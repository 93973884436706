/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.tagsInputWrapper {
    .rbt-input {
        &.focus {
            border-color: @midGray;
            box-shadow: none;
        }
    }
    .rbt-token {
        background-color: @selectedTag;
        border: 1px solid @borders;
        color: @mainText;
        &.rbt-token-active {
            color: @mainText;
        }
    }
    .rbt-input-multi.form-control[disabled] {
        background-color: @disableBg;
    }
}