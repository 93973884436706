/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.inFilterValueUpload{
    .upload-btn-wrapper i.ev{
        vertical-align: -12%
    }
    .input-group-btn {
        vertical-align: top;
    }
}

.filterValueAutoComplete {
    &.ac-Wrapper {
        .rbt .rbt-input-main {
            background-color: @modeBgColor;
        }
    }
}