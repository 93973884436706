@import '../../../../config/variables-fns';

.range-utils{
  position: relative;

  .track-tracker{
    display: inline-block;
    position: absolute;
    bottom: 12px;
    transform: translateX(-50%);
    padding: 2px 4px;
    background-color: @hoverColor;
    border-radius: 3px;
    color: @white;
    font-size: 12px;
    &.track-end{
      transform: translateX(50%);
    }
    &:nth-child(odd){
      bottom: auto;
      top: 20px;
    }
  }
  .rc-slider-mark-text {
    &:first-child {
      transform: translateX(0%) !important;
    }
    &:last-child {
      transform: translateX(-100%) !important;
    }
  }
}

.rc-slider-rail{
  background-color: @borders;
}

.rc-slider-track {
  background-color: @iconsColor;
}

.rc-slider-mark-text {
  color: @mainText;
}

.rc-slider-dot{
  display: none;
}
.marg-top{
  margin-top: 60px;
}
.rc-slider-handle{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid @borders;
  margin-top: -8px;
  background-color: @modeBgColor;
  
  &.rc-slider-handle-dragging,
  &:focus,
  &:active {
    border-color: @borders !important;
    background-color: @iconsColor;
    box-shadow: none !important;
    outline: none;
  }
}

.rangeSize, .silderDisable{
  &.rangeSize{
   padding-top: 10px;
  }
  .range-utils {
    width:~"calc(100% - 15px)";
    margin: 0 auto;
  }
  .rc-slider-mark-text{
    strong{
      font-weight: normal;
      color: @mainText;
    }
  }
  &.silderDisable{
    .range-utils{
      opacity: 75%;
    }
    .rc-slider-disabled{
      background-color: @disableBg;
      color: @disableText;
      .rc-slider-handle{
        cursor: default;
      }
      .rc-slider-mark-text{
        cursor: default !important;
      }
    }   
  }
}