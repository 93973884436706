/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../config/variables-fns';
.tabs-container {
    margin-top: 5px;
    position: relative;
    &.thresholdTab{
        .nav-tabs {
            li a {
                font-size: 10px;
                padding: 10px 7px;
            }
        }
    }
    .nav-tabs {
            border-bottom: none;
        li {
            background-color: @tabsHeadingBgColor;
            padding: 0;
            text-align: center;
            font-weight: bold;
            a {
                border-radius: 0;
                margin-right: 0;
                border: none;
                border-right: 2px solid @tabsBorderColor;
                color: @tabsHeadingTextColor;
                .transition(@bgAmbience);
                outline: none;
                font-size: 1.3rem;
                padding: 8px;
                &:focus,
                &:hover {
                    background-color: @hoverColor;
                }
            }
            &.active {
                background-color: @modeBgColor !important;
                a {
                    border-right: 3px solid @activeElement;
                    background-color: @modeBgColor;
                    &:focus,
                    &:hover {
                        background-color: @modeBgColor;
                        color: @tabsHeadingTextColor;
                        border: none;
                        border-right: 3px solid @activeElement;
                    }
                }
            }
            &.disabled{
                background-color: rgba(215, 215, 215, 0.5);
                cursor:not-allowed;
                a{
                    color: @disableText;
                    border-right: 2px solid rgba(162, 162, 162,0.5);
                }
            }
        }
    }
    .tab-content {
        .transition(@ambience3);
        padding: 10px 20px 20px 20px;
        background: @modeBgColor;
        position: relative;
        height: ~'calc(100vh - 171px)';
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar-thumb {
            background: rgba(160, 160, 160, 0) !important;
        }
        &::-webkit-scrollbar-track{
            background: rgba(112, 112, 112, 0) !important; 
        }
        &:hover{
           &::-webkit-scrollbar-thumb  {
            background: rgba(160, 160, 160, 0.3)!important;
                &:hover{
                background: rgba(160, 160, 160, 1) !important;
                }
            }
            &::-webkit-scrollbar-track {
                background: rgba(112, 112, 112, 0.13) !important;
            }
        }
        &:hover{
            .expandPreview-btn{
                z-index: 0 !important;
            }
        }
    }
    // this needs to be move to system settings less file
    &#system-settings-tabs{
        .tab-content{
            min-height:~'calc(100vh - 171px)';
            height: auto;
        }
    }  
}


// this suppose to be moved to association file, but it's not linked to the AssociationConfigurationTabs which uses this class
@media only screen and (min-width:1366px){
    .tabs-container.thresholdTab .nav-tabs li a {
        font-size: 12px;
        padding: 10px 8px;
    }
}