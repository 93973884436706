/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.svgViewer {
    &.modalSVGViewer {
        border: 2px dashed @borders;
        padding: 5px;
        .previewText {
            fill: @disableText;
            font-size: 1.3rem;
            transform: scale(0.5) translate(50%, -50%);
        }
        path{
            fill: @mainText; 
        } 
    }
    &.svgViewerItemList {
        margin-right: 10px;
        float: left;
    }
}