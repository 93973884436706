/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../config/variables-fns';

.switch {
    width: 100px;
    border: 1px solid @borders;
    height: 26px;
    cursor: pointer;
    margin-bottom: 10px;
    display: inline-block;
    overflow: hidden;
    transition: all .4s ease-in-out;
    &.switch-sm {
        width: 80px;
        &.on {
            .switch-toggle {
                transform: translateX(53px);
            }
        }
    }
    &.switch-lg {
        width: 127px;
        &.on {
            .switch-toggle {
                transform: translateX(100px);
            }
        }
    }
    .switch-toggle {
        border: 1px solid @borders;
        box-shadow: 1px 1px 1px @borders;
        width: 25px;
        height: 24px;
        background: @modeBgColor;
        position: relative;
        transition: all .4s ease-in-out;
        .handle {
            left: 33px;
            position: absolute;
            line-height: 23px;
        }
    }
    &.on {
        background: @mainColor;
        .switch-toggle {
            transform: translateX(73px);
            .handle {
                left: initial;
                right: 35px;
                color: @white;
            }
        }
    }
    &.disabled {
        border-color: @disabledBorder;
        background: @disableBg;
        cursor: not-allowed;
        .switch-toggle {
            border-color: @disabledBorder;
            background: @disableBg;
            box-shadow: none;
            .handle {
                color: @disableText;
            }
        }
    }
}