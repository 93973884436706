/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.annotation-btn {
	position: absolute;
	right: 0;
	display: none;
	z-index: 1;
}

.annotatable-container {
	position: relative;
	height:100%;
	.svg-wrapper {
		text-align: center;
	}
	svg {
		display: inline-block;
	}
	&:hover .annotation-btn {
		display: block;
	}
}

.annotation-drawing-area-wrapper {
	border: 1px solid @borders;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.literally {
	height: 100%;
}

.lc-drawing {
	height: 100%;
	canvas {
		position: absolute;
		cursor: crosshair;
	}
}

.annotation-toolbar {
	margin-top: 10px;
	position: absolute;
	width: calc(~'100% - 60px');
    left: 15px;
    top: -10px;
	text-align: center;
	padding-right: 15px;
    z-index: 101;
	.btn-action {
		display: inline-block;
		background-color: @systemBackground;
		padding: 4px 0px;
		width: 28px;
	    margin-bottom: -1px;
    	margin-right: -1px;
		border: 1px solid @borders;
	}
	.color-picker {
    	margin-right: -1px;
		vertical-align: middle;
		cursor: pointer;
		width: 28px;
		margin-bottom: -1px;
		border: 1px solid @borders;
		padding: 1px 0px;
		background-color: @systemBackground;

		.color-button {
			width: 16px;
			height: 16px;
			margin: 5px;
		}

		.color-popover {
	    z-index: 101;
		}
	}
}
