/* Copyright 2019 Greyskies. All Rights Reserved. */
// override style for [date range picker] plugin which is used to implement DatePicker & DateRangePicker components
// All styles left here are override for the BsDateRangePicker library

@import '../../../../config/variables-fns';


// THE dateRangePicker dropdown (that the library generates)
.daterangepicker {
    position: absolute;
    background: @modeBgColor;
    width: 278px;
    padding: 4px;
    margin-top: 1px;
    top: 100px;
    left: 20px;
    /* Calendars */
    &:before,
    &:after {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }
    &:before {
        top: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid @borders;
    }
    &:after {
        top: -6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid @modeBgColor;
        border-left: 6px solid transparent;
    }
    &.opensleft{
        &:before {
            left: auto;
            right: 50px;
        }
        &:after {
            left: auto;
            right: 51px;
        }
    }
    &.noCustomRange {
        .ranges{
            float: none;
        }
    }
    &.openscenter{
        padding: 5px 10px 10px 10px;
    }
    &.openscenter:before,
    &.openscenter:after {
        left: 50% !important;
        transform: translateX(-50%);
        width: 0;
        margin-left: auto;
        margin-right: auto;
    }

    &.opensright:before {
        right: auto;
        left: 50px;
    }

    &.opensright:after {
        right: auto;
        left: 51px;
    }
    &.dropup {
        margin-top: -5px;
        &:before {
            top: initial;
            bottom: -7px;
            border-bottom: initial;
            border-top: 7px solid @midGray;
        }
        &:after {
            top: initial;
            bottom: -6px;
            border-bottom: initial;
            border-top: 6px solid @modeBgColor;
        }
    }
    &.dropdown-menu {
        max-width: none;
        margin-bottom: 40px;
        border-color: @borders;
    }

    &.single {
        padding: 5px 10px 10px 10px;
        .ranges,
        .calendar {
            float: none;
        }
    }
    &.show-calendar {
        &.opensright:not(.single){
            &.overrideRightPosition{
                left: auto !important;
                right: 0 !important;
                &:before {
                    left: auto;
                    right: ~'calc(40% - 1px)';
                }
            
                &:after {
                    left: auto;
                    right: 40%;
                }
            }
        }
        &.opensleft{
            &:before {
                left: auto;
                right: 50px;
            }
            &:after {
                left: auto;
                right: 51px;
            }
        }
        &:before {
            left: 50px;
            right: auto;
        }
        &:after {
            left: 51px;
            right: auto;
        }
        .calendar {
            display: block;
        }
    }
    .calendar {
        display: none;
        max-width: 270px;
        margin: 4px;
        &.single .calendar-table {
            border: none;
        }
    }

    .calendar th,
    .calendar td {
        white-space: nowrap;
        text-align: center;
        min-width: 32px;
    }
    .calendar-table {
        border: 1px solid @modeBgColor;
        padding: 4px;
        background: @modeBgColor;
        table {
            width: 100%;
            margin: 0;
            td,
            th {
                min-width: 32px;
                text-align: center;
                width: 20px;
                height: 20px;
                border: 1px solid transparent;
                white-space: nowrap;
                cursor: pointer;
                &.available:hover {
                    background: @hoverColor;
                }
                &.week {
                    font-size: 80%;
                    color: @midGray;
                }
            }
            td {
                border-color: transparent;
                &.off,
                &.off.in-range,
                &.off.start-date,
                &.off.end-date {
                    background-color: @modeBgColor;
                    color:@gray;
                }
                &.in-range {
                    background-color: @panelHeading; // needs check for color var
                    color: @mainText;
                }
                &.active,
                &.active:hover {
                    background-color: @selectedDateBgColor;
                    color: @lightText;
                }
            }
        }
        th.month {
            width: auto;
        }
    }
    td.disabled,
    option.disabled {
        color: @disableText;
        cursor: not-allowed;
        text-decoration: line-through;
    }
    select {
        cursor: pointer;
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid @borders;
        .transition(@ambience1);
        font-weight: bold;
        font-size: 12px;
        height: @height1;

        &.hourselect {
            width: 45%;
        }
        &.minuteselect {
            width: 33%;
        }
        &.monthselect,
        &.yearselect {
            margin: 0;
        }
        &.monthselect {
            margin-right: 2%;
            width: 56%;
        }
        &.yearselect {
            width: 40%;
        }
        &:focus,
        &:hover {
            border-bottom: 1px solid @activeElement;
            outline: none;
            background-color: @hoverColor;
        }
        span {
            padding: 3px 16px 3px 12px;
            font-size: 16px;
        }
        option {
            background-color: @modeBgColor;
        }
        &.disabled {
            cursor: not-allowed;
            border-color:  @disabledBorder;
            background-color: @disableBg;
        }

    }

    .input-mini {
        border: 1px solid  @midGray;
        color: @darkGray;
        height: 30px;
        line-height: 30px;
        display: block;
        vertical-align: middle;
        margin: 0 0 5px 0;
        padding: 0 6px 0 28px;
        width: 100%;
        &.active {
            border: 1px solid @dateRange;
        }
    }
    .daterangepicker_input {
        position: relative;
        i {
            position: absolute;
            left: 8px;
            top: 8px;
            &.fa-calendar:before{
                content: "\e00e";   
                font-family: "eventum-font" !important;
                font-style: normal !important;
            }
        }
    }
    .calendar-time {
        text-align: center;
        margin: 5px auto;
        line-height: 30px;
        position: relative;
        padding-left: 28px;
        select.disabled {
            color: @disableText;
            cursor: not-allowed;
        }
    }
}

.ranges {
    font-size: 11px;
    float: none;
    margin: 4px;
    text-align: left;
    ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        li {
            font-size: 13px;
            background: @systemBackground;
            border: 1px solid @systemBackground;
            color: @dateRange;
            padding: 3px 12px;
            margin-bottom: 8px;
            cursor: pointer;
            &:hover{
                color: @mainText;
            }
            &.active {
                background: @dateRange;
                border: 1px solid @dateRange;
                color: @mainText;
            }
        }
    }
}

.dateRangePickerWrapper{ 
    .disabled{ // this is only in daterangepicker
        pointer-events: none;
        background-color: @disableBg;
        border-color:@disabledBorder;
        color:@disableText;
    }
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
    .daterangepicker {
        width: auto;
        .ranges ul {
            width: 160px;
            padding: 0 5px 0 0;
            max-height: 300px;
            overflow: auto;
            margin-bottom: 5px;
        }
        &.single {
            .ranges ul {
                width: 100%;
            }
            .calendar.left {
                clear: none;
            }
        }
        .calendar.left {
            clear: left;
            margin-right: 0;
            .calendar-table {
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding-right: 12px;
            }
            .calendar.right {
                margin-left: 0;
                .calendar-table {
                    border-left: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
        .left .daterangepicker_input {
            padding-right: 12px;
        }
        .ranges,
        .calendar {
            float: left;
        }
    }
}

@media (min-width: 730px) {
    .daterangepicker .ranges {
        width: auto;
        float: left;
    }
    .daterangepicker .calendar.left {
        clear: none;
    }
    .dropdown-menu {
        box-shadow: none;
        .ranges li {
            background-color: @systemBackground;
            
            .transition(@ambience2);
            color: @mainText;
            &:focus,
            &:hover {
                background-color:@hoverColor;
                border: 1px solid @borders;
            }
            &.active {
                background-color:@tableHeading;
                color: @sysHeaderTextColor;
                border-radius: 0;
                border: 1px solid @borders;
                &:focus,
                &:hover {
                    background-color:@hoverColor;
                    border: 1px solid @borders;
                    color: @mainText;
                }
            }
        }
        .range_inputs {
            .btn {
                margin-bottom: 0;
                float: right;
                margin-left: 5px;
                color: @white;
            }
            .applyBtn{
                background-color: @primaryBtn;
                border: 2px solid @primaryBtn;
            }
            .cancelBtn{
                border: 2px solid @cancelBtnBorder;
                background: @transparent;
                color: @mainText;
                outline: none;
            }
        }
        .calendar {
            td.end-date,
            td.in-range,
            td.start-date {
                background-color: @midGray;
                color: @mainText;
                &:focus,
                &:hover {
                    background-color: @lightGray;
                }
            }
            td.active {
                background-color: @darkGray;
                color: @mainText;
                &:focus,
                &:hover {
                    background-color: @gray;
                }
            }
            .input-mini {
                box-shadow: none;
                border-color: @borders;
                &:focus {
                    box-shadow: none;
                    border-color: @midGray;
                }
                &.active {
                    border-color: @borders;
                    &:focus {
                        box-shadow: none;
                        border-color: @midGray;
                    }
                }
            }
        }
    }
}
