/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.filter-form{
    .filter-form-popup-button{
      display: inline-flex;
      max-width: 320px;
      background-color: @selectedTag;
      min-width: 100px;
      margin: 0 5px 0 5px;
      border: 2px solid @borders;
      cursor: pointer;
      position: relative;
      vertical-align: middle;
      height: 28px;
      align-items: center;
      .dateRangePickerWrapper{
        width:100%;
        padding: 0 6px; // to override dateRangePicker
        &.optionalFilter{
          margin-right: 0;
          padding-right: 0;
          width: ~'calc(100% - 20px)';
          span.dateText {
            width: ~'calc(100% - 20px)';
            padding-right: 0;
          }
        }
        .react-bootstrap-daterangepicker-container {
          width: 100%;
        }
        .calendarIconWrapper {
          vertical-align: middle;
        }
        .ev-calendar{
          color:@mainColor !important;
        }
      }
      & > span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: ~'calc(100% - 21px)';
        display: inline-block;
        line-height: 20px;
        padding: 2px 0 2px 5px;
        float: left;
      }
      span.dateText{
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: ~'calc(100% - 20px)';
        padding: 3px 0px 3px 3px;
        vertical-align: middle;
       }
      .form-control {
        display: inline-block;
        margin-bottom: 0;
        border: none;
        padding: 4px;
        height: auto;
      }
      .multipleSelect{
        width: auto;
        flex-grow: 1;
        .ms-parent{
          margin: 0;
          .ms-drop{
            width: auto;
            max-width: 250px;
          }
          .ms-choice  {
            height: 24px;
            border-bottom: 0;
            &> span{
              padding: 5px 6px 3px 3px;
              line-height: 12px;
            }
            &>div{
              background-position-y: 3px;
              height: 20px;
              &.open{
                background-position-y: 3px;
              }
            }
          }
        }
      }
      .dashboard-datepicker > span:first-child{
        padding: 0px 5px;
        line-height: 22px;
      }
      &.sm-elm{
        width: 100px;
      }
      &.md-elm{
        width: 180px;
      }
      &.lg-elm{
        width: 260px;
      }
      &.auto-elm{
        width: auto;
        min-width: 180px;
      }
      .btn.btn-action{
        padding: 0;
        width: 20px;
        align-items: center;
        justify-content: center;
      }
    }

    .filterOptions-btn {
      margin: 0;
      &.input-select{
        min-width: 60px !important;
      }
      .ms-drop{
        min-width: 150px;
        top: 25px !important;
      }
    }
    .filter-form-control.ms-parent .ms-choice {
      width: 30px;
      height: 30px;
      float: right;
      background: transparent;
      border: none;
      padding: 5px;
      right: 20px;
      text-align: center;
      span, div {
        display: none;
      }
      &:hover {
        border: none!important;
      }
    }
  }

  .filter-form-popover{
    min-height: 30px;
  }

  .keyFilter{
    width: auto;
    flex-grow: 1;
    position: relative;
    > .displayVal {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: ~'calc(100% - 25px)';
        padding: 3px 0;
        float: left;
    }
    .selectAttrPath-btn.tree-btn.filterSelectNE-btn {
        margin: 0;
        float: right;
        padding: 2px 5px;
        outline: none;
        background: @hoverColor;
    }
}
.networkElementText{
  .keyFilter{
    > .displayVal {
      padding: 3px 9px 3px 3px;
    }
  }
}
