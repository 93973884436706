/* Copyright 2019 Greyskies. All Rights Reserved. */

//=== chosen
.chosen-container {
    margin-bottom: 20px;
    width: 100%;
    font-size: 16px;

    &.chosen-container-active {
        & .chosen-single {
            border-color: @midGray;
            box-shadow: none;
        }

        &.chosen-with-drop {
            & .chosen-single div b {
                background-position: -20px 7px;
            }
        }
    }

    & .chosen-default,
    & .chosen-single {
        border-radius: 0;
        padding: 3px 12px;
        height: @height1;
        box-shadow: none;
        background: @modeBgColor;
        .transition(@ambience2);
        div b {
            background: url('../../img/chosen-sprite.png') no-repeat -2px 7px;
        }
    }

    & .chosen-drop {
        border-radius: 0;
        box-shadow: none;
        border-color: @midGray;

        & li.highlighted {
            background: @gray;
        }
    }
}
