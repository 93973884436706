/* Copyright 2019 Greyskies. All Rights Reserved. */

.notificationMixin(@color , @content) {
    &:before {
        content: @content;
        color: @lightText  !important;
        z-index: 1;
        padding-left: 2px;
    }
    &:after {
        width: 60px;
        height: 100%;
        content: "";
        background-color: @color  !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
}

.notification-container {
    overflow: visible;
    width: auto;
    width: 464px;
    .notification {
        -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 16%);
        -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 16%);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 16%);
        .transition(@ambience2);
        border-left: none;
        word-wrap: break-word;
        background-color: @modeBgColor  !important;
        border: 1px solid @borders;
        color: @mainText  !important;
        opacity: 1;
        .title {
            font-size: 1.3rem;
            line-height: 1em;
            font-weight: bold;
            margin-left: 20px;
        }
        .message {
            margin-left: 20px;
            font-size: 1.2rem;
        }
        &.notification-success {
            .notificationMixin(@success , "\f058");
        }
        &.notification-info {
            .notificationMixin(@dateRange , "\f05a");
        }
        &.notification-warning {
            .notificationMixin(@warning , "\f06a");
        }
        &.notification-error {
            .notificationMixin(@danger , "\f057");
        }
    }
}

@media (max-width: 900px) {
    .notification-container {
        width: 50vw;
    }
}

@media (max-width: 425px) {
    .notification-container {
        width: 80vw;
    }
}