/* Copyright 2019 Greyskies. All Rights Reserved. */

// Tree control Component
@import 'config/variables-fns';
.mainTree {
    .sysTree {
        position: relative;
        margin: 0;
        padding: 5px 0;
        font-family: 'eventum-font';

        li {
            list-style: none;
            margin: 0;
            outline: 0;
            padding: 0;
            white-space: nowrap;
            display: flex;
            align-items: center;
            a[draggable],
            a[draggable="true"] {
                .userSelect(none);
                -khtml-user-drag: element;
                -webkit-user-drag: element;
                color: @gray;
            }

            ul {
                margin: 0;
                padding: 0 0 0 22px;
            }
            .treeActIco,
            .treeActIco i {
                color: @mainText !important;
            }
            a {
                cursor: pointer;
                display: inline-block;
                margin: 0;
                padding: 1px 3px 0 0;
                text-decoration: none;
                vertical-align: sub;
                margin-bottom: 0px;
                font-size: 1em;
                color: @mainText;
                width: 90%;
                .transition(@ambience2);
                &.item-active-bg-color{
                    background-color: @hoverColor;
                    width: auto;
                    &:hover{
                        background-color: @hoverColor;
                        width: auto;
                    }
                }
                &.item-inactive-bg-color{
                    background-color: transparent;
                }
            }
            span.sysTree-switcher,
            span.sysTree-iconEle,
            span.sysTree-checkbox {
                background-attachment: scroll;
                background-color: transparent;
                background-repeat: no-repeat;
                border: 0 none;
                cursor: pointer;
                display: inline-block;
                margin-right: 2px;
                vertical-align: middle;
            }
            span.sysTree-switcher {
                height: @height0;
                line-height: @height0;
                outline: none;
                width: @width1;
            }

            span.sysTree-checkbox {
                background-image: url("../img/checkbox.png");
                background-position: 0 0;
                height: 13px;
                height: 19px;
                line-height: @height0;
                outline: none;
                width: 13px;
                width: 22px;
                margin: 0;
            }

            span.sysTree-iconEle {
                height: @height0;
                line-height: 13px;
                outline: none;
                width: @width1;
                margin-left: 1px;
            }

            span.sysTree-icon_loading {
                margin-right: 2px;
                vertical-align: top;
            }

            span.sysTree-switcher {
                &.sysTree-roots_open,
                &.sysTree-center_open,
                &.sysTree-bottom_open,
                &.sysTree-noline_open,
                &.sysTree-switcher_open {
                    .transform(rotate, -45deg);

                    &:before {
                        content: '\e000';
                        font-size: 17px;
                    }
                }

                &.sysTree-roots_close,
                &.sysTree-center_close,
                &.sysTree-bottom_close,
                &.sysTree-noline_close,
                &.sysTree-noline_docu,
                &.sysTree-switcher_close {
                    &:before {
                        content: '\e002';
                        color: @mainText;
                        font-size: 17px;
                    }
                }
                &.sysTree-noline_open ~ a ,
                &.sysTree-noline_open ~ a > * ,
                &.sysTree-noline_close ~ a ,
                &.sysTree-noline_close ~ a > * {
                    cursor: default;
                    .ev-text-warning {
                        color: @mainText;
                    }
                }

                &.sysTree-center_docu {
                    background-position: -56px -18px;
                }

                &.sysTree-bottom_docu {
                    background-position: -56px -36px;
                }
               
            }

            span.sysTree-checkbox-checked {
                background-attachment: scroll;
                background-color: transparent;
                background-image: url("../img/checkbox.png");
                background-position: 0 0;
                background-repeat: no-repeat;
                border: 0 none;
                cursor: pointer;
                display: inline-block;
                height: 13px;
                height: 22px;
                line-height: @height0;
                margin-right: 2px;
                outline: none;
                vertical-align: middle;
                width: 13px;
                width: 22px;
                margin: 0;
                background-position: 0 -18px;
            }

            span.sysTree-checkbox-indeterminate {
                background-position: 0 -40px;
            }

            span.sysTree-checkbox-disabled {
                background-position: 0 -56px;
            }

            span.sysTree-checkbox.sysTree-checkbox-checked.sysTree-checkbox-disabled {
                background-position: 0 -18px;
            }

            span.sysTree-checkbox.sysTree-checkbox-indeterminate.sysTree-checkbox-disabled {
                background: @midGray;
                border-radius: 3px;
                position: relative;

                &::after {
                    border-left: 0;
                    border-top: 0;
                    border: 2px solid @white;
                    content: ' ';
                    height: 0;
                    left: 3px;
                    position: absolute;
                    top: 5px;
                    .transform(scale, 1);
                    width: 5px;
                }
            }
        }
        .dropdown-menu li a{
            width: 100%;
        }

    }

    .sysTree-node-content-wrapper {
        display: inline-block;
        padding: 5px;
    }

    .sysTree-icon__open {
        margin-right: 2px;
        vertical-align: top;

        &:before {
            content: '\e024';
        }

        +.sysTree-title {
            font-weight: bold;
        }
    }

    .sysTree-icon__close {
        margin-right: 2px;
        vertical-align: top;

        &:before {
            content: '\e023';
            font-family: 'eventum-font';
            color: @mainText;
        }

        +.sysTree-title {
            color: @mainText;
        }
    }

    .sysTree-title {
        font-size: 14px;
        font-family: Arial, "Helvetica Condensed", Helvetica, sans-serif;
    }

    .checkbox-group {
        display: inline-block;
        margin-bottom: 0;
        .checkbox-label {
            margin-left: 2px;
            padding-left: 19px;
            margin-right: 6px;
            min-height: 21px;
        }
    }
    .mainTreeSearchBar{
        width: 100%;
        display: inline-block;
        &.withFilter{
            width: ~'calc(100% - 45px)';
        }
    }
    .search-container {
        input {
            margin-bottom: 5px;
        }
    }
    .panel-body {
        .rst__tree {
            min-width: 100%;
            display: inline-block;
            .ev{
                color: @mainColor;
            }
        }
    }
    .ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
        overflow: auto !important;
        .ReactVirtualized__Grid__innerScrollContainer {
        overflow: visible !important;
        }
    }
    
    &.highlighted-TreeNodes {
        .ReactVirtualized__List,
        .ReactVirtualized__Grid,
        .rst__virtualScrollOverride {
            overflow: auto !important;
        }
        .rst__node {
            *:not(.fa) {
                transition: .2s color ease-in-out;
            }
            .nodeActions {
                display: inline-block;
                transition: .2s all ease-in-out;
                opacity: 0;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                display: flex;
                align-items: center;
            }
            .rst__nodeContent {
                transition: .2s all ease-in-out;
            }
            &:hover {
                .rst__nodeContent {
                    background-color:@systemBackground;
                }
                .nodeActions {
                    opacity: 1;
                }
            }
        }
    }
}
.tree-node-title {
    margin-left: 8px;
}
.matched-word {
  font-weight: bold;
}

.ellipsis-tree-parent{
    .sysTree-title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: ~"calc(100% - 140px)";
    }
}
.navigationSlider .mainTree.highlighted-TreeNodes {

    .rst__node{
        &:hover .rst__nodeContent{
            background-color: @transparent;
        }
        .sysTree{
            padding: 0;
            li a{
                padding: 5px;
                width: auto;
                .transition(@ambience2);
                &:hover{
                    background-color:@systemBackground;
                }
            }
        }
    }
}
