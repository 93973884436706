/* Copyright 2019 Greyskies. All Rights Reserved. */
@import './fonts.less';
@import '../../config/variables-fns';

html{
    font-size: 10px;
}

body {
    font-family: @fontFamily;
    font-weight:400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-weight: bold;
}

h1{
    font-size: 2.4rem;
}
h2{
    font-size: 2.2rem;
}
h3{
    font-size: 2rem;
}
h4{
    font-size: 1.8rem;
}
h5{
    font-size: 1.6rem;
}
h6{
    font-size: 1.4rem;
}

span{
    font-size: 1.25rem;
}

p{
    font-size: 1.25rem;
}

label{
    font-size: 1.3rem;
    font-weight: 600 !important;
}

a{
    font-size: 1.25rem;
}

button{
    font-size: 1.4rem;
}
th{
    font-weight: 600 !important;
}

td{
    font-size: 1.25rem;
}

li{
    label{
        font-weight: 400 !important;
        span{
            font-weight: 400 !important;
        }
    }
}
