/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus{
  -webkit-text-fill-color: @mainText;
  -webkit-box-shadow: 0 0 0px 100px @modeBgColor inset !important;
  caret-color: @mainText;
}

input{
  color: @mainText;
  caret-color: @mainText;
  &.form-control {
    height: @height1;
    &.email-subject {
      background-color: @modeBgColor;
      border: 1px solid @borders;
      overflow: hidden;
      padding-left: 10px;
      padding-top: 5px;
      margin-bottom: 0px !important;
      height: 42px;
    }
  }
}

textarea {
  width: 100%;
  resize: vertical;
}

.form-control {
  height: auto;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 0;
  border-color: @borders;
  background-color: @modeBgColor;
  box-shadow: none;
  color: @mainText;
  font-size: 1.2rem;
  @media(max-width: 768px) {
    margin-bottom: 10px;
  }
  &::placeholder {
      color: @gray;
  }
  .transition(@ambience2);
  td a & {
      height: 10px;
  }
  &.textarea {
      max-width: 100%;
      min-height: 70px;
  }
  &.textarea+.glyphicon {
      right: 20px;
  }
  &.input-select:focus,
  &.input-text:focus,
  &:focus,
  &:hover {
      box-shadow: none;
      border-color: @inputFocusBorder;
      &::placeholder {
          color: @darkGray;
          opacity: 1;
          .transition(@ambience1);
      }
  }
  &.disabled-input {
    box-shadow: none;
    border-color: @disabledBorder;
    color: @disableText;
    transition: none; 
    cursor: not-allowed;
    &:focus {
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: @disableText;
      }
    }
  }
}
.form-group{
  .form-control,
  .radiobutton-container,
  .btn,
  .ms-parent{
    margin-bottom: 0;
  }
}
.form-control[disabled]{
  background-color: @disableBg;
  border:none;
  color:@disableText;
}
.input-group-addon {
  border-radius: 0;
  line-height: 0;
}
.data-input {
  .input-group{
    .form-control,
    .input-group-addon + .form-control{
      margin-bottom: 0 !important;
    }
  }
}
.input-group{
  .form-control{
    background-color: @modeBgColor;
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 6px 10px 6px;
  
  input[type=file] {
    appearance: none;
    position: absolute;
    opacity: 0;
    z-index: -1;
    display: none;
  }
}

select {
  height: @height1;

  &.form-control {
      height: @height1;
  }
}

.has-feedback.has-error {
  .form-control-feedback {
    right: 20px;
  }
  .form-control {
    padding-right: 12px;
  }
}

.data-input {
  margin-bottom: 15px;
  .form-control {
    margin-bottom: 5px !important;
  }
  .checkbox-label {
    margin-top: 7px;
    margin-bottom: 0px;
  }
  .ms-parent {
    margin-bottom: 5px;
  }
  .data-input-field {
    > :first-child .checkbox-group,
    .checkbox-group:first-child {
      padding-top: 5px;
    } 
  }
}

.data-input .form-control.margBtm0 {
  margin-bottom: 0 !important;
}

.data-input-label {
  padding-top: 6px;
  &:empty {
    padding: 0;
  }
}
.quill {
  background-color: @modeBgColor;
  .ql-editor {
    min-height: 200px;
    p {
      word-break: break-all;
    }
  }
}
#holder {
  border: 10px dashed  @midGray;
  width: 300px;
  min-height: 300px;
  margin: 20px auto;
  &.hover {
    border: 10px dashed @success;
  }
  img {
    display: block;
    margin: 10px auto;
  }
  p {
    margin: 10px;
    font-size: 1.2rem;
  }
}
progress {
  width: 100%;
  &:after {
    content: '%';
  }
}
.fail {
  background:  @mainColor;
  padding: 2px;
  color: @mainText;
}
.ace_editor div, .ace_editor span {
  font-size: 12px !important;
}

.ace-tm .ace_gutter {
  background: #e6e4e4;
}

[data-layout-theme="mobily"][data-theme="dark"] {
  .ace_editor .ace_gutter { // command line gutter in databinding
    background-color: #3C3836;
  }
}

.form-container{
  .submitForm-btn{
    color:@white;
  }
  
  &.snmpTrapAction-form,
  &.workflowAction-form,
  &.automationAction-form {
    min-height: 250px;
  }
}

.optionInput {
  width: ~'calc(100% - 50px)';
}

// this class needs to be moved
.inputFilterBtn{  
  color:@mainText !important;
  background-color:@borders !important;
  &:focus {
    outline: none !important;
    border: none !important;
  }
  .btn.btn-default{
    color:@mainText !important;
    background-color:@hoverColor !important;
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }
 }

 .form-container:not(.filter-form) {
  .KeyFilterStyle {
      background-color: @treeSelections;
      height: 32px;
      display: flex;
      align-items: center;
      width: 100%;
  }
}

.evc-transparent-input {
  background-color: transparent;
  &:hover {
    background-color: @hoverColor;
  }
}

.form-container {
  .constructTreeWrapper {
    border: 1px solid @borders;
    padding: 8px 12px;
    overflow: hidden;
    overflow-y: auto;
    .mainTree .ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
      padding: 10px 0;
    }
  }
  .data-input .data-input-field .constructTreeWrapper .mainTree {
    & > :first-child .checkbox-group,
    .checkbox-group:first-child {
      padding-top: 0;
    }
  }
}
