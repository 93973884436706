/* Copyright 2019 Greyskies. All Rights Reserved. */

@charset "UTF-8";

@font-face {
  font-family: "eventum-font";
  src:url("../fonts/eventum-font.eot");
  src:url("../fonts/eventum-font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/eventum-font.woff") format("woff"),
    url("../fonts/eventum-font.ttf") format("truetype"),
    url("../fonts/eventum-font.svg#eventum-font") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "eventum-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ev {
    /*color: #162a36 !important;*/
    display: inline-block;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    font-size: 1.5rem;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: -15%;
}

[class^="ev-"]:before,
[class*=" ev-"]:before {
  font-family: "eventum-font" !important;
  font-style: normal !important;
}

.ev-add:before {
  content: "\61";
}
.ev-add-dashboard:before {
  content: "\62";
}
.ev-add-records:before {
  content: "\63";
}
.ev-admin-settings:before {
  content: "\64";
}
.ev-anomaly-detection:before {
  content: "\65";
}
.ev-attr-binding:before {
  content: "\66";
}
.ev-chart:before {
  content: "\67";
}
.ev-circle:before {
  content: "\68";
}
.ev-collection-management:before {
  content: "\69";
}
.ev-collection-profile:before {
  content: "\6a";
}
.ev-dashboard:before {
  content: "\6b";
}
.ev-data-analysis:before {
  content: "\6c";
}
.ev-data-model:before {
  content: "\6d";
}
.ev-data-model-tree:before {
  content: "\2c";
}
.ev-data-rollup:before {
  content: "\6f";
}
.ev-device:before {
  content: "\70";
}
.ev-drilldown:before {
  content: "\71";
}
.ev-drillup:before {
  content: "\72";
}
.ev-event-healing:before {
  content: "\73";
}
.ev-events:before {
  content: "\74";
}
.ev-exportexcel:before {
  content: "\75";
}
.ev-exportpdf:before {
  content: "\76";
}
.ev-fga:before {
  content: "\77";
}
.ev-firewall:before {
  content: "\78";
}
.ev-forecasting:before {
  content: "\79";
}
.ev-gauge:before {
  content: "\7a";
}
.ev-hardware:before {
  content: "\41";
}
.ev-input:before {
  content: "\42";
}
.ev-interface:before {
  content: "\43";
}
.ev-ip:before {
  content: "\44";
}
.ev-landingpage:before {
  content: "\45";
}
.ev-license:before {
  content: "\46";
}
.ev-lookup:before {
  content: "\47";
}
.ev-main-events:before {
  content: "\48";
}
.ev-manage-landingpage:before {
  content: "\49";
}
.ev-managegroups:before {
  content: "\4a";
}
.ev-manageusers:before {
  content: "\4b";
}
.ev-markdown:before {
  content: "\4c";
}
.ev-nbi:before {
  content: "\4d";
}
.ev-netflow:before {
  content: "\4e";
}
.ev-network-permission:before {
  content: "\4f";
}
.ev-number:before {
  content: "\50";
}
.ev-org-group:before {
  content: "\51";
}
.ev-output:before {
  content: "\52";
}
.ev-pattern:before {
  content: "\53";
}
.ev-permissions:before {
  content: "\54";
}
.ev-pie-chart:before {
  content: "\55";
}
.ev-raw-data:before {
  content: "\56";
}
.ev-report:before {
  content: "\57";
}
.ev-reportdesigner:before {
  content: "\58";
}
.ev-reporter-settings:before {
  content: "\59";
}
.ev-reset-zoom:before {
  content: "\5a";
}
.ev-router:before {
  content: "\30";
}
.ev-scheduledreports:before {
  content: "\31";
}
.ev-square:before {
  content: "\32";
}
.ev-string:before {
  content: "\33";
}
.ev-switch:before {
  content: "\34";
}
.ev-table:before {
  content: "\35";
}
.ev-top-n:before {
  content: "\36";
}
.ev-topn-table:before {
  content: "\37";
}
.ev-topology:before {
  content: "\38";
}
.ev-tree:before {
  content: "\39";
}
.ev-triggerd-events:before {
  content: "\21";
}
.ev-var-body:before {
  content: "\22";
}
.ev-var-header:before {
  content: "\23";
}
.ev-viewgroups:before {
  content: "\24";
}
.ev-viewusers:before {
  content: "\25";
}
.ev-workflow-permission:before {
  content: "\26";
}
.ev-attribute:before {
  content: "\2d";
  zoom: 1.1;
}
.ev-source-attr:before {
  content: "\27";
}
.ev-destination-attr:before {
  content: "\28";
}
.ev-list:before {
  content: "\29";
}
.ev-link-list:before {
  content: "\2a";
}
.ev-topology-list:before {
  content: "\2b";
}
.ev-manage-network-element:before {
  content: "\6e";
}
.ev-download:before {
  content: "\3a";
}
.ev-export-center:before {
  content: "\3b";
}
.ev-theme:before {
  content: "\3c";
}
.ev-user:before {
  content: "\3d";
}

.ev-expired:before {
  content: "\2e";
}
.ev-success:before {
  content: "\2f";
}
.ev-navigation:before {
  content: "\3e";
}
.ev-data-flow:before {
  content: "\3f";
}
.ev-report-nav:before {
  content: "\40";
}
.ev-routing-group:before {
  content: "\5b";
}
.ev-routing-management:before {
  content: "\5d";
}
.ev-settings:before {
  content: "\5e";
}
.ev-automation:before {
  content: "\5f";
}
.ev-ne-allocation:before {
  content: "\60";
}
.ev-arrow-down:before {
  content: "\7b";
}
.ev-arrow-left:before {
  content: "\7c";
}
.ev-arrow-right:before {
  content: "\7d";
}
.ev-arrow-up:before {
  content: "\7e";
}
.ev-arrows:before {
  content: "\5c";
}
.ev-caret-down:before {
  content: "\e000";
}
.ev-caret-left:before {
  content: "\e001";
}
.ev-caret-right:before {
  content: "\e002";
}
.ev-caret-up:before {
  content: "\e003";
}
.ev-chevron-down:before {
  content: "\e004";
}
.ev-chevron-left:before {
  content: "\e005";
}
.ev-chevron-right:before {
  content: "\e006";
}
.ev-chevron-up:before {
  content: "\e007";
}
.ev-annotate:before {
  content: "\e008";
}
.ev-association-format:before {
  content: "\e009";
}
.ev-association-settings:before {
  content: "\e00a";
}
.ev-data-association:before {
  content: "\e00b";
}
.ev-threshold:before {
  content: "\e00c";
}
.ev-ban:before {
  content: "\e00d";
}
.ev-calendar:before {
  content: "\e00e";
}
.ev-check:before {
  content: "\e00f";
}
.ev-clock:before {
  content: "\e010";
}
.ev-clone:before {
  content: "\e011";
}
.ev-close:before {
  content: "\e012";
}
.ev-collection-aggregation:before {
  content: "\e013";
}
.ev-history:before {
  content: "\e014";
}
.ev-undo:before {
  content: "\e015";
}
.ev-tags:before {
  content: "\e016";
}
.ev-save:before {
  content: "\e017";
}
.ev-trash:before {
  content: "\e018";
  font-size: 1.4rem;
}
.ev-pause:before {
  content: "\e019";
}
.ev-pencil:before {
  content: "\e01a";
}
.ev-plus-circle:before {
  content: "\e01b";
}
.ev-ellipsis-h:before {
  content: "\e01c";
}
.ev-ellipsis-v:before {
  content: "\e01d";
}
.ev-database:before {
  content: "\e01e";
}
.ev-external-link:before {
  content: "\e01f";
}
.ev-eye:before {
  content: "\e020";
}
.ev-files-o:before {
  content: "\e021";
}
.ev-filter:before {
  content: "\e022";
}
.ev-folder:before {
  content: "\e023";
}
.ev-folder-o:before {
  content: "\e024";
}
.ev-font:before {
  content: "\e025";
}
.ev-i-cursor:before {
  content: "\e026";
}
.ev-iframe:before {
  content: "\e027";
}
.ev-image:before {
  content: "\e028";
}
.ev-info:before {
  content: "\e029";
}
.ev-lock:before {
  content: "\e02a";
}
.ev-navicon:before {
  content: "\e02b";
}
.ev-paste:before {
  content: "\e02c";
}
.ev-pencil-square:before {
  content: "\e02d";
}
.ev-placeholder:before {
  content: "\e095";
}
.ev-play:before {
  content: "\e02e";
}
.ev-plus-square:before {
  content: "\e02f";
}
.ev-question:before {
  content: "\e030";
}
.ev-refresh:before {
  content: "\e031";
}
.ev-repeat:before {
  content: "\e032";
}
.ev-expand:before {
  content: "\e033";
}
.ev-compress:before {
  content: "\e034";
}
.ev-send:before {
  content: "\e035";
}
.ev-sign-out:before {
  content: "\e036";
}
.ev-single-value:before {
  content: "\e037";
}
.ev-star:before {
  content: "\e038";
}
.ev-star-o:before {
  content: "\e039";
}
.ev-app-access:before {
  content: "\e03a";
}
.ev-block-list:before {
  content: "\e03b";
}
.ev-fga-sub:before {
  content: "\e03c";
}
.ev-link-settings:before {
  content: "\e03d";
}
.ev-unreachability:before {
  content: "\e03e";
}
.ev-ml-jobs:before {
  content: "\e03f";
}
.ev-ansible-script:before {
  content: "\e040";
}
.ev-approval-email:before {
  content: "\e041";
}
.ev-design-settings:before {
  content: "\e042";
}
.ev-dynamic-resolution:before {
  content: "\e043";
}
.ev-email-settings:before {
  content: "\e044";
}
.ev-ldap:before {
  content: "\e045";
}
.ev-map:before {
  content: "\e046";
}
.ev-notification-email:before {
  content: "\e047";
}
.ev-pull-based:before {
  content: "\e048";
}
.ev-push-based:before {
  content: "\e049";
}
.ev-system-config:before {
  content: "\e04a";
}
.ev-workflow:before {
  content: "\e04b";
}
.ev-hour-glass-end:before {
  content: "\e04c";
}
.ev-hour-glass-mid:before {
  content: "\e04d";
}
.ev-hour-glass-start:before {
  content: "\e04e";
}
.ev-import:before {
  content: "\e04f";
}
.ev-time-comparison:before {
  content: "\e050";
}
.ev-art-board:before {
  content: "\e051";
}
.ev-bold-circle:before {
  content: "\e052";
}
.ev-diamond:before {
  content: "\e053";
}
.ev-hand:before {
  content: "\e054";
}
.ev-workflow-arrow:before {
  content: "\e055";
}
.ev-diff:before {
  content: "\e056";
}
.ev-heat-map:before {
  content: "\e057";
}
.ev-map-marker:before {
  content: "\e058";
}
.ev-spark:before {
  content: "\e059";
}
.ev-archive:before {
  content: "\e05a";
}
.ev-config-binding:before {
  content: "\e05b";
}
.ev-config-collection:before {
  content: "\e05c";
}
.ev-discovery-metric-binding:before {
  content: "\e05d";
}
.ev-discovery-metric-collection:before {
  content: "\e05e";
}
.ev-node-settings:before {
  content: "\e05f";
}
.ev-palette:before {
  content: "\e060";
}
.ev-probe-list:before {
  content: "\e061";
}
.ev-synthetic-binding:before {
  content: "\e062";
}
.ev-synthetic-collection:before {
  content: "\e063";
}
.ev-unlock:before {
  content: "\e064";
}
.ev-sql-binding:before {
  content: "\e065";
}
.ev-sql-collection:before {
  content: "\e066";
}
.ev-unified-file:before {
  content: "\e067";
}
.ev-reset-topology:before {
  content: "\e068";
}
.ev-zoom:before {
  content: "\e069";
}
.ev-zoom-in:before {
  content: "\e06a";
}
.ev-zoom-out:before {
  content: "\e06b";
}
.ev-api:before {
  content: "\e06c";
}
.ev-automation-main:before {
  content: "\e06d";
}
.ev-report-designer-wf:before {
  content: "\e06e";
}
.ev-file-text:before {
  content: "\e06f";
}
.ev-file-section:before {
  content: "\e070";
}
.ev-file-export:before {
  content: "\e071";
}
.ev-schedule-file-export:before {
  content: "\e072";
}
.ev-auditing-template:before {
  content: "\e073";
}
.ev-installer:before {
  content: "\e074";
}
.ev-users:before {
  content: "\e075";
}
.ev-download-license:before {
  content: "\e076";
}
.ev-area-layer:before {
  content: "\e077";
}
.ev-bamboo-build:before {
  content: "\e078";
}
.ev-bar-chart:before {
  content: "\e079";
}
.ev-branch:before {
  content: "\e07a";
}
.ev-build:before {
  content: "\e07b";
}
.ev-dashed:before {
  content: "\e07c";
}
.ev-dotted:before {
  content: "\e07d";
}
.ev-dotted-layer:before {
  content: "\e07e";
}
.ev-line-chart:before {
  content: "\e07f";
}
.ev-solid:before {
  content: "\e080";
}
.ev-version:before {
  content: "\e081";
}
.ev-collection-method:before {
  content: "\e082";
}
.ev-data-warehouse:before {
  content: "\e083";
}
.ev-ownership:before {
  content: "\e084";
}
.ev-reset-history:before {
  content: "\e085";
}
.ev-transfer-ownership:before {
  content: "\e086";
}
.ev-attribute-types:before {
  content: "\e087";
}
.ev-data-retention:before {
  content: "\e088";
}
.ev-hide:before {
  content: "\e089";
}
.ev-inprogress:before {
  content: "\e08a";
}
.ev-layers:before {
  content: "\e08b";
}
.ev-system-logs:before {
  content: "\e08c";
}
.ev-user-logs:before {
  content: "\e08d";
}
.ev-activate:before {
  content: "\e08e";
}
.ev-deactivate:before {
  content: "\e08f";
}
.ev-invite-user:before {
  content: "\e090";
}
.ev-upload:before {
  content: "\e091";
}
.ev-hide-deleted:before {
  content: "\e092";
}
.ev-show-deleted:before {
  content: "\e093";
}
.ev-signal:before {
  content: "\e094";
}
.ev-check-circle:before {
  content: "\e096";
}
.ev-cloud-upload:before {
  content: "\e097";
}
.ev-crosshairs:before {
  content: "\e098";
}
.ev-minus:before {
  content: "\e099";
}
.ev-opentelemetry:before {
  content: "\e09a";
}
.ev-analytics-job:before {
  content: "\e09b";
}
.ev-analytics-project:before {
  content: "\e09c";
}
.ev-search-icon:before {
  content: "\e09d";
}
.ev-custom-analytics:before {
  content: "\e09e";
}
.ev-pending:before {
  content: "\e09f";
}
.ev-retention-time-series:before {
  content: "\e0a0";
}
.ev-retention-big-data:before {
  content: "\e0a1";
}
.ev-blocker:before {
  content: "\e0a2";
}
.ev-critical:before {
  content: "\e0a3";
}
.ev-major:before {
  content: "\e0a5";
}
.ev-minor:before {
  content: "\e0a6";
}
.ev-trival:before {
  content: "\e0a4";
}
.ev-add-incident:before {
  content: "\e0a7";
}
.ev-incidents-explorer:before {
  content: "\e0a8";
}
.ev-incidents:before {
  content: "\e0a9";
}

.ev-sm {
  font-size: 1.2rem !important;
}
.ev-lg {
    font-size: 1.8rem !important;
    line-height: 0.75em;
    vertical-align: -15%;
}

.ev-2x {
    font-size: 2.5rem !important;
}

.ev-3x {
    font-size: 3.5rem !important;
}

.ev-4x {
    font-size: 4.5rem !important;
}

.ev-5x {
    font-size: 5.5rem !important;
}

.ev-6x {
  font-size: 6.5rem !important;
}
