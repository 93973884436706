/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.radiobutton-group {
    margin-bottom: 10px;
    .radiobutton {
        height: 0;
        width: 0;
        display: none;
        & + label {
            padding-left: 30px;
            display: inline-block;
            line-height: 24px;
            vertical-align: middle;
            cursor: pointer;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 400 !important;
            position: relative;
            min-height: 24px;
            span {
                position: absolute;
                top: 2px;
                left: 0;
                border: 2px solid @borders;
                background: @modeBgColor;
                cursor: pointer;
            }
        }
        &[disabled] ~ label{
            cursor: not-allowed;
            opacity: .5;
            span{
                cursor: not-allowed;
            }
        }
    }
    label {
        .userSelect(none);
    }
}

// radioButton
.radiobutton-group {
    .radiobutton-container{
        margin-bottom: 5px;
        &.cardGroup{
            .radiobutton-label{
                display: none;
            }
            .card{
                cursor: pointer;
                border: 1px solid @borders;
                border-bottom: 3px solid @borders;
                padding: 5px;
                .transition(@ambience2);
                .cardImg {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                }
                &.checked{
                    border-bottom: 3px solid @activeElement;
                    -webkit-box-shadow: 0 0 10px @widgetShadow;
                    -moz-box-shadow: 0 0 10px @widgetShadow;
                    box-shadow: 0 0 10px @widgetShadow;    
                }
            }
        }

        label{
            font-weight: 400 !important;
        }
    }
    .radiobutton {
        & + label > span{
            border-radius: 50%;
            width: 20px;
            height: 20px;
            &::before{
                content: '';
                top: 6px;
                left: 6px;
                background-color: @mainColor;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                position: absolute;
                display: inline-block;
                transform: scale(0);
                transition: all 250ms cubic-bezier(.4, .0, .23, 1);
            }
        }
        &:checked + label > span::before {
            transform: scale(3);
        }
    }
}
.disabled {
    .radiobutton-group {
        .radiobutton {
            & + label > span{
                &::before{
                    background-color: @borders;
                }
            }
        }
    }
}
.automationType-radio {
    @media (min-width: 992px){
        &.col-md-4 {
            width: 50%;
        }
    }
}
.sourceRadioContainer {
    .radiobutton-group{
        .radioWidth{
            width: 50%;
        }
    }
}