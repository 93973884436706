/* Copyright 2019 Greyskies. All Rights Reserved. */
@import '../../../config/variables-fns';


.fixedPlaceholder{
    position: fixed;
    top: 40px;
    width:  ~"calc(100% - 80px)";
    z-index: 101;
    .collapsablePlaceholder{
        padding: 5px 15px;
    }
    .placeholderExpand-btn {
        left: 0;
        right: 0;
        top: 0px;
        z-index: 1;
        width: 25px;
        height: 20px;
        margin: 0 auto;
        cursor: pointer;
        text-align: center;
        position: absolute;
        background: @modeBgColor;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        box-shadow: 0px 3px 4px -2px @widgetShadow;
        i.ev{
            margin-top: -2px;
        }
    }
    &.expandedPlaceHolder{
        top: 95px;
    }
    
}

.collapsableBarWrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    .data-input {
        margin-bottom: 5px !important;
    }
}

.btnCollapsedMargin{
    margin-top: 55px !important;
}

.collapsablePlaceholder{
    padding: 10px;
    box-shadow: 0 0 4px 0 @widgetShadow;
    .treeSelections {
        .selectionList{
            padding-right: 40px;
        }
        .NE-list{
            li{
                width: auto;
                padding-right: 5px;
            }
        }
    }
}

.collapsablePlaceholder .ac-Wrapper {
    .rbt { 
        .rbt-input-hint,
        .rbt-input-main.form-control{
                font-size: 1.33rem;
        }
    }
}

.expandAnimate{
    .transition(@ambience3);
}
        
.expanded {
    .fixedPlaceholder {
        top: -10px;
        width:  ~"calc(100% - 30px)";
        &.expandedPlaceHolder{
            top: 45px;
        }
    }
}