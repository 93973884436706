/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';
// Sidebar
.sidebar-wrapper {
    .transition(@ambience2);
    background-color: @sysSidebarBGColor;
    height: 100%;
    left: 0;
    position: fixed!important;
    width: 0;
    z-index: 1000;
    top: 50px;
    width: 50px;
    font-size: 12px;
    ul {
        ul {
            visibility: hidden;
            position: absolute;
            z-index: 598;
            width: @menuWidth;
            left: -@menuWidth;
            top: -1px;
            z-index: -1;
            .transition(@ambience3);
            opacity: 0;
            border-right: 1px solid @borders;
        }
        li:hover > ul:first-of-type {
            visibility: visible;
            left: 50px;
            opacity: 1;
        }
    }
    li {
        a {
            text-align: center;
            i.ev {
                vertical-align: middle;
                line-height: 38px;
            }
        }
        &.list-group{
            a{
                padding: 0 10px;
                text-align: left;    
            }            
        }
    }
    .responsive-navbar,
    .sidebar-nav {
        li a .sidebar-item-title {
            opacity: 0;
            transform: translateX(-200px);
        }
        ul li a .sidebar-item-title {
            opacity: 1;
            transform: none;
            vertical-align: middle;
        }
    }
}
.responsive-navbar {
    display: none;
}
.responsive-navbar,
.sidebar-nav {
    border: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    li {
        background-color: @sysSidebarBGColor;
        min-height: 40px;
        position: relative;
        list-style: none;
        a, .navbar-action-icon {
            width: 100%;
            color: @sysSidebarTextColor;
            display: block;
            height: 40px;
            text-decoration: none;
            border-left: 5px solid @sysSidebarBGColor;
            border-bottom: 1px solid @sysSidebarActiveBGColor;
            .transition(@ambience2);
            &:hover, &.active {
                background-color: @sysSidebarActiveBGColor;
                border-left: 5px solid @activeElement;
                color: @sysSidebarActiveTextColor;
                text-decoration: none;
            }
            &:focus {
                text-decoration: none;
            }
            .sidebar-item-title {
                display: inline-block;
                max-width: 100%;
                padding: 0 0 0 10px;
                opacity: 1;
                transition: visibility 0s, opacity 0.5s linear;
              }
        }

        &.list-group {
            border-radius: 0;
            margin-bottom: 0;

            > ul {
                li {
                  a {
                      background-color: @modeBgColor;
                      color: @mainText;
                      border-radius: 0;
                      border-width: 0;
                      border-top: 1px solid @borders;
                      text-align: start;
                      display: flex;
                      align-items: center;
                      &:hover ,&.active {
                          color: @mainText;
                          background-color: @hoverColor;
                          border-left: 5px solid @activeElement;
                      }
                  }
                  span {
                      display: inline-block;
                  }
                    &:last-of-type {
                        border-bottom: 1px solid @borders;
                        list-style-type:none;
                    }
                }
            }
            ul {
                padding: 0;
            }
            a.active{
                color: @sysSidebarActiveTextColor;
                background-color: @sysSidebarActiveBGColor;
                border-left: 5px solid @activeElement;
            }
        }
    }
}

.sidebar-nav {
    > .sidebar-brand {
        font-size: 18px;
        height: 65px;
        line-height: 60px;
        a {
            color: @mainColor;
            &:hover {
                background-color: none;
                color: @mainText;
            }
        }
    }
    > li:last-of-type {
        margin-bottom: 50px;
    }
}


@media(max-width: 768px) {
    .sidebar-wrapper {
        width: 0;
        display: none;
    }
    .responsive-navbar {
        display: block;
        position: relative;
        width: 100%;
        .list-group,
        li {
            line-height: 40px;            
            a, .navbar-action-icon {
                text-align: left;
                padding: 0 15px;
                i.ev {
                    text-align: left;
                    display: inline-block;
                    vertical-align: middle;
                }
                .navbar-item-title {
                    padding: 0 15px;
                }
            }
            > ul li a {
                padding: 0 5rem 0;
                span {
                    padding: 0 15px;
                }
            }
            &:last-of-type {
                height: auto;
            }
        }
    }
    .responsive-navbar,
    .sidebar-nav {
        li a, .navbar-action-icon {
            height: 40px;
        }
    }
}