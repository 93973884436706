/* Copyright 2019 Greyskies. All Rights Reserved. */
@import 'config/variables-fns';

.workflowVar-slide {
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: 1;
    .transition (@ambience3);
    ~.expandPreview-btn{
        .transition (@ambience3);
        top: 50%;
        &.validationReq:before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: @danger;
            border-radius: 50%;
            right: 0px;
            top: -5px;
        }
        &:hover,
        &:focus{
            color: @mainText;
        }
    }
    
    &.expanded {
        transform: translateX(0);
        height: 100%;
        width: 400px;
        ~.expandPreview-btn{
            left: 405px;
            top: 50%;
        }
    }
    .optionInput {
        width: ~'calc(100% - 90px)';
        margin-left: 10px;
    }
    .panels-container{
        box-shadow: 4px 0px 2px -2px @widgetShadow;
        margin-top: 0;
        .transition (@ambience3);
        .panel .panel-body {
            overflow: auto;
            height: ~"calc(100vh - 216px)";
        }
    }
}
.setAutomationWorkflow-page{
    position: relative;
    .expandPreview-btn{
        left: 40px;
        display: block;
        transform: translate(-100%, -100%);
    }
    ~.row {
        .btn-row{
            margin: 10px auto 0;
            padding: 10px 15px 0;
            .btn {
                margin-bottom: 0;
            }
        }
    }
}

#bpmncontainer {
    font-family: 'Segoe UI', Arial, "Helvetica Condensed", Helvetica, sans-serif;
    padding-left: 80px;
    padding-right: 10px;
    overflow: hidden;
    .bjs-powered-by {
        color: @mainText !important;
    }
    .bpmnView-area{
        height: ~"calc(100vh - 180px)";
        padding: 0;
        .bjs-container{
            padding-right: 2px;
        }
    }
    .bpmnProp-area{
        height: ~"calc(100vh - 200px)";
        max-height: 98vh;
        overflow-x:auto;
        margin: 10px 0;
        border: solid 1px @borders;
        background-color: @systemBackground;
        &:empty{
            display: none;
            ~.bpmnView-area{
                width: 100%;
            }
        }
        &:after{
            content: attr(title);
            position: absolute;
            top: 5px;
            left: 25px;
            z-index: 1;
            font-size: 16px;
            font-weight: bolder;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 250px;
        }
    }
    .djs-group {
        rect {
            fill: @systemBackground;
        }

        circle {
            fill: transparent !important;
            stroke: @mainText  !important;
        }
    }

    .djs-drag-active:hover {
        background-color: transparent !important;
    }

    .djs-direct-editing-parent {
        background-color: @modeBgColor  !important;
        border-radius: 11px;
    }

    .entry {
        color: @mainText;
        box-shadow: @widgetShadow;
        background-color: transparent;
        &.bpmn-icon-connection-multi::before{
            content: '\E810';
        }
        &.ev-ansible-script.ev-sm{
            font-size: 2rem !important;
            padding-top: 1px;
        }
        &.ev-reportdesigner{
            font-size: 2.1rem !important;
        }
        &:hover{
            cursor: pointer;
            background-color: @hoverColor;
        }
    }
    .djs-container{
        overflow: visible !important;

        .djs-palette {
            border: solid 1px @borders;
            left: -50px;
            top: 10px;
            .entry:before{
                vertical-align: unset;
                padding-bottom: 5px;   
            }

            .separator {
                color: @borders;
            }

            .djs-palette-entries {
                background-color: @systemBackground;
    
                .entry {
                    color: @mainText;
    
                    &:hover {
                        border-bottom: 2px solid @activeElement;
                        color: @mainText;
                    }
                    &.ev-ansible-script.ev-sm{
                        font-size: 2.5rem !important;
                    }
                    &.ev-reportdesigner{
                        font-size: 2.7rem !important;
                    }
                    &.ev-approval-email{
                        padding-top: 2px; 
                        padding-right: 3px;                   
                    }
                }

            }
        }
        .djs-overlays{
            .djs-context-pad.open{
                background-color: @modeBgColor;
                width: 55px;
                .entry{
                    margin: 0 4px 4px 0;
                }
                .group[data-group="connect"] {
                    clear: both;
                }
                .group[data-group="model"] > .entry{
                    float: left;
                }
            }
        }
    }

    .bpp-properties-panel {

        span,
        label {
            font-size: 1.25rem;
            color: @mainText;
        }

        input,select,#camunda-ansibleParams,#camunda-emailBody {
            background-color: @modeBgColor  !important;
            border-color: @borders;
            }
        input,select,#camunda-ansibleParams,#camunda-emailBody:focus{
            box-shadow: none;            
        }
        .bpp-properties-tab-bar {
            border-color: @borders;
        }
        .action-button.add,
        .action-button.clear{
            padding-top: 0px !important;
            background-color: @systemBackground;
            color: @mainText;
            border: none;
            &:hover {
                background-color: @hoverColor;
                .transition(@ambience2);
            }
        }
        .action-button.clear{
            background-color: transparent;
            padding-top: 4px !important;
            &:hover{
                background-color: transparent;
            }
        }
        .action-button.add{
            &:before{
                font-family: "eventum-font" !important;
                content: "\61";
                font-size: 1rem;
                
            }
        }

        .bpp-properties {
            background-color: @systemBackground;

            .bpp-properties-header {
                padding: 5px 5px 5px 0px;

                .label {
                    color: @mainText;
                    font-size: 0;
                    visibility: hidden;
                }
            }

            .bpp-properties-tab-link.bpp-active {
                a {
                    border-left: 3px solid @activeElement;
                    border-top: none;
                    background-color: @systemBackground;
                    color: @mainText;
                    font-weight: 700;
                    border-right-color: @borders;
                    font-size: 1.25rem;
                    border-radius: 0;
                }
            }
        }

        .bpp-properties-tabs-container {
            .bpp-properties-group>.group-toggle:hover {
                background-color: @borders;

                &:hover {
                    background-color: @activeElement;
                }
            }
            .bpp-properties-group:hover > .group-toggle{
                background-color:@borders ;
            }
            .bpp-properties-group.group-closed>.group-toggle {
                background-color: @borders;
            }
            .bpp-properties-group.group-closed:hover>.group-toggle {
                background-color: @activeElement;

            }

            .bpp-properties-group+.bpp-properties-group {
                border-top: 1px solid @borders;
            }
            .bpp-properties-entry .bpp-table-add-row label {
                color: @mainColor !important;
                font-size: 0 !important;
                &:before {
                    content: "Add Attachment";
                    color: @mainText;
                    font-size: 1.3rem;
                }
            }
        }

        .bpp-table-row > .action-button {
            border-left: none;
            position: static;
            padding: 5px;
            height: 28px;
            &:before {
                font-family: "eventum-font" !important;
                content: "\e018";
                font-size: 1.4rem;
                margin-top: 6px;
            }
        
        }
        .action-button:hover{
                color: @mainText;
        }
        button:focus{
            border-color: @borders;
            box-shadow: 0 0 0 2px @borders, 0 0 0 1px #cdcdcd inset
        }

    }
}
.hasPrefixSuffix{
    &:before {
        content: attr(data-prefix);
        position: absolute;
        left: 0px;
        top: 5px;
    }
    &:after {
        content: attr(data-suffix);
        left: ~"calc(100% - 12px)"; 
        top: 5px;
        position: absolute;
    }
}
.workflowAction-form {
    .placeholders-formElement {
        .data-input-label.input-group-addon.textEllipsis {
            text-align: left;
            ~ div{
                display: contents;
            }
        }
    }
}
.btn-row.textCenter{
    text-align: center;
}
.action-button.clear[data-action="onRemove"]{
    padding: 0px;
    &:before{
        font-family: "eventum-font" !important;
        content: "\e018";
        font-size: 1.2rem;
    }
}
.bpp-error-message{
    font-size: 1.2rem;
}
.djs-group{
    .djs-visual{
        polygon{
            fill: @modeBgColor !important;
            stroke: @mainText !important;
        }
        rect{
            stroke: @mainText !important;
            fill: @modeBgColor !important;
            fill-opacity: 1 !important;
        }
        path{
            stroke:@mainText !important;
        }
        text{
            fill:@mainText !important;
        }
    }
    .djs-element.djs-shape{
        path{
            fill:@mainText !important;
        }
    }
}
defs{
    path{
        stroke:@mainText !important;
        fill: @mainText !important;
    }
}
