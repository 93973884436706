/* Copyright 2019 Greyskies. All Rights Reserved. */
@import 'config/variables-fns';

.panels-container .panel .panel-body{
  min-height: auto;
  height: ~'calc(100% - 36px)';
}

.filter-multiple-select{
  margin-bottom: 5px;
}

.empty-search-row{
  padding-top: 9px;
  height: 300px;
}

.association-header {
  margin: 0;
  margin-top: 6px;
  font-weight: 600;
}

.association-page{
  .helpHeader{
    .horizontal-divider {
      margin-bottom: 0px !important;
    }
  }

  .btnCollapsedMargin {
    margin-top: 51px !important;
  }

  .collapsablePlaceholder {
    width: ~"calc(100% - 32px)";
    margin-left: 15px;
    margin-top: -6px;
  }

  .associationPlaceholderExpand {
    padding-top: 77px !important;
    margin-top: 0px !important;
  }

  .associationPlaceholderCollapse {
    padding-top: 18px !important;
    margin-top: 0px !important;
  }

  .previewHeight {
    height: ~'calc(100vh - 136px)';
    .transition(@ambience3);
  }

  .hasPlaceholder {
    &.withExpandedPlaceHolder {
      padding-top: 83px !important;

      .previewHeight {
        .transition(@ambience2);
        height: ~'calc(100vh - 190px)';
      }

      .tab-content {
        .transition(@ambience2);
        height: ~'calc(100vh - 223px)';
      }
    }

    &.withCollapsedPlaceholder {
      padding-top: 35px !important;
    }
  }

  .widgetPreview {
    .widget {
      padding: 0;
      margin-bottom: 0;
    }
    .vegaWrapper {
      overflow-x: hidden !important;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .topologyWrapper {
      overflow-x: hidden !important;
    }
  }
}

.dashboardFixedHeader{
  position: fixed;
  width: 100%;
  z-index: 12;
  width:  ~"calc(100% - 50px)";
  background-color: @systemBackground;
  margin-top: -12px;
  .horizontal-divider{
    margin-bottom: 0px !important;
  }
}
.fixedHeaderPadding{
  padding-top: 37px !important;
}
.widgetPreview{
  padding: 0 15px 0 0;
  .transition(@ambience2);
}
.associationTabs .nav-tabs i.ev.ev-lg {
  font-size: 2rem !important;
}

@media screen and (min-width:768px){

  .widgetPreview{
    float: right;
    &.expandedPreview{
      width: 100%;
      padding: 0 15px 0 20px;
    }
  }

  .associationTabs{
    .transition(@ambience2);
    position: absolute;
    z-index: 1;
    &.collapsedTabs{
      transform: translate(-100%);
    }
  }
  .expandPreview-btn{
    display: block;
  }
}

.float-right{
  float: right;
}
.vHidden{
  visibility: hidden;

}

@media screen and (max-width:1200px) and (min-width:768px){
  .expandPreview-btn{
    left:-8px;
  }
}
@media screen and (max-width:768px){
  .widgetPreview{
    padding: 0 15px;
  }
}

.hasPlaceholder{
  .transition(@ambience3);
  &.withExpandedPlaceHolder {
      padding-top: 95px !important;
  }
  &.withCollapsedPlaceholder{
      padding-top: 45px !important;
  }
}

// moved from collapsibleComponent.less
.expanded {
  .hasPlaceholder {
      &.withCollapsedPlaceholder {
          padding-top: 15px !important;
      }
      &.withExpandedPlaceHolder {
          padding-top: 65px !important;
      }
  }
  .report-wrapper {
      margin-top: 0;
  }
}
