/* Copyright 2019 Greyskies. All Rights Reserved. */

// Color variables

@sysLayoutBGColor: var(--sysLayoutBgColor);

@sysHeaderBGColor: var(--sysHeaderBGColor);
@sysHeaderTextColor: var(--sysHeaderTextColor);
@sysHeaderBorderColor: var(--sysHeaderBorderColor);
// ========
@sysSidebarBGColor: var(--sysSidebarBGColor);
@sysSidebarTextColor: var(--sysSidebarTextColor);
@sysSidebarActiveBGColor:  var(--sysSidebarActiveBGColor);
@sysSidebarActiveTextColor: var(--sysSidebarActiveTextColor);
// ========
@tableHeading: var(--tableHeading);
@tableHeadingTextingColor: var(--tableHeadingTextingColor);
@tableRowHoverBGColor: var(--tableRowHoverBGColor);
// ========
@panelBg: var(--panelBg);
@panelHeading: var(--panelHeading);
@panelHeadingText: var(--panelHeadingText);
// ========
@stepToDoBgColor: var(--stepToDoBgColor);
@stepToDoText: var(--stepToDoText);
@stepDoingBgColor: var(--stepDoingBgColor);
@stepDoneBgColor: var(--stepDoneBgColor);
// ========
@tabsHeadingBgColor: var(--tabsHeadingBgColor);
@tabsHeadingTextColor: var(--tabsHeadingTextColor);
@tabsBorderColor: var(--tabsBorderColor);
// ========
@success: var(--success); 
@warning: var(--warning);
@danger: var(--danger);
@info: var(--info);
// ======== dashboard
@widgetShadow: var(--sysWidgetShadow);
@widgetShadowHover: var(--sysWidgetHoverBg);
@toolbarBg: var(--toolbarBg);
@floatingBtn: var(--floatingBtn);
// ========
@activeBtn: var(--activeBtn);
@primaryBtn : var(--primaryBtn);
@cancelBtnBorder : var(--cancelBtnBorder);
@primaryBtnHover: var(--primaryBtnHover);
// ========
@disableBg: var(--disableBg);
@disableText: var(--disableText);
@disabledBorder : var(--disabledBorder);
// ========
@systemBackground: var(--sysBgColor);
@modeBgColor: var(--sysModeColor); //bg color for almost everything (modals - panels - ...)
@sysModeSemiTransparent: var(--sysModeSemiTransparent);
@sysTransitionColor: var(--sysTransitionColor);
@borders: var(--sysBorder);
@hoverColor: var(--sysHover);
// ========
@playBackSliderBGColor: var(--playBackSliderBGColor);
@playBackSliderTextColor: var(--playBackSliderTextColor);
@playBackSliderRail: var(--playBackSliderRail);
@playBackSliderRailBorder: var(--playBackSliderRailBorder);
@playBackSliderCurrentTrack: var(--playBackSliderCurrentTrack);
// ========
@selectedDateBgColor: var(--selectedDateBgColor);
// ========
@activeElement: var(--activeElement);
@mainText: var(--mainText);
@mainColor: var(--mainColor);
@iconsColor: var(--iconsColor);
@selectedTag: var(--selectedTag);

@focusInputText: var(--focusInputText); // used in react-tags.less ??!! 
@inputFocusBorder : @gray; // ?? used in forms, reactable, colorPicker, multipleSelect



@lightText: var(--lightText);
@pointerColor: var(--pointerColor); // ?? used where
@treeSelections : var(--treeSelections);
@analysisProgress: var(--analysisProgress);
@burgerMenuBG: var(--burgerMenuBG);

// =========
@gradientBg: var(--gradientBg);

// ================================================================

@fontFamily: var(--fontFamily);

// ======== Colors for Severity
@blocker: var(--blocker);
@critical: var(--critical);
@major : var(--major);
@minor : var(--minor);

// ================================================================
// Need to get rid of 
@white: #fff;
@lightGray: #ececec;
@midGray: #d7d7d7;
@gray:#a2a2a2;
@darkGray: #707070;
@lightBlack: #333;

@eventAlertHigh: #E3231B; 
@eventAlertMedium: #EC8923;
@eventAlertLow: #F8DE2C; 

@black: #000;

@lightSuccess: rgba(190,196,150,0.2);

@favorite: #f90; 
@variantWarning: #F8DE2C;

@lightWarning: rgba(255,187,169,0.2); 
@lightWarning2: #e7d09e; 

@olive: #a5ab81; 
@lightOlive: #bec496; 

@lightDanger: #df5b60;

@transparent: transparent;
@blue: #337ab7; 

@dateRange:#08c;
@LightInfo: rgba(175, 202, 197, 0.2);


@lightError: rgba(223, 91, 96, 0.2); 

@color27: #afcac5;
@color28: #568278;
@color29: #c2e2dc; // use in summary-panel-container class in STC file /// used in btns default hover as well // needs revisit
 

// ================================================================
// Needs explanation, and where they are being used
// what does 0 and 1 means here

// Width variables
@width0: 180px;
@width1: 16px;
@menuWidth: 235px;
// Height variables
@height0: 16px;
@height1: 32px;
@headerHeight: 50px;

// ================================================================
// Map Gradients (Map Widget)
@blueRedGradient: linear-gradient(90deg, 
	rgba(0, 255, 255, 0),
	rgba(0, 255, 255, 0),
	rgba(0, 191, 255, 1),
	rgba(0, 127, 255, 1),
	rgba(0, 63, 255, 1),
	rgba(0, 0, 255, 1),
	rgba(0, 0, 223, 1),
	rgba(0, 0, 191, 1),
	rgba(0, 0, 159, 1),
	rgba(0, 0, 127, 1),
	rgba(63, 0, 91, 1),
	rgba(127, 0, 63, 1),
	rgba(191, 0, 31, 1),
	rgba(255, 0, 0, 1));
@greenYellowGradient : linear-gradient(90deg,
	rgba(0, 0, 0, 0),
	rgba(0, 0, 0, 0),
	rgba(102, 255, 0, 1),
	rgba(147, 255, 0, 1),
	rgba(193, 255, 0, 1),
	rgba(238, 255, 0, 1),
	rgba(244, 227, 0, 1),
	rgba(249, 198, 0, 1),
	rgba(255, 170, 0, 1),
	rgba(255, 113, 0, 1),
	rgba(255, 57, 0, 1),
	rgba(255, 0, 0, 1),
	rgba(255, 0, 0, 1));

// ================================================================
// Transitions
@ambience1: all 0.3s ease-in-out, color 0s, background 0s, box-shadow 0s;
@ambience2: all 0.5s ease-in-out, color 0s, background 0s, box-shadow 0s;
@ambience3: all 0.7s ease-in-out, color 0s, background 0s, box-shadow 0s;
@bgAmbience: background 0.5s ease-in-out,color 0s, box-shadow 0s;
// ================================================================
.transform(@type , @factor) {    
	-webkit-transform:   @type(@factor);
	-moz-transform:      @type(@factor);
	-ms-transform: 		 @type(@factor);
	-o-transform: 		 @type(@factor);
	transform: 		     @type(@factor);
}
.transition (@transition) {
	-webkit-transition: @transition;  
	-moz-transition:    @transition;
	-ms-transition:     @transition; 
	-o-transition:      @transition;  
	transition:         @transition;  
}
.userSelect (@value) {
	-webkit-touch-callout: 	@value;
	-webkit-user-select: 	@value;
	-khtml-user-select: 	@value;
	-moz-user-select: 		@value;
	-ms-user-select: 		@value;
	user-select:			@value;
}
.borderRadius(@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}
// ================================================================
// this class is being imported from here to some less files, this is odd here 
// either to have the class in helper classes and add it to the html 
// or ?? import it from helper classes! 

.menuItemHover {
	background: @hoverColor;
	border-bottom: 1px solid @activeElement;
	color: @mainText;
	outline: none;
}