/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.markdown-body {
  & > * {
    // letter-spacing: 0.5pt;
    line-height: 1;
    font-family: arial;
    color: @mainText !important;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h1, h2{
    border-bottom: 0 transparent;
    padding-bottom: 0.3em;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  p{
    margin: 0;
  }
  table {
    display: block;
    width: 100%;
    overflow: auto;

    tr {
      background-color: @modeBgColor;
      border-top: 1px solid @borders;

      &:nth-child(2n) {
        background-color: @systemBackground;
      }
      th,
      td {
        padding: 6px 13px;
        border: 1px solid @borders;
      }
      th {
        font-weight: 600;
      }
    }
  }
  dl {
    padding: 0;

    dt {
      padding: 0;
      margin-top: 16px;
      font-size: 1em;
      font-style: italic;
      font-weight: 600;
    }
    dd {
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }
  img{
    background-color: @modeBgColor;
    display: block;
  }
}