/* Copyright 2019 Greyskies. All Rights Reserved. */

.topologyPopup .reactable.dynamicHeight .table-scrollable-area {
    max-height: 50vh !important;
}

@media screen and ( max-height: 1049px ) { 
    .topologyPopup .reactable.dynamicHeight .table-scrollable-area {
        max-height: 44vh !important;
    }
}
@media screen and ( max-height: 600px ) { 
    .topologyPopup .reactable.dynamicHeight .table-scrollable-area {
        max-height: 30vh !important;
    }
}