/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'variables-fns';

.animationName(@name){
    -webkit-animation-name: @name;
    animation-name: @name;
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.fadeInUp {
    .animationName(fadeInUp);
}
.fadeInLeft {
    .animationName(fadeInLeft);
}
.fadeOut {
    .animationName(fadeOut);
}
.fadeIn {
    .animationName(fadeIn);
}
.fadeHalfTo1 {
    .animationName(fadeHalfTo1);
    
}
.slideInRight {
    .animationName(slideInRight);
}
.slideOutRight {
    .animationName(slideOutRight);
}
.slideDown {
    .animationName(SlideDown);
}
.slideUp {
    .animationName(SlideUp);
}
.Spin {
    animation: Spin 2s infinite linear;
}
.blink {
    .animationName(blink)
}

// blink
.keyframes(blink, {
    25% {
        opacity: 1
    }
    50% {
    opacity: 0
    }
    75% {
    opacity: 1
    }
});

.keyframes(btn-scale-rot, {
    0%, 100% {
        transform: scale(1) rotate(45deg);
    }
    25% {
        transform: scale(1.5) rotate(45deg);
    }
    75% {
        transform: scale(0.8) rotate(45deg);
    }
});

// Fade In Up
.keyframes(fadeInUp, { 
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
});

// Fade In
.keyframes(fadeIn, { 
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
});

// Fade In
.keyframes(fadeHalfTo1, { 
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
});

// Fade Out
.keyframes(fadeOut, { 
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
});

// Loader Transition
.keyframes(loaderTransition, {
    0%,100% {
        border-color: @borders;
    }
    50% {
        border-color: @mainText;
    }
});

// Slide Down 
.keyframes(SlideDown, {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
});

// Slide Up 
.keyframes(SlideUp, {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
});
    
// Slide In Right 
.keyframes(slideInRight, {
    from {
        transform: translateX(0);
        opacity: 0;
    }
    to {
        transform: translateX(100%);
        opacity: 1;
    }
});

// Slide Out Right 
.keyframes(slideOutRight, {
    from {
        transform: translateX(100%);
        opacity: 1;
    }
    to {
        transform: translateX(0);
        opacity: 0;
    }
});

// Slide Out Right 
.keyframes(Spin, {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
});

// General animation Funcition
.keyframes(@name,@rules) {
    @-webkit-keyframes @name {
        @rules();
    }
    
    @-moz-keyframes @name {
        @rules();
    }
    
    @-ms-keyframes @name {
        @rules();
    }
    
    @-o-keyframes @name {
        @rules();
    }
    
    @keyframes @name {
        @rules();
    }
}

.keyframes(loadingLinear, { 
  from {
      background-position:0% 50%;
  }
  to {
      background-position:100% 50%;
  }
});

.keyframes(fadeInLeft, { 
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
});
