/* Copyright 2019 Greyskies. All Rights Reserved. */

@import "../../../../../config/variables-fns";

.treeWrapper.rc-tree {
  padding: 0;
  .rc-tree-treenode span.rc-tree-switcher,
  .rc-tree-treenode span.rc-tree-checkbox,
  .rc-tree-treenode span.rc-tree-iconEle {
    vertical-align: top;
  }
  .rc-tree-treenode-draggable,
  .rc-tree-node-content-wrapper,
  .rc-tree-treenode {
    display: inline-flex;
    align-items: center;
    cursor: grab;
    &:active, &:focus {
      cursor: grabbing;
    }
    .placeholderContainer .placeholderWrapper {
      width: 100%;
    }
    .treeSelections .tree-btn {
      border: 1px solid @borders;
      i[class*=ev-] {
        height: 30px;
      }
      .selectionCount {
        bottom: 20px;
      }
    }
  }
  .rc-tree-treenode {
    line-height: normal;
    .ms-parent .ms-drop ul{
      padding: 4px 4px 4px 4px;
      & > li {
        display: list-item;
      }
    }
  }

  .rc-tree-treenode .rc-tree-node-content-wrapper {
    height: auto;
    width: 100%;
    .data-input-label{
      cursor: grab;
      &:active, &:focus {
        cursor: grabbing;
      }
    }
  }

  .rc-tree-title {
    width: 100%;
  }
  input.form-control {
    margin: 0 !important;
  }
  .data-input {
    margin: 0;
  }
  .data-input-label {
    margin: 0;
  }

  .tempIconContainer{
    padding: 0 0 0 10px;
    width: 28px;
    margin-left: -20px;
    & .firstmenuIcon {
      margin-right: -8px;
    }
  }
  .rc-tree-list-holder{
    overflow-x: hidden !important;
    padding-right: 5px; // needed in case there is a scrollbar
    min-height: 140px;
  }
  .color-picker {
    cursor: pointer;
    margin: 0 10px 0 0;
    .color-button[style*='transparent'] {
      border: 1px solid @borders;
      background-image: repeating-conic-gradient(@transparent 0% 25%, @systemBackground 0% 50%) !important;
      background-size: 10px 10px;
    }
    .color-popover{
      left: auto !important;
      transform: none !important;
      z-index: 3;
      cursor: pointer;
      [title="transparent"] > div{
        background-image: repeating-conic-gradient(@modeBgColor 0% 25%, @systemBackground 0% 50%) !important;
        background-size: 15px 15px !important;
      }
    }
  }
}

.rc-tree {
  .treeNodeWrapper {
    // the selected dragged node
    .rc-tree-node-selected {
      background-color: @tableRowHoverBGColor;
      box-shadow: 0 0 0 1px @tableRowHoverBGColor;
    }
    // the dragging node 
    &.dragging {
      background-color: @tableRowHoverBGColor;
      .rc-tree-draggable-icon {
        background-color: @tableRowHoverBGColor;
      }
    }
    // hover on node
    &.rc-tree-treenode.drop-target {
      background-color: @hoverColor;
      .rc-tree-draggable-icon {
        background-color: @hoverColor;
      }
    }
    .rc-tree-node-content-wrapper {
      .rc-tree-title + div {
        background-color: @activeElement !important;
      }
    }
  }
  .rc-tree-treenode.drop-container ~ .rc-tree-treenode.drag-over-gap-bottom,
  .rc-tree-treenode.drop-container ~ .rc-tree-treenode.drop-target {
    border-left: 2px solid @activeElement;
  }
}
