/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';
.ms-parent {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 20px;
    &.ms-dashboard {
        margin-bottom: 0;
        width: 300px !important;
    }

    .ms-choice {
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        cursor: pointer;
        text-align: left;
        white-space: nowrap;
        color: @mainText;
        text-decoration: none;
        position: relative;
        border: 0;
        height: 32px;
        background-color: transparent;
        border-bottom: 1px solid @borders;
        .transition(@ambience1);
        .borderRadius(0);
        &:focus,
        &:hover {
            border-bottom: 1px solid @activeElement;
            outline: none;
            background-color: @hoverColor;
        }
        &.disabled {
            border-bottom: 1px solid @borders;
            cursor: not-allowed;
            border-color: @disabledBorder;
            color: @disableText;
        }
        & > span {
            padding: 3px 16px 3px 12px;
            font-size: 1.33rem;
            position: absolute;
            top: 0;
            left: 0;
            right: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            width: 100%;
            line-height: 2.6rem;

            &.placeholder {
                color: @disableText;
                border: @borders;
            }
        }
        & > div {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 30px;
            background: url('../../../../../img/chosen-sprite.png') 5px 8px no-repeat;
            &.open {
                background: url('../../../../../img/chosen-sprite.png') -13px 8px no-repeat;
            }
        }
    }

    .ms-drop {
        width: 100%;
        overflow: hidden;
        display: none;
        margin-top: -1px;
        padding: 0;
        position: absolute;
        z-index: 10000;
        background:  @modeBgColor;
        color: @mainText;
        border: 1px solid @borders;
        .borderRadius(0);
        &.bottom {
            top: 100%;
            -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
            -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
            box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
            margin-bottom: 40px;
        }
        &.top {
            bottom: 100%;
            -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
            -moz-box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
            box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
        }
        ul {
            overflow: auto;
            margin: 0;
            padding: 4px 4px 4px 4px;
            & > li {
                position: relative;
                list-style: none;
                background-image: none;
                padding: 1px 5px;
                transition: .5s all ease-in-out;
                display: inline-block;
                min-width: 100%;
                &.disabled {
                    opacity: .35;
                    filter: Alpha(Opacity=35);
                }
                &.multiple {
                    display: block;
                    float: left;
                    label {
                        width: 100%;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &.group {
                    clear: both;
                    &:focus,
                    &:hover {
                        background-color: @transparent;
                        border-bottom: 1px solid @transparent;
                        label {
                            color: @mainText;
                        }
                    }
                    label {
                        background: none;
                        padding: 0;
                    }
                }
                &:hover {
                    label:not(.disabled):before {
                        .menuItemHover;
                    }
                }
                label {
                    span {
                        transition: .5s all ease-in-out;
                    }
                    .userSelect(none);
                    &.optgroup {
                        margin-left: 0px;
                        padding-left:0px;
                        background-image: none;
                        font-weight: bold !important;
                    }
                }
            }
        }
        input[type="checkbox"] {
            vertical-align: middle;
        }
        .ms-no-results {
            display: none;
        }
        
        .ms-search {
            display: inline-block;
            margin: 0;
            min-height: 26px;
            padding: 4px;
            position: relative;
            white-space: nowrap;
            width: 100%;
            z-index: 10000;
            input {
                width: 100%;
                height: auto !important;
                min-height: 24px;
                padding: 0 20px 0 5px;
                margin: 0;
                outline: 0;
                font-family: sans-serif;
                font-size: 1em;
                border: 1px solid @borders;
                .borderRadius(0);
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                background: @modeBgColor url('../../../../../img/chosen-sprite.png') no-repeat 100% -20px;
                background: url('../../../../../img/chosen-sprite.png') no-repeat 100% -20px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, @modeBgColor), color-stop(0.99,@lightGray));
                background: url('../../../../../img/chosen-sprite.png') no-repeat 100% -20px, -webkit-linear-gradient(center bottom, @modeBgColor 85%,@lightGray 99%);
                background: url('../../../../../img/chosen-sprite.png') no-repeat 100% -20px, -moz-linear-gradient(center bottom, @modeBgColor 85%,@lightGray 99%);
                background: url('../../../../../img/chosen-sprite.png') no-repeat 100% -20px, -o-linear-gradient(bottom, @modeBgColor 85%,@lightGray 99%);
                background: url('../../../../../img/chosen-sprite.png') no-repeat 100% -20px, -ms-linear-gradient(top,  @modeBgColor 85%,@lightGray 99%);
                background: url('../../../../../img/chosen-sprite.png') no-repeat 100% -20px, linear-gradient(top,  @modeBgColor 85%,@lightGray 99%);
                &:focus,&hover{
                    border: 1px solid @inputFocusBorder;
                }
            }
            & , input {
                -webkit-box-sizing: border-box;
                -khtml-box-sizing: border-box;
                -moz-box-sizing: border-box;
                -ms-box-sizing: border-box;
                box-sizing: border-box;
            }
            ~ ul li.ms-select-all:has([style*='display: none']) {
                display: none;
            }
        }

    }
}

// Draw checkboxes in multipleSelect dropdown
.ms-parent {
    .ms-drop {
        ul {
            & > li {
                margin-bottom: 3px;
                border-bottom: 1px solid transparent;
                &:hover {
                    border-bottom-color: @activeElement;
                    background-color: @hoverColor;
                }
                label {
                    margin-left: 5px;
                    margin-bottom: 2px;
                    white-space: nowrap;
                    font-size: 1.33rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    height: 18px;
                }
                input[type="checkbox"],
                input[type="radio"] {
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: @modeBgColor;
                    border: 2px solid @borders;
                    margin: 0;
                    margin-right: 10px;
                    width: 18px;
                    height: 18px;
                    &::before {
                        content: "";
                        width: 6px;
                        height: 10px;
                        transform: scale(0);
                        transition: 120ms transform ease-in-out;
                    }
                    &:checked {
                        background-color: @mainColor;
                        border: none;
                    }
                    &:checked::before {
                        content: "";
                        position: absolute;
                        left: 16px;
                        top: 3px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    &:disabled {                      
                        color: @disableBg;
                        cursor: not-allowed;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

// Draw Icons before every item in the dropdown
.dropdownWithIcons {
    .text-menuItem {
      & span {
        position: relative;
        padding-left: 30px;
        &::before{
          position: absolute;
          left: 4px;
          top: 0px;
          font-family: 'eventum-font';
          font-size: 14;
        }
      }
    }
    .ms-choice {
        span{
            position: relative;
            &:not(.placeholder) {
                padding-left: 34px;
            }
            &::before{
              position: absolute;
              left: 12px;
              top: 3px;
              font-family: 'eventum-font';
              font-size: 14;
            }
        }
    }
}

// ========================

.NEPlaceholder-menu .ms-choice{
    border-top: 1px solid @borders;
    border-right: 1px solid @borders;
    & > span.placeholder{
        padding-top: 2px ;
    }
}
.Addon-ms-choice{
    .ms-choice{
        border-top: 1px solid @borders;
        border-right: 1px solid @borders;
    }
}
.TenantManagementGroup-menu .multipleSelect .ms-parent ul ,
.TenantGroup-menu.multipleSelect .ms-parent ul {
    max-height: 215px !important;
}
.mainPlaceHolderPath-formElement .multipleSelect .ms-parent ul {
    max-height: 80px !important;
}
.currentIsInactive{
    .ms-parent:before {
        content: '.';
        position: absolute;
        top: 5px;
        right: 30px;
        font-family: 'eventum-font';
        font-size: initial;
        color: @warning;
        z-index: 1;
    }
    &:hover .ms-parent:after {
        content: 'Selected ML Job is Inactive';
        top: 32px;
        right: 0;
        color: @lightText;
        background-color: @black;
        position: absolute;
        padding: 3px 7px;
        font-size: 1.3rem;
        z-index: 1;
    }
}