/* Copyright 2019 Greyskies. All Rights Reserved. */
@import '../../../config/variables-fns';

.helpHeader {
    .title-wrapper {
        display: inline-block;
    }
    .help-text {
        margin-bottom: 5px;
    }
}

.headerActions {
    display: inline-block;
    float: right;
}

.pageWarning {
    position: absolute;
    top: 50px;
    z-index: 9999;
    border-top: 8px solid @eventAlertMedium;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 8px; // for the back btn to work
    .warningMessage {
        background: @modeBgColor;
        padding: 5px 10px;
        border: 1px solid @borders;
        margin: 0;
        height: 30px;
        .warningIcon {
            color: @eventAlertMedium;
            margin-right: 8px;
        }
        .warningText {
            font-weight: 600;
            color: @mainText;
        }
    }
}