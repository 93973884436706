/* Copyright 2019 Greyskies. All Rights Reserved. */
// This file need breakdown

@import '../../../config/variables-fns';
@import '../../../config/animations';

//collapsableComponent
.collapsible-component {
    .collapsible-col {
        & > * {
            vertical-align: middle;
        }
        .collapsible-arrow {
            margin-right: 5px;
            height: 15px;
        }
        .collapsible-left-side-container {
            margin-right: 5px;
            display: inline-block;
        }
        .collapsible-label {
            margin: 0;
        }
        .layerSetting-switch { // switch btn
            margin: 0;
        }
    }
    .evc-container-sys-bg .evc-container-sys-bg {
        padding: 0;
    }
}

.collapsible-component{
    .collapsible-component{
        label{
            font-weight: 400 !important;
        }
        .boldTextOverRide{
            font-weight: bold !important;
        }
    }
    .pointer-cursor{
        .btn.btn-action{
            transition: all 0.3s ease-in-out, 0s color ease;
            &.disabled, 
            &[disabled] {
                background: @transparent;
                color: @disableText;
            }
        }
    }
}
