/* Copyright 2019 Greyskies. All Rights Reserved. */
@import '../../../config/variables-fns';

.btn-default.floating-btn, .floating-btn{
    position: fixed;
    font-family: Arial;
    bottom: 40px;
    right: 40px;
    width: 55px;
    height: 55px;
    color: @white;
    font-size: 3.8rem;
    border-radius: 50%;
    padding-top: 0;
    margin-bottom: 0;
    background-color: @floatingBtn;
    box-shadow: 0 0 10px 0 rgba(0,0,0,26%);
    z-index: 1000;
    transition: all 0.5s;
    span {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    &:hover,&:focus, &:active{
        box-shadow: 0 0 15px 0 rgba(0,0,0,40%);
        background-color: @floatingBtn;
        filter: contrast(80%);
        outline: none;
    }
}
.floatingBtn{
    font-size: 3.8rem;
}