/* Copyright 2019 Greyskies. All Rights Reserved. */

@import "../../../config/variables-fns";

//Modal & dialogs
.modal-backdrop {
  z-index: 10031;
  &.backdropTransparent {
    background-color: transparent;
  }
}
.modal {
  z-index: 10032;
  overflow: hidden !important;
  .modal-dialog {
    position: relative;

    &.xlgModal {
      width: 90%;
    }

    &.modal-md .modal-content {
      .modal-body,
      .modal-body .modal-body-wrapper {
        max-height: auto;
        height: auto;
        overflow-y: visible;
      }
    }

    .modal-content {
      background-color: @panelBg;
      border-radius: 0;
      box-shadow: none;
      box-shadow: 0 0 40px -25px @widgetShadow;
      .modal-header {
        background-color: transparent;
        border-bottom: 1px solid @borders;
        padding: 10px 15px;
        button {
          opacity: 1;
          &.close {
            margin-top: 0;
            text-shadow: none;
            color: @mainText;
            &[disabled] {
              color: @disableText;
              cursor: not-allowed;
              pointer-events: none;
            }
          }
        }
        .modal-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 22px;
          font-size: 1.4rem;
          color: @mainText;
        }
      }
      .modal-body {
        overflow-y: auto;
        max-height: 70vh;
        .modal-body-wrapper {
          min-height: 150px;
          max-height: ~"calc(100vh - 200px)";
          height: 420px;
          &.autoHeight {
            height: auto;
          }
        }
        .close {
          color: @mainText;
          opacity: 1;
        }
        // =====
        .tab-content {
          .annotatable-container svg {
            .transform(translateY, 33px);
          }
        }
        .annotatable-container {
          margin-top: 0;
        }
      }
      // =====
      .chosen-container,
      .form-control {
        margin-bottom: 0;
      }

      .dataTables_filter {
        label {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.bootbox {
    .bootbox-close-button {
      color: @mainText;
    }
    .close {
      color: @mainText;
    }
    .modal-dialog {
      .modal-content {
        .modal-body,
        .modal-body .modal-body-wrapper {
          min-height: inherit;
          height: auto;
          .bootbox-body {
            word-break: break-word;
          }
        }
      }
    }
  }
  &.ownershipConfirmation .modal-dialog .modal-content {
    .modal-body,
    .modal-body .modal-body-wrapper {
      min-height: auto;
      max-height: unset;
    }
  }
  &.modal-auto-height .modal-dialog .modal-content {
    .modal-body,
    .modal-body .modal-body-wrapper {
      height: auto;
    }
  }
}

//
.modal-footer {
  border: none;
}

.modal-footer {
  // for the bootbox
  .btn {
    min-width: 75px;
    color: @white;
    margin-bottom: 0;
  }
  .btn-cancel {
    border: 2px solid @cancelBtnBorder;
  }
  .btn-primary {
    border: 2px solid @primaryBtn;
  }
}

.modal-dialog .modal-footer .btn[data-bb-handler="cancel"] {
  background: @transparent;
  color: @mainText !important;
  border: 2px solid @cancelBtnBorder;
  &:focus,
  &:hover {
    outline: none;
    background: @hoverColor;
  }
}

//bootbox are modals it's style based on bootstrap modal

.bootboxWarning,
.modelHeaderWarning {
  .modal-header {
    background-color: @warning !important;
    color: @white;
    .close {
      color: @white !important;
    }
  }
  .btn.btn-primary {
    background-color: @warning;
    border-color: @warning;
    &:hover,
    &:focus,
    &:active {
      background-color: @warning;
      border-color: @warning;
      opacity: 0.8;
    }
  }
}

.bootboxError {
  .modal-header {
    background-color: @danger !important;
    color: @white;
    .close, .modal-title {
      color: @white !important;
    }
  }
  .row:last-child {
    ul {
      margin-bottom: 0px;
    }
  }
  .btn.btn-primary {
    background-color: @danger;
    border-color: @danger;
    &:hover,
    &:focus,
    &:active {
      background-color: @danger;
      border-color: @danger;
      opacity: 0.8;
    }
  }
}
