/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../config/variables-fns';

.scrollMixin(@width ,@hight){
	width: @width;
	hight: @hight;
}

.scrollarea-content {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
	
    &:focus {
		outline: 0;
    }
}
.manageDashboards-page,
.manageReports-page {
	.scrollarea-content{
		overflow: auto;
		&::-webkit-scrollbar {
			width: 0;
		}
	}
}

.scrollarea {
	position: relative;
	overflow: hidden;
	&:hover {
		.scrollbar-container {
			opacity: .3;
		}
	}

	.scrollbar-container{
        -webkit-transition: all .4s;
        transition: all .4s;
        position: absolute;
        background: none;
        outline: none;
		opacity: .13;
		
		&.vertical,&.horizontal {
			.scrollbar {
				background: @darkGray !important;
				outline: none;
			}
		}

		&.horizontal {
			.scrollMixin(100% ,10px);
			left: 0;
			bottom: 0;
			
			.scrollbar {
				.scrollMixin(20px ,8px);

				margin-top: 1px;
			}
		}
		
		&.vertical {
			.scrollMixin(15px ,100%);
			right: 1px;
			top: 0;
			&:hover {
				background: @transparent;
			}
			.scrollbar {
				.scrollMixin(8px ,20px);
				margin-left: 1px;
			}
		}
	}
	.scrollbar-container {
       &:hover,
        &.active {
            background: @darkGray;
			opacity: .8 !important;
			transform: scaleX(1.5);
        }
	}
}

::-webkit-scrollbar {
	width: 4px;
	height: 10px;
}
body::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}
::-webkit-scrollbar-track {
	background: rgba(112, 112, 112, 0.13);
}
::-webkit-scrollbar-thumb {
	background: rgba(160, 160, 160, 0.3);
}
::-webkit-scrollbar-thumb:hover {
	background: rgba(112, 112, 112, 0.7);
}