/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

// react-template\src\js\components\templates\Footer.js
.footer {
    position: fixed;
    left: 50px;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: @systemBackground;
    border-top: 1px solid @borders;
    z-index: 10000;
    .footer-info-item {
        font-weight: normal;
        display: inline-block;
        margin: 0 1rem;
        &:last-child {
            margin: 0;
        }
    }
    .footer-copyright {
        img {
            margin-right: 10px;
        }
        h6 {
            display: inline-block;
        }
    }

    // upm-ui\src\js\components\layout\FooterBuildProps.js
    .footer-wrapper {
        display: flex;
        align-items: center;
        .footer-logo {
            padding: 5px 10px;
            height: 24px;
        }
        .footer-link {
            margin-right: 8px;
            color: @mainText;
            text-decoration: none;
        }
    }

    .footer-link,
    .footer-cpyrits-text,
    .footer-info-item,
    .semiBold-text {
        font-size: 1.2rem;
    }
}
