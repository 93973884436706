/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.popover.bottom > .arrow{
    border-bottom-color: @borders !important;
}
.popover.left > .arrow{
    border-left-color:@borders !important;
}
.popover.top > .arrow::after{
    border-top-color: @modeBgColor !important;
}
.popover.top > .arrow{
    border-top-color: @borders !important;
}
.popover {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    min-height: 200px;
    z-index: 10033;
    background-color: @modeBgColor;
    border: 1px solid @borders !important;
    &.NBIinfo-popover{
        max-width: max-content;
        min-height: auto;
        background-color: @modeBgColor;
        &.left .arrow:after {
            border-left-color:@modeBgColor;
        }
    }
    .popover-content{
        background-color: @modeBgColor;
    }
    &.reservedWords-popover{
        min-height: auto;
    }
    &.bottom .arrow:after {
        border-bottom-color: @panelHeading; // needs check for color var
    }
    
    &.quickFilter-popover{
        z-index: 10025;
        min-width: 350px;
        .filters-list{
            margin: 0;
            width: 100%;
            max-height: 120px;
            overflow: auto;
            overflow-x: hidden;
            padding: 5px 10px;
            &:not(:empty) {
                padding-left: 10px;
                display: inline-block;
                border-bottom: 1px solid @borders;
                margin-bottom: 5px;
            }
            .expandableArea {
                padding: 0 5px;
                margin-bottom: 5px;
                button{
                    padding: 4px 12px;
                }
            }
        }
        .ms-parent .ms-drop ul {
            max-height: 90px !important;
        }
    }

    .popover-title {
        border-radius: 0;
        background-color: @panelHeading;
        border-bottom-color: @borders !important;
    }

    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;

        &.success {
            background-color: @success;
        }

        &.warning {
            background-color: @warning;
        }

        &.danger {
            background-color: @danger;
        }
    }
}
// Tooltip
.tooltip {
    pointer-events: none !important;
    word-wrap: break-word !important;
    white-space: pre-wrap;
    .in{
        z-index: 10034;
    }
    .tooltip-inner {
        border-radius: 0;
    }
    &.tooltip-wrapped {
        word-wrap: break-word;
    }
}
#download-center-popover, #popover-export-center {
    top: 50px !important;
    width: 500px;
    position: fixed;
    .popover-content {
        padding: 9px 0 9px 14px;
        .scrollarea-content {
            padding: 0 10px 0 0;
        }
    }
}

.downloadCenter {
    height: 20px;

    > div.pull-left {
        padding-top: 4px;
    }

    > div.pull-right > * {
        background: none;
        padding: 0 12px;
    }
}

.tooltip.bottom{
    z-index: 10034 !important;
}
.attr-list{
    padding-left: 10px;
    display: inline-block;
    margin-bottom: 5px;
}
.filter-form-popover {
    min-width: 210px;
    --width:100px;
    &.sm-elm{
        --width:100px;
        transform: translateX(~'calc(var(--width) - 45px)');
    }
    &.md-elm{
        --width:180px;
        transform: translateX(~'calc(var(--width) - 165px)');
    }
    &.lg-elm{
        --width: 260px; 
        transform: translateX(~'calc(var(--width) - 245px)');
    }    
    .arrow{
        display: none;
    }
    .popover-content {
        padding: 5px 15px;
    }
    &.bottom{
        margin-top: 2px;
    }
}