/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.dynamicListItem {
  background-color: @systemBackground;
  padding: 2px 0 2px 5px;
  margin-right: -5px;
  margin-left: -5px;

  input {
    margin-bottom: 0px;
  }
  .savedVariable {
    input {
    text-overflow: ellipsis;
    background-color: @systemBackground;
    border: none;
    padding: 0 3px;
      &:focus,&:active{
        border:@borders 1px solid;
        background-color: @modeBgColor;
        padding: 6px 12px;;
      }
    }
    ~ .showSecure input:hover{
      cursor: url('../../../../../img/edit-cursor.png') , auto;
    }
  }
  .rowWithBtn {
    width: ~'calc(100% - 38px)';
  }
  .showSecure input {
    width: ~'calc(100% - 56px)';
    float: left;
    ~ button{
      height: 32px;
      &.ev-lock:before,
      &.ev-unlock:before {
        padding-top: 2px;
        display: inline-block;
      }
    }
  }
  // Ansible in Networks
  &.addOnlyColon .colon:after{
    content: ":";
    position: absolute;
    top: 5px;
    left: 99%;
  }
  // All of them (Workflow - Ansible - DataBinding)
  .hasColon {
    &.savedVariable input{
        pointer-events: none;
    }
    &:after {
      content: "} :";
      width: 15px;
      left: auto;
      right: -4px;
      top: 5px;
      position: absolute;
    }
    &:before {
      content: "${";
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
}
.setDataBinding-page{
  position: relative;
  ~.row {
      .btn-row{
          margin: 10px auto 0;
          padding: 10px 15px 0;
          .btn {
              margin-bottom: 0;
          }
      }
  }
}

.dynamicListItem .hasColon {
  // Only in Workflow I guess
  &.internalVariables,
  &.externalVariables {
    .form-control {
      float: left;
      width: ~'calc(100% - 50px)';
      ~ .btn {
        float: right;
      }
    }
    &:after{
      content: '}';
      right: 56px;
      left: auto;
      width: 5px;
    }
  }
}