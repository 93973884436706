/* Copyright 2019 Greyskies. All Rights Reserved. */

// summary panel
.summary-panel-container {
    .summary-panel {
      display: block;
      margin-bottom: 15px;
      background-color: @lightBlack;
      .transition(@ambience2);
  
      &:hover, &:focus {
        text-decoration: none;
        background-color: @darkGray;
        outline: none;
        cursor: pointer;
  
        .summary-icon-container {
          background-color: @mainColor;
        }
      }
  
      &.summary-panel-default {
        .summary-icon-container {
          background: @color27;
          color: @mainText;
        }
        &:focus,
        &:hover {
          .summary-icon-container {
            background: @color29;
          }
        }
      }
  
      &.summary-panel-primary {
        .summary-icon-container {
          background: @darkGray;
          color: @mainText;
        }
        &:focus,
        &:hover {
          .summary-icon-container {
            background: @gray;
          }
        }
      }
  
      &.summary-panel-warning {
        .summary-icon-container {
          background: @warning;
          color: @mainText;
        }
        &:focus,
        &:hover {
          .summary-icon-container {
            background: @lightWarning2;
          }
        }
      }
  
      &.summary-panel-danger {
        .summary-icon-container {
          background: @mainColor;
          color: @mainText;
        }
        &:focus,
        &:hover {
          .summary-icon-container {
            background: @mainColor;
          }
        }
      }
  
      &.summary-panel-success {
        .summary-icon-container {
          background: @olive;
          color: @mainText;
        }
        &:focus,
        &:hover {
          .summary-icon-container {
            background: @lightOlive;
          }
        }
      }
  
      .summary-icon-container {
        display: inline-block;
        background-color: @mainColor;
        .transition(@ambience2);
        .summary-icon {
          padding: 15px;
          color: @mainText;
  
          .ev {
            font-size: 2.7em !important;
          }
        }
      }
      .summary-details {
        display: inline-block;
        position: relative;
        top: 5px;
        padding-left: 10px;
        color: @mainText;
        .summary-content {
          font-size: 24px;
  
        }
      }
    }
  }
  