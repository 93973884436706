/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.ac-Wrapper {
    position: relative;
    .rbt { 
        .rbt-input-hint,
        .rbt-input-main {
            background-color:  transparent;
            border: none;
            border-bottom: 1px solid;
            border-color: @borders;
            
            &:hover {
                border-bottom: 1px solid @activeElement;
                background-color:  @hoverColor;
            }
            &.form-control{
                border: 1px solid @borders;
                margin-bottom: 0 !important;
            }
        }
        &.has-aux .rbt-input{
            padding-right: 12px;
        }
    }
    .rbt-menu {
        box-shadow: 0 4px 5px rgba( 0, 0, 0, 0.15);
        background: @modeBgColor;
        color: @mainText;
        border: 1px solid @borders;
        border-radius: 0;
        padding: 0;
        max-height: 301px !important;
        .dropdown-item {
            display: block;
            padding: 5px 10px;
            color: @mainText;
            font-size: 1.25rem;
            overflow: visible;
            &:nth-child(10) {
                margin-bottom: 20px;
            }
            &:hover,
            &.active {
                text-decoration: none;
                border-bottom: 1px solid @activeElement;
                background-color:  @hoverColor;
            }
        }
    }
}
