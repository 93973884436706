/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';


.normalLabel{
    background-color: transparent;
    padding-left: 0;
    padding-right: 20px;
    border: none;
    width: 25%;
    text-align: left;
}
.addon-input-group {
    .col-sm-12 {
        padding: 0;
    }
    .data-input,
    .data-input .input-text.form-control {
        margin: 0 !important;
    }
}