/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.aggregationInput, .groupingInput, .filterInput{
    .ms-parent{
        margin-bottom:0;
    }
}

.treeSelections {
    position: relative;
    padding: 0;
    min-height: 32px;
    width: 100%;
    background-color:@treeSelections;
    .transition(@ambience1);

    &:hover {
        background-color: @hoverColor;
    }
    .selectionList {
        margin: 0;
        list-style: none;
        padding: 0px 15px;
        padding-right: 32px;
        &.selectionListWithTwoIcons {
            padding-right: 42px;
        }
        &.selectionLabel,
        li {
            overflow: hidden;
            line-height: 32px;
            white-space: nowrap;
            text-overflow: ellipsis;
            .timeRangePadding{
                padding-left: 63px;
            }
            .timeRangeUlPadding{
                padding-left: 0px;
            }
        }
    }
    .form-group [type="text"]{
        border: none;
    }
    .tree-btn{
        bottom: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        outline: none;
        position: absolute;
        background-color: transparent;
        i[class*=ev-]{
            right: 0;
            bottom: 0;
            margin: 0;
            height: 32px;
            color: @mainText;
            padding: 0 12px;
            line-height: 32px;
            position: absolute;
            background: @hoverColor;
        }
        .selectionCount{
            z-index: 1;
            right: -6px;
            bottom: 25px;
            height: 15px;
            padding: 0 5px;
            font-size: 10px;
            line-height: 15px;
            position: absolute;
            border-radius: 5px;
            word-break: break-word;
            background-color: @primaryBtn;
        }
        &:focus ,
        &:hover {
            background-color: @transparent;
        }
    }

}

.disabled{
.treeSelections{
    li{
        color: @disableText;
    }
    .tree-btn{
        background-color: transparent !important;
        color: @disableText;
        .ev-tree {
            color: @disableText;
        }
    }
}
}

.ms-parent .ms-drop ul li { 
    label {
        &.disabled {
            cursor: not-allowed;
            opacity: 0.7;
        }
    }
}

.ds-collaspable-wrapper {
    padding: 5px 0 10px;
    .data-input-label {
        padding-top: 0
    }
}

.ds-source-field {
    padding: 5px 8px;
    display: inline-block;
}
// to be removed while refactoring filterInput & filterInputRow component
.data-selection-wrapper {
    .row.filterInput.margSides0 {
        margin-right: -15px;
        margin-left: -15px;
    }
}