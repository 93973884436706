/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.file-uploader-wrapper-fuw {
    .input-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .fuw-right-side {
        display: flex;
        align-items: center;
        width: calc(~'100% - 40px');
        border: 1px solid @borders;
        padding: 0 10px;
        height: 32px;
        .fuw-input {
            border: none;
            background: transparent;
            height: auto;
            padding: 0;
        }
    }
    .addonReset.input-group-addon {
        width: auto;
        .ev-upload, .ev-download, .ev-eye {
            font-size: 1.3em;
        }
        .ev-eye {
            margin-left: 0;
        }
        .upload-btn-wrapper, .ev-download {
            margin-left: 2px;
        }
        .upload-btn-wrapper {
            padding: 6px 10px 5px;
            height: 32px;
        }
        &.clearUploaded {
            margin-left: auto;
        }
    }
    .fuw-check-icon {
        color: green;
        font-size: 14px;
        margin-right: 5px;
    }
}

.btn.btn-default.tootltip-btn,
.btn.tootltip-btn {
    margin: 0;
    background: none;
    color: black;
    border: 1px solid;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    outline: none;
    i {
        font-size: 10px;
        display: flex;
    }
    &:hover {
        background-color: @hoverColor;
    }
}