/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';
// Navbar
.navbar {
    height: 50px;
    margin-bottom: 0;
    box-shadow: 0 6px 5px -8px @widgetShadow;
    &.navbar-inverse {
        background: @sysHeaderBGColor;
        z-index: 10030;
        border:none;
    }
    .navbar-brand {
        width:auto;
        height: 50px;
        color: @sysHeaderTextColor;
        padding: 7px 7px 7px 8px;
        margin-left: 0 !important;
        .transition(@ambience2);
        img {
            display: inline-block;
        }

    }
    .navbar-title {
        padding: 15px 15px 15px 8px;
        float: left;
        color: @sysHeaderTextColor;
        font-weight: bold;
    }
    .headerEllipsis{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 50vw;
    }
    .navbar-button {
        display: none;
        background: transparent;
        border: none;
        color: @sysHeaderTextColor;
    }
    .nav {
        .admin-logout {
            border-left: 1px solid @sysHeaderBorderColor;
            color: @sysHeaderTextColor;
            .dropdown-menu{
                background-color: @modeBgColor;
                border: 1px solid @borders;
                color:@mainText !important;
            }
            li {
                a{
                    color:  @mainText;
                }
            }
            a {
                span {
                    font-weight: bold;
                }
                &.dropdown-toggle {
                    color:@sysHeaderTextColor;
                     &:hover,&:focus{
                    background: None;
                    }

                    .ev {
                        padding-left: 5px;
                    }
                }
            }
        }
        .dropdown-menu {
            border-radius: 0;
            box-shadow: none;
            padding: 0;
            a {
                color: @sysHeaderTextColor;
                padding: 10px 20px;
                border-bottom: 1px solid @transparent; 
                .transition(@ambience2);
                &:hover {
                    .menuItemHover;
                }
                .ev {
                    padding-right: 5px;
                }
            }
        }
        .navbar-notification {
            .transition(@ambience1);
            a {
                color: @sysHeaderTextColor;
            }
            .navbar-action-icon{
                color:@sysHeaderTextColor;
            }
        }
    }
    .navbar-collapse {
        float: right;
    }
    .sidebar-navbar {
        display: none;
    }
    .container {
        padding: 0;
    }

}

// override bootstrap styles 
.navbar-inverse .navbar-nav > li > a, 
.navbar-inverse .navbar-nav > .open > a, 
.navbar-inverse .navbar-nav > .active > a {
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    text-shadow: none;
}

@media(max-width: 768px) {
    .navbar {
        .navbar-header {
            width: 100%;
        }
        .navbar-toggle {
            display: block;
            float: right;
            background-color: @burgerMenuBG;
        }
        .headerEllipsis{
            max-width: 75vw;
        }
        .navbar-button {
            display: block;
            float: right;
            background: transparent;
            border: none;
            color: @sysHeaderTextColor;
        }
        .hidden-xs {
            display: none !important;
        }
        .navbar-brand {
            text-align: start;
            width: auto;
        }
        .navbar-collapse {
            float: left;
            width: 100%;
            max-height: 500px;
            padding: 0;
            overflow-y: auto !important;
            &.collapse {
                display: none !important;
                &.in {
                    display: block !important;
                }
            }
        }
    }
}
@media(max-width: 1024px) {
    .navbar {
        .headerEllipsis{
            max-width: 45vw;
        }
    }
}
@media(max-width: 992px) {
    .navbar {
        .headerEllipsis{
            max-width: 35vw;
        }
    }
}

@media(max-width: 425px) {
    .navbar {
        .headerEllipsis{
            max-width: 45vw;
        }
    }
}
@media(max-width: 375px) {
    .navbar {
        .headerEllipsis{
            max-width: 40vw;
        }
    }
}
@media(max-width: 320px) {
    .navbar {
        .headerEllipsis{
            max-width: 35vw;
        }
    }
}