/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.loader-overlay {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    opacity: 1;
    transition: opacity 0.25s linear, z-index 0.5s linear;
    z-index: 10;
    &.fixedLoader {
      position: fixed;
    }
    .loader {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 25%;
        max-width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: @sysModeSemiTransparent;
    }
    &.loaded {
        opacity: 0;
        z-index: -1;
    }
    .line-scale-party>div, 
    .line-scale>div {
      background-color: @activeElement;
    }
}
// Progress bar
.progress {
    height: 25px;
    border-radius: 0;
    box-shadow: none;
    background: none;
    background-color: @stepToDoBgColor;
    border: 1px solid @borders;

    &[variant='success']{
      .progress-bar {
        background-image: none;
        background-color: @success;
      }
    }
    &[variant='info']{
      .progress-bar {
        background-image: none;
        background-color: @color28;
      }
    }
    &[variant='warning']{
      .progress-bar {
        background-image: none;
        background-color: @variantWarning;
      }
    }
    &[variant='danger']{
      .progress-bar {
        background-image: none;
        background-color: @mainColor;
        color: @mainText;
      }
    }

    .progress-bar {
        line-height: 25px;
        font-size: 14px;
        white-space: nowrap;
        color: @mainText;

        &.progress-bar-success {
            background-color: @success;
        }

        &.progress-bar-info {
            background-color: @color28;
        }

        &.progress-bar-warning {
            background-color: @warning;
        }

        &.progress-bar-danger {
            background-color: @mainColor;
            color: @mainText;
        }

        &.active {
            -webkit-animation-direction: reverse;
            -moz-animation-direction: reverse;
            -ms-animation-direction: reverse;
            -o-animation-direction: reverse;
            animation-direction: reverse;
        }
    }
}

// for now this class used also in DashboardLayout, but it needs to be moved to utilities classes 
.freeze{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top:0;
  left: 0;
  pointer-events: unset;
  z-index: 1;
  &.freezeDashboard{
    cursor: pointer;
  }
}

// this class used in ml > show chart > click play > queued message 
.analysisProgress{
  background-color:@analysisProgress;
  min-height: 50px;
  display: grid;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;

    .progress-scrollArea{
      max-height: 90px;
      margin: 10px 0;
    }

    .progress {
      margin-bottom: 0;
      height: 7px;
      border: none;
        .progress-bar{
          box-shadow: none;
        }
    }
    .steps{
      display: inline-block;
      line-height: 40px;
      font-weight: bold;
      margin: 0;
      padding-right: 15px;
    }
    .dashboard-action {
      margin-right: 0;
      margin-top: 5px;
    }
    .stepCounter{
      line-height: 40px;
      padding: 0 15px;
    }
    .danger{
      color:@lightDanger
    }
    .details{
      a{
        color: #2a8be0;
      }
      a:hover{
        color: @mainText;
      }
    }
}
.busyLinearLoader{
  width : 100%;
  height: 3px;
  background: linear-gradient(270deg, @mainColor, @mainColor, @midGray, @midGray,  @mainColor, @mainColor);
  background-size: 500% 1000%;
  position: absolute;
  top: 0;
  left: 0;    
  -webkit-animation: loadingLinear 1s linear infinite;
  -moz-animation: loadingLinear 1s linear infinite;
  -o-animation: loadingLinear 1s linear infinite;
  animation: loadingLinear 1s linear infinite;
  animation-direction: reverse;
  z-index: 1;
  .freeze{
    width: 100%;
    height: 100%;
    z-index: 105;
  }
}

#container > .loader-overlay .line-scale > div {
  background-color: @activeBtn;
}