/* Copyright 2019 Greyskies. All Rights Reserved. */
@import '../../../../config/variables-fns';

.rdtPicker {
    th {
        border-bottom: none !important;
    }

    td.rdtActive,
    td.rdtActive:hover {
        background-color: @darkGray  !important;
    }

    td.rdtToday:before {
        display: none !important;
    }
    .rdtBtn:hover{
        background-color: @hoverColor;
    }
}
.rdt {
    .rdtPicker {
        width: 100%;
        min-width: 150px;
        border: 1px solid @midGray;
    }
}
.rdtOpen .rdtPicker {
    margin-top: -6px !important;
    background-color: @modeBgColor;
    color: @mainText;
    bottom: 32px;
}

.rdtOpen.disableMinutes {
    .rdtCounters .rdtCounter:nth-child(3) { // second counter (minutes)
        color: @disableText;
        .rdtBtn:hover {
            background-color: transparent;
        }
    }
}

.timeRange{
    .rdt{
        .form-control {
            margin-bottom: 0 !important;
            &[readonly]{
                cursor:pointer;
                background: @modeBgColor;
            }
          }
    }
}