/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.tags-viewer {
  .rbt-input-multi{
    &.form-control {
      padding: 0;
    }
    &.form-control[disabled]{
      background-color: transparent;
      border: none;
      width: 240px;
      cursor: auto;
      @media screen and (min-width: 1201px) and (max-width: 1500px) {
        width: 210px;
      }
    }

    .rbt-input-wrapper{
      flex-wrap: nowrap;
      margin: 0;
      cursor: pointer;
      .tokenWrapper {
        max-width: ~"calc(100% - 40px)";
      }
      .rbt-token{
        max-width: 90px;
        padding: 0 6px;
        margin: 0 4px 0 0;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
        border: 1.5px solid @borders;
        background-color: @selectedTag;
        color: @mainText;
        @media screen and (min-width: 1201px) and (max-width: 1500px) {
          max-width: 80px;
        }
      }
      .rbt-input-main {
        width: 30px !important;
        &:disabled {
          pointer-events:none
        }
      }
    }
  }
  &.wrapped .rbt-input-multi .rbt-input-wrapper{
    flex-wrap: wrap;
    .rbt-token {
      margin-bottom: 4px;
    }
  }
}