/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';
.vg-tooltip {
  visibility: hidden;
  padding: 6px;
  position: fixed;
  z-index: 10034;
  font-family: sans-serif;
  font-size: 12px;
  background-color:@black;
  color: @lightText;
  
  td{
    overflow: hidden;
    text-overflow: ellipsis;
    .key{
      color: @darkGray;
      max-width: 150px;
      text-align: right;
      padding-right: 1px;

    }
    .value {
      width: auto;
      text-align: left;
  
    }
  }

   &.dark-theme {
    background-color: rgba(64, 64, 64, 0.9);
    border: none;
    color:@mainText;
    td.key {
      color: @gray;
    }
  }

  &.light-theme td.key {
    color: @darkGray;
  }
}
.vegaWrapper{
  text-align: center;
  .legendSymbol, .legendLabel {
    cursor: pointer;
  }

  &.drillable{
    .pieSlice {
      cursor: pointer;
    }
  }

  &.pointOverlay{
    g.overlay{
      cursor: pointer;
    }
  }
  &.pointDrilldown{
    .bar{
      cursor: pointer;
    }
  }
}
