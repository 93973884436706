/* Copyright 2019 Greyskies. All Rights Reserved. */

.dropdown {
  .dropdown-toggle {
    &.openLeft + .dropdown-menu{
      margin: 0;
      left: inherit;
      border-radius: 0;
      box-shadow: none;
      right: 0px;
      min-width: inherit;
      padding: 0;
      top: 30px;
      max-height: 165px;
      overflow: auto;
      cursor: default;
      background-color:@modeBgColor ;
      color:@mainText;
      top: 100%;
      li a {
        display: block;
        clear: both;
        font-weight: normal;
        font-family: Arial, "Helvetica Condensed", Helvetica, sans-serif;
        color: @mainText;
        white-space: nowrap;
        padding: 3px 15px;
        text-align: left;
        border-bottom: 1px solid transparent;
        i {
          padding-right: 10px;
          text-align: left;
          color: @mainText !important;

          &.ev-plus-square::before {
            transition: .3s box-shadow ease-in-out;
            box-shadow: -1px 0px 0px 0px @white, -3px -2px 0px 0px rgba(97, 97, 97, 0.6);
            border-radius: 3px;
          }
        }
        &:hover {
          background-color: @hoverColor !important;        
          border-bottom: 1px solid @activeElement;
          transition: all 0.5s ease-in-out;
          .ev-plus-square:before {
            box-shadow: -1px 0px 0px 0px @lightGray, -3px -2px 0px 0px rgba(97, 97, 97, 0.6);
            background-color: @modeBgColor;
          }
          background: @lightGray;
        }
      }
    }

    &.horizontalItems {
      z-index: 1;
      & + ul.dropdown-menu {
        top: 0;
        right: 100%;
        width: auto;
        display: inline-flex;
        transform: translate(100%);
        opacity: 0;
        .transition(@ambience2);
        z-index: -1;

        a{
          padding: 4px 10px;
          text-align: center;
          i{
            padding-right: 0;
          }
          .actionName {
            display: none;
          }
        }
        li{
          background-color: @hoverColor;
        }
      }
      &[aria-expanded="true"] + ul.dropdown-menu{
        transform: translate(0);
        opacity: 1;
        z-index: 0;
      }
    }
  }

  &.open {
    .dropdown-toggle.floating-btn + .dropdown-menu {
      display: flex;
      flex-flow: column-reverse;
      position: fixed;
      right: 40px;
      left: auto;
      top: auto;
      bottom: 100px;
      width: 170px;
      // overflow: hidden;
      .animated;
        .animationName(fadeIn);
      li {
        .animated;
        .animationName(fadeInUp);
        &.divider{
          margin: 1px 0;
          background-color:@borders ;
        }
        i {
          // float: right;
          padding-right: 10px;
          padding-left: 0;
          padding-top: 4px;
        }
      }
    }
  }
}

ul.dropdown-menu{
  background-color:@modeBgColor !important;
  border-color: @borders !important;
  border-radius: 0;
  background-clip: initial;
  li{
    &.disabled {
        color: @disableText;
        a {
          color: @disableText !important;
        }
    }
    a{
      color:@mainText !important;
      border-bottom: 1px solid transparent;
      &:hover {
        .menuItemHover;
      }
    }
  }
}

.ql-picker-options {
  background-color: @modeBgColor;
  
}
.ql-snow.ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  background-color: @modeBgColor;
  border-color: @borders;
  padding: 0;
  .ql-picker-item {
    color: @mainText;
    padding: 5px 10px;
    border-bottom: 1px solid @transparent;
    &:hover {
      .menuItemHover;
    }
  }
}
