/* Copyright 2019 Greyskies. All Rights Reserved. */
// This file need breakdown
@import '../../config/variables-fns';

.btn-group {
    .btn {
        margin-right: 2px;
    }

    .btn:last-of-type {
        margin-right: 0;
    }

    .dropdown.btn-group {
        margin-right: 2px;

        .btn:first-of-type {
            margin-right: 0;
        }
    }
    &.open .dropdown-toggle {
        background-color: @hoverColor;
        box-shadow: none !important;
    }
}

.btn {
    border-radius: 0;
    margin-bottom: 20px;
    text-shadow: none;
    box-shadow: none;
    border: none;
    .transition(@ambience1);
    color: @mainText;

    &.btn-lg {
        height: 40px;
        font-size: 1.4rem;
    }

    &.btn-default {
        background: @color27;
        outline: none;
        &:focus,
        &:hover {
            background: @color29;
        }
    }

    &.btn-primary {
        background: @primaryBtn;
        color:@white;

        &:focus,
        &:hover,
        &:active {
            background: @primaryBtnHover;
            border-color: @primaryBtnHover;
            outline: none;
        }
        &[disabled]:hover{
            border-color: @primaryBtn;
        }
    }
    &.btn-cancel {
        background: @transparent;
        color: @mainText !important;
        &:focus,
        &:hover {
            outline: none;
            background: @hoverColor;
        }
    }
    &.btn-warning {
        background: @warning;

        &:focus,
        &:hover {
            background: @lightWarning2;
        }
    }

    &.btn-danger {
        background: @mainColor;
        border: 2px solid @mainColor;
        color: @white !important;

        &:focus,
        &:hover {
            background: @lightDanger;
        }
    }

    &.btn-success {
        background: @olive;

        &:focus,
        &:hover {
            background: @lightOlive;
        }
    }

    &.btn-action {
        background: @transparent;
        width: initial;
        margin: 0;
        color: @mainText;
        
        &:focus,
        &:hover {
            background-color: @hoverColor !important;
            color: @mainText !important;
            outline: 0;
        }

        &.disabled,
        &[disabled] {
            color: @disableText !important;

            &:hover {
                color: @disableText !important;
            }
        }
    }

    &.btn-link {
        background: none;
        padding: 0;
    }

    &:focus,
    &:hover {
        outline: 0;
        box-shadow: none;
    }

    &.active {
        box-shadow: none;
    }

    &.btn-padding {
        padding-left: 0;
        padding-right: 0;
    }
}


.btn-row { // this class used to wrap almost every footer of buttons in the system (name not expressive, needs to be moved to utilities file)
    padding-top: 25px;
    padding-right: 25px; // this needs to be removed
    border-top: 1px solid @borders;
    margin: 30px auto 10px;
    opacity: 1 !important;
    text-align: right;
    .transition(@ambience2);

    .btn {
        margin-right: 5px !important;
        min-width: 75px;
        color:@white;
    }
    .btn-cancel {
        border: 2px solid @cancelBtnBorder;
    }
    .btn-primary {
        border: 2px solid @primaryBtn;
    }
}

.input-group{
    .btn{
    color:@white;
    }
}

// this needs to be moved to toggle group file and linked to the toggle group component 
.tglBtnGrp {
    .btn {
        margin-bottom: 5px;
        color: @mainText;
        border: 1px solid @borders;
        border-bottom-width: 2px;
        margin: 0;
        padding: 4px 10px;
        &.active {
            // pointer-events: none;
            background-color: @systemBackground;
            border-bottom-color: @mainColor;
            &:hover{
                color: @mainText;
            }
        }
        &:not(.active) {
            background-color:@modeBgColor;
        }
        &:active,
        &:focus {
            outline: none;
            color: @mainText;
        }
        &:first-child{
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
        &:last-child{
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }
    }
}

// from this line to the end of the file belongs to association and dashboard
// needs revisit and separation
.quickFilter{
    position: absolute;
    left: 10px;
    top: 0;
    border-radius: 0 0 10px 10px;
    border: none;
    width: 40px;
    height: 25px;
    z-index: 3;
    color: @mainText;
    background-color: @modeBgColor;
    box-shadow: 0 0 5px @widgetShadowHover;
    -webkit-text-stroke: 1px @mainText;
    -webkit-text-fill-color: @modeBgColor;
    text-align: left;

    &.disabledFilter::before{
        content: '';
        background-color: @mainText;
        transform: rotate(-45deg);
        position: absolute;
        display: block;
        height: 2px;
        width: 21px;
        top: 10px;
        left: 3px;
    }

    &::after{
        content: attr(data-filters-length);
        top: 8px;
        right: 5px;
        font-size: 1rem;
        height: 14px;
        background-color: @darkGray;
        color: @white;
        display: inline-block;
        position: absolute;
        text-align: center;
        padding: 0 2px;
        z-index: 1;
        line-height: 15px;
        border-radius: 5px;
        word-break: break-word;
        -webkit-text-stroke: 0;
        -webkit-text-fill-color: #fff;
    }
}

.refreshNow-btn{
  border-right: 1px solid @borders;
}

.refreshRate-menuBtn{
  padding: 6px 8px 6px 3px;
}

// this class added on every arrow icon that expands and collapse a sidebar, 
// used in “VariablesPanels - AssociateWidget - AddAutomativeWork - Analysis” 
.expandPreview-btn{
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    left: -3px;
    padding: 0;
    width: 22px;
    margin: 0;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    background: @modeBgColor;
    box-shadow: 4px 0px 2px -2px @widgetShadow;
    text-align: center;
    display: none;
    z-index: 1;
  
    &:focus, &:hover, &:active, &:active:focus {
      outline: none;
      border: none;
      box-shadow: 5px 0px 5px -1px @widgetShadowHover;
      color: @mainText;
    }
}
.expandedPreview{
    .expandPreview-btn{
      transform: translate(-100%, -50%);
      left: 23px;
    }
}

.selectionCount{
    z-index: 1;
    right: -6px;
    bottom: 20px;
    height: 15px;
    padding: 0 5px;
    font-size: 10px;
    line-height: 15px;
    position: absolute;
    border-radius: 5px;
    word-break: break-word;
    color: @lightText;
    background-color: @primaryBtn;
}
