/* Copyright 2019 Greyskies. All Rights Reserved. */
// Notes: 
// the main two colors here are (sysBgColor: which mainly used for the bg) and (sysModeColor: which is used for Elements bg like tables, inner panels, ...) 
// and we use both of them to switch between main colors (ex: table rows bg)

:root {
	--sysLayoutBgColor:#212227;

	--sysHeaderBGColor: #212227;
	--sysHeaderTextColor: #fff;
	--sysHeaderBorderColor: #707070;
	// ========
	--sysSidebarBGColor: #212227;
	--sysSidebarTextColor: #fff;
	--sysSidebarActiveBGColor: #4e4e4e;
	--sysSidebarActiveTextColor: #fff;
	// ========
	--tableHeading: #707070;
	--tableHeadingTextingColor: #fff;
	--tableRowHoverBGColor: #ececec;
	// ========
	--panelBg: #fff;
	--panelHeading: #d7d7d7;
	--panelHeadingText: #333;
	// ========
	--stepToDoBgColor: #d7d7d7;
	--stepDoingBgColor: #707070;;
	--stepToDoText: #d7d7d7;
	--stepDoneBgColor: #109618;
	// ========
	--tabsHeadingBgColor: #d7d7d7;
	--tabsHeadingTextColor: #333;
	--tabsBorderColor: #a2a2a2;
	// ======== these colors are the same shades in every theme, but only different success shade in Eventum dark theme
	--success: #109618;
	--warning: #f90; // will be used in star icon
	--danger: #dc3912;
	--info: #f1f2f7;
	// ========
	--sysWidgetShadow: rgba(0, 0, 0, 0.16);
	--sysWidgetHoverBg: rgba(0, 0, 0, 0.16);
	--toolbarBg: #fff; // used in dashboard and report toolbar
	--floatingBtn: #707070;
	// ======== btns
	--activeBtn:#707070;
	--primaryBtn: #707070; // used in buttons, and also in selectionCount (tree selection bagde number bg)
	--cancelBtnBorder: #A0A0A0;
	--primaryBtnHover :#505050;
	// ========
	--disableBg: #eeeeee;
	--disableText: #a2a2a2;
	--disabledBorder: #a2a2a2;
	// ========
	--sysBgColor: #f2f2f2;
	--sysModeColor:#fff;
	--sysModeSemiTransparent: rgba(255, 255, 255, 0.70); // used in wizard steps bg and loader bgd
	--sysTransitionColor: #f8f8f8;
	--sysBorder: #d7d7d7;
	--sysHover: #d7d7d7;
	// ========
	--playBackSliderBGColor: rgba(0,0,0,0.52);
	--playBackSliderTextColor: #fff;
	--playBackSliderRail: #e2e2e2;
	--playBackSliderRailBorder: #ffffffa1;
	--playBackSliderCurrentTrack: #bc0d1b;
	// ========
	--selectedDateBgColor: #707070; // inherited in dark
	// ========
	--activeElement: #bc0d1b;
	--mainText: #333;
	--mainColor:#bc0d1b;
	--iconsColor: #a2a2a2;
	--focusInputText: #707070;
	--selectedTag: #ececec; // used in emails & filterform & manage dashboard > sharing code 

	--lightText:#fff;
	--pointerColor:#000;
	--treeSelections:#ececec; // background of treeselection networks
	--analysisProgress:#dededecc; // ml > show chart > click play > queued message
	--burgerMenuBG: #333;
	--fontFamily: 'Segoe UI', Arial, "Helvetica Condensed", Helvetica, sans-serif;
	--gradientBg: linear-gradient(135deg, #FD0234, #702433, #333333);
	// ======== Colors for Severity
	--blocker: #E3231B;
    --critical: #E3231B;
    --major : #f36b43;
    --minor : #149BCB;


	&[data-theme="dark"] {
		--sysLayoutBgColor:#212227;

		--sysHeaderBGColor: #212227;
		--sysHeaderTextColor: #d4d4d4;
		--sysHeaderBorderColor: #707070;
		// ========
		--sysSidebarBGColor: #212227;
		--sysSidebarTextColor: #d4d4d4;
		--sysSidebarActiveBGColor: #4e4e4e;
		--sysSidebarActiveTextColor: #d4d4d4;
		// ========
		--tableHeading: #303135;
		--tableHeadingTextingColor: #d4d4d4;
		--tableRowHoverBGColor: #303135;
		// ========
		--panelBg: #212227;
		--panelHeading: #303135;
		--panelHeadingText: #d4d4d4;
		// ========
		--stepToDoBgColor: #212227;
		--stepDoingBgColor: #15161A;
		--stepToDoText:#5a5a5a;
		--stepDoneBgColor: #3b7a3f;
		// ========
		--tabsHeadingBgColor: #303135;
		--tabsHeadingTextColor: #d4d4d4;
		--tabsBorderColor: #a2a2a2;
		// ========
		--success: #3b7a3f;
		// ========
		--sysWidgetShadow: rgba(0, 0, 0, 0.4);
		--sysWidgetHoverBg: rgba(0, 0, 0, 0.65);
		--toolbarBg: #212227;
		--floatingBtn: #707070;
		// ========
		--activeBtn:#15161A;
		--primaryBtn: #707070;
		--cancelBtnBorder: #A0A0A0;
		--primaryBtnHover :#505050;
		// ========
		--disableBg: #1b1c1f;
		--disableText: #838383;
		--disabledBorder: #a2a2a2;
		// ========
		--sysBgColor: #15161A;
		--sysModeColor:#212227;
		--sysModeSemiTransparent: rgba(0, 0, 0, 0.70);
		--sysTransitionColor: #1c1d22;
		--sysBorder: #4e4e4e;
		--sysHover: #4e4e4e;
		// ========
		--playBackSliderBGColor: #47474770;
		--playBackSliderTextColor: #fff;
		--playBackSliderRail: #e2e2e2;
		--playBackSliderCurrentTrack: #da1c2b;
		// ========
		--activeElement: #da1c2b;
		--mainText: #d4d4d4;
		--mainColor:#da1c2b;
		--iconsColor: #a2a2a2;
		--focusInputText: #a9a9a9;
		--selectedTag: #303135;

		--lightText:#d4d4d4;
		--pointerColor:#fff;
		--treeSelections:#191a1d;
		--analysisProgress:#4e4e4ee3;
		--burgerMenuBG: #333;
		--fontFamily: 'Segoe UI', Arial, "Helvetica Condensed", Helvetica, sans-serif;
	}

	// =============

	&[data-layout-theme="greyskies"] {
		--sysLayoutBgColor: #FFFFFF;

		--sysHeaderBGColor: #FFFFFF;
		--sysHeaderTextColor: #404041;
		--sysHeaderBorderColor: #D3D4DE;
		// ========
		--sysSidebarBGColor: #047EAA;
		--sysSidebarTextColor: #D3E1E8;
		--sysSidebarActiveBGColor: #3095B9;
		--sysSidebarActiveTextColor: #fff;
		// ========
		--tableHeading: #DDDEE5;
		--tableHeadingTextingColor: #757679;
		--tableRowHoverBGColor: #E1EDF1;
		// ========
		--panelBg: #fff;
		--panelHeading: #DDDEE5;
		--panelHeadingText: #404041;
		// ========
		--stepToDoBgColor: #CBC9D1;
		--stepToDoText: #5A5466;
		--stepDoingBgColor: #5A5466;
		--stepDoneBgColor: #FFDC00;
		// ========
		--tabsHeadingBgColor: #DDDEE5;
		--tabsHeadingTextColor: #404041;
		--tabsBorderColor: #C0C0C0;
		// ======== dashboard
		--sysWidgetShadow: rgba(0, 0, 0, 0.16);
		--sysWidgetHoverBg: rgba(0, 0, 0, 0.16);
		--toolbarBg: #F1F2F7; 
		--floatingBtn: #404041bd;
		// ========
		--activeBtn:#FFDC00;
		--primaryBtn: #149BCB; 
		--cancelBtnBorder: #BCBBC5;
		--primaryBtnHover: #047EAA;
		// ========
		--disableBg: #E8EDF3;
		--disableText: #ACA9B3;
		--disabledBorder: #D3D4DE;
		// ========
		--sysBgColor: #F1F2F7;
		--sysModeColor:#fff;
		--sysModeSemiTransparent: rgba(255, 255, 255, 0.70);
		--sysTransitionColor: #F7F7FA;
		--sysBorder: #D3D4DE;
		--sysHover: #D3E5EB;
		// ========
		--playBackSliderBGColor: #a0a3af94;
		--playBackSliderRailBorder: #ffffffb3;		
		--playBackSliderCurrentTrack: #047eaa;
		// ========
		--selectedDateBgColor: #149BCB; // inherited in dark
		// ========
		--activeElement: #FFDC00;
		--mainText: #404041;
		--mainColor: #149BCB;
		--iconsColor: #AFC9D7;
		// --focusInputText: #707070;
		--selectedTag: #E1EDF1;

		--lightText: #fff;
		// --pointerColor:#000;
		--treeSelections:#E8EDF3; 
		--analysisProgress:#dddee5ad;
		// --burgerMenuBG: #333;
		--fontFamily: 'Segoe UI', Arial, "Helvetica Condensed", Helvetica, sans-serif;
		--gradientBg: linear-gradient(135deg, #149BCB, #0B8ABB, #024766);

	}
	&[data-layout-theme="greyskies"][data-theme="dark"] {
		--sysLayoutBgColor:#1E2231;

		--sysHeaderBGColor: #1E2231;
		--sysHeaderTextColor: #F7F7FA;
		--sysHeaderBorderColor: #253242;
		// ========
		--sysSidebarBGColor: #1E2231;
		--sysSidebarTextColor: #BCBBC5;
		--sysSidebarActiveBGColor: #00608c33;
		--sysSidebarActiveTextColor: #149BCB;
		// ========
		--tableHeading: #1B3240;
		--tableHeadingTextingColor: #FFFFFF;
		--tableRowHoverBGColor: #1B3744;
		// ======== these are not the panels in the dashboard, these are the inner panels 
		--panelBg: #1B2838;
		--panelHeading: #1B3240;
		--panelHeadingText: #F7F7FA;
		// ========
		--stepToDoBgColor: #1E2231;
		--stepDoingBgColor:#1B3B47;
		--stepToDoText:#F7F7FA;
		--stepDoneBgColor: #FFDC00;
		// ========
		--tabsHeadingBgColor: #1E2231;
		--tabsHeadingTextColor: #F7f7f7;
		--tabsBorderColor: #1B3B47;
		// ========
		--sysWidgetShadow: rgba(0, 0, 0, 0.4);
		--sysWidgetHoverBg: rgba(0, 0, 0, 0.65);
		--toolbarBg: #1A192A;
		--floatingBtn: #149BCB;
		// ========
		--activeBtn:#FFDC00;
		--primaryBtn: #149BCB;
		--cancelBtnBorder: #2C5362;
		--primaryBtnHover :#047EAA;
		// ========
		--disableBg: #1E2231;
		--disableText: #898F99;
		--disabledBorder: #2c5362;
		// ========
		--sysBgColor: #1A192A;
		--sysModeColor:#1B2838;
		--sysModeSemiTransparent: rgba(27, 40, 56, 0.3); 
		--sysTransitionColor: #1E2231;
		--sysBorder: #2C5362;
		--sysHover: #1B3B47;
		// ========
		--playBackSliderBGColor: #5d5f6794;
		--playBackSliderRailBorder: #ffffff7a;		
		// ========
		--activeElement: #FFDC00;
		--mainText: #F7F7FA;
		--mainColor:#149BCB;
		--iconsColor: #D3E1E8;
		// --focusInputText: #a9a9a9;
		--selectedTag: #1B3B47;

		// --lightText:#d4d4d4;
		// --pointerColor:#fff;
		--treeSelections:#1E2231;
		--analysisProgress:#214857a6;
		// --burgerMenuBG: #F7F7FA;
		--fontFamily: 'Segoe UI', Arial, "Helvetica Condensed", Helvetica, sans-serif;

	}

	// =============

	&[data-layout-theme="mobily"] {
		--sysLayoutBgColor: #FFFFFF;

		--sysHeaderBGColor: #FFFFFF;
		--sysHeaderTextColor: #000000;
		--sysHeaderBorderColor: #E4E7ED;
		// ========
		--sysSidebarBGColor: #0071E2;
		--sysSidebarTextColor: #D3E1E8;
		--sysSidebarActiveBGColor: #008cff;
		--sysSidebarActiveTextColor: #fff;
		// ========
		--tableHeading: #DDDEE5;
		--tableHeadingTextingColor: #51565B;
		--tableRowHoverBGColor: #E1EAF1;
		// ========
		--panelBg: #fff;
		--panelHeading: #DDDEE5;
		--panelHeadingText: #000000;
		// ========
		--stepToDoBgColor: #CBC9D1;
		--stepToDoText: #51565B;
		--stepDoingBgColor: #51565B;
		--stepDoneBgColor: #f2a23a;
		// ========
		--tabsHeadingBgColor: #DDDEE5;
		--tabsHeadingTextColor: #000000;
		--tabsBorderColor: #C0C0C0;
		// ======== dashboard
		--sysWidgetShadow: rgba(0, 0, 0, 0.16);
		--sysWidgetHoverBg: rgba(0, 0, 0, 0.16);
		--toolbarBg: #f2f4f8;
		--floatingBtn: #008cff;
		// ========
		--activeBtn:#f2a23a;
		--primaryBtn: #008cff;
		--cancelBtnBorder: #BCBBC5;
		--primaryBtnHover: #68bbff;
		// ========
		--disableBg: #edeff2;
		--disableText: #A9A9A9;
		--disabledBorder: #E4E7ED;
		// ========
		--sysBgColor: #f2f4f8;
		--sysModeColor:#fff;
		--sysModeSemiTransparent: rgba(255, 255, 255, 0.70);
		--sysTransitionColor: #F7F7FA;
		--sysBorder: #E4E7ED;
		--sysHover: #D3E1EB;    // Same as sys background (need revisit)
		// ========
		--playBackSliderBGColor: #a0a3af94;
		--playBackSliderRailBorder: #ffffffb3;
		--playBackSliderCurrentTrack: #0071E2;
		// ========
		--selectedDateBgColor: #008cff; // inherited in dark
		// ========
		--activeElement: #f2a23a;
		--mainText: #000000;
		--mainColor: #008cff;
		--iconsColor: #AFC9D7;
		// --focusInputText: #707070;
		--selectedTag: #E1EDF1;

		--lightText: #fff;
		// --pointerColor:#000;
		--treeSelections:#E8EDF3;
		--analysisProgress:#DDDEE5ad;
		// --burgerMenuBG: #333;
		--fontFamily: 'Scandia';
		--gradientBg: linear-gradient(135deg, #008CFF, #0080E9, #025AA3);
	}

	&[data-layout-theme="mobily"][data-theme="dark"] {
		--sysLayoutBgColor:#232A2D;

		--sysHeaderBGColor: #232A2D;
		--sysHeaderTextColor: #F7F7FA;
		--sysHeaderBorderColor: #253242;
		// ========
		--sysSidebarBGColor: #232A2D;
		--sysSidebarTextColor: #BCBBC5;
		--sysSidebarActiveBGColor: #00608c33;
		--sysSidebarActiveTextColor: #008cff;
		// ========
		--tableHeading: #2A343C;
		--tableHeadingTextingColor: #FFFFFF;
		--tableRowHoverBGColor: #2E3B45;
		// ======== these are not the panels in the dashboard, these are the inner panels
		--panelBg: #283134;
		--panelHeading: #2A343C;
		--panelHeadingText: #F7F7FA;
		// ========
		--stepToDoBgColor: #232A2D;
		--stepDoingBgColor:#31404C;
		--stepToDoText:#F7F7FA;
		--stepDoneBgColor: #f2a23a;
		// ========
		--tabsHeadingBgColor: #232A2D;
		--tabsHeadingTextColor: #F7f7f7;
		--tabsBorderColor: #31404C;
		// ========
		--sysWidgetShadow: rgba(0, 0, 0, 0.4);
		--sysWidgetHoverBg: rgba(0, 0, 0, 0.65);
		--toolbarBg: #19262A;
		--floatingBtn: #008cff;
		// ========
		--activeBtn:#f2a23a;
		--primaryBtn: #008cff;
		--cancelBtnBorder: #51565B;
		--primaryBtnHover :#68bbff;
		// ========
		--disableBg: #232A2D;
		--disableText: #898F99;
		--disabledBorder: #51565B;
		// ========
		--sysBgColor: #19262A;
		--sysModeColor:#283134;
		--sysModeSemiTransparent: rgba(27, 40, 56, 0.3);
		--sysTransitionColor: #232A2D;
		--sysBorder: #51565B;
		--sysHover: #31404C;
		// ========
		--playBackSliderBGColor: #5d5f6794;
		--playBackSliderRailBorder: #ffffff7a;
		// ========
		--activeElement: #f2a23a;
		--mainText: #F7F7FA;
		--mainColor:#008cff;
		--iconsColor: #D3E1E8;
		// --focusInputText: #a9a9a9;
		--selectedTag: #31404C;

		// --lightText:#d4d4d4;
		// --pointerColor:#fff;
		--treeSelections:#232A2D;
		--analysisProgress:#214857a6;
		// --burgerMenuBG: #F7F7FA;
		--fontFamily: 'Scandia';
	}

}
