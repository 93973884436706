/* Copyright 2019 Greyskies. All Rights Reserved. */
@import '../../../../config/variables-fns';

.itemsListComponentWrapper {
  &.hasBorders{
    border: 1px solid @borders;
  }
  .itemWrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    &:not(:last-child) {
      border-bottom: 1px solid @borders;
    }
  }
  .listItemWrapper {
    display: flex;
    .ev-users {
      margin-right: 6px;
    }
  }
  .itemActions {
    position: absolute;
    right: 0;
  }
}
