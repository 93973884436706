/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

// Tags input
.ql-snow .ql-stroke{
    stroke: @mainText !important;   
    button:hover{
        color: @mainText;
    }
}
.ql-snow .ql-fill{
    fill:@mainText !important;
} 
.ql-snow {
    border: 1px solid @borders !important;
  
    .ql-picker-label{
        color: @mainText !important;
    }
 
}
.react-tagsinput {
    background-color: @modeBgColor !important;
    border: 1px solid @borders !important;
    &.react-tagsinput--focused {
        border-color: @midGray;
    }

    .react-tagsinput-tag {
        background-color: @selectedTag;
        border-color: @borders;
        color: @mainText;
        border-radius: 0;
    }
    .react-tagsinput-remove {
        color: @mainText;
        vertical-align: middle;
        font-size: 1.7rem;
        line-height: 18px;
        &:hover {
            text-decoration: none;
        }
    }
    .react-tagsinput-input {
        width: 100%;
        color: @mainText;
        &::placeholder {
            color: @disableText;
            opacity: 1;
            .transition(@ambience1);
        }

        &:focus {
            &::placeholder {
                color: @focusInputText;
                .transition(@ambience1);
            }
        }
    }
}

//react tags component
.react-tags {
    padding: 5px 0 0 5px;
    margin-bottom: 10px;
    border: 1px solid @borders !important;

    .react-tags {
        padding: 0;
        border: 0 !important;
        margin-bottom: 0;
        border-radius: 0;
        display: inline-block;
        width: ~"calc(100% - 50px)";
    }

    .react-tags__search {
        width: 100%;

        input {
            background-color: @transparent;
            width: 100%;
        }
    }

    .react-tags__selected-tag {
        border-radius: 0;
        border-color: @borders;
        background-color: @selectedTag;
        color: @mainText;
        line-height: 1em;
        width: 100%;
        padding: 6px 8px;
        text-align: left;
        & > span {
            width: ~'calc(100% - 20px)';
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }

        &:after {
            font-size: 10px;
            font-weight: 700;
            color: @mainText;
            float: right;
        }
    }

    .react-tags__suggestions {
        z-index: 1000;
        max-height: 200px;
        overflow-x: hidden;
        font-size: 14px;
        text-align: left;
        margin-top: 6px;
        width: 100%;
        background-color: @modeBgColor !important;
        border: 1px solid @borders;
        color:@mainText;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: 0 3px 5px -3px rgba(0, 0, 0, .15);

        * {
            margin-top: 0;
            margin-bottom: 0;
            box-shadow: none;
            color:@mainText;
            border: @borders;

            :focus {
                outline: none;
                background-color: @modeBgColor !important;
            }
        }

        li {
            word-break: break-word;
            background-color: @modeBgColor !important;
            border-bottom: 1px solid @borders !important;

        }

        * {
            margin-top: 0;
            margin-bottom: 0;
            box-shadow: none;

            :focus {
                outline: none;
            }
        }
    }

    .tagsIcon {
        padding: 0 10px 0 15px;
    }
}
