/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';


.file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: @dateRange;
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
}

.uploadContent {
    background-color: @modeBgColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: ~'calc(100vh - 290px)';
    padding: 40px 16px 16px; 
    padding-top: 40px;
    .statusCountHeader {
        line-height: 1.4em;
    }
    &.result{
        align-items: initial;
    }
    .uploadValidationRow{
        border-bottom: 1px solid @borders;
        color: @mainText;
        margin-bottom: 10px;
        .entityName{
            .entityName-label {
                width: ~"calc(100% - 25px)";
                display: inline-flex;
                font-weight: normal !important;
            }
        }
        i{
          padding-right: 10px;
        }
    }

    p {
        color: @danger;
        text-align: center;
    }
    .drop-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 70%;
        height: 200px;
        border: 3px dashed @borders;
        cursor: pointer;
        .drop-message {
            font-weight: 400;
            text-align: center;
            .upload-icon{
                color: @iconsColor;
                width:100%;
            }
            a{
                text-decoration: underline;
                font-weight: bold;
            }
        }
        .file-input {
            display: none;
        } 
    }
  
    .file-display-container {
        width: 100%;
      
        .file-status-bar{
            width: 100%;
            vertical-align: top;
            margin-top: 10px;
            position: relative;
            line-height: 20px;
            
            & > div {
                overflow: hidden;
            }  
            .file-type {
                display: inline-block!important;
                position: absolute;
                font-size: 12px;
                font-weight: 700;
                line-height: 13px;
                margin-top: 4px;
                padding: 0 4px;
                border-radius: 2px;
                box-shadow: 1px 1px 2px #abc;
                color: @white;
                background: @dateRange;
                text-transform: uppercase;
            }
            
            .file-name {
                display: inline-block;
                vertical-align:top;
                margin-left: 50px;
                color: @dateRange;
            }
    
            .file-size {
                display:inline-block;
                color: @darkGray;
                font-weight: 700;
                margin: 0 10px;
            }
            .file-remove  {
                position: absolute;
                top: 2px;
                right: 5px;
                line-height: 15px;
                cursor: pointer;
            }
        }
    }
  
}

.file-progressBar {
    width: 100%;
    border-radius: 5px; 
    overflow: hidden;
    display:inline-block;
    margin:0px 10px 5px 5px;
    vertical-align:top;
    flex: 6 !important;
    margin-left: 50px;
    box-sizing: content-box;
    width:calc(100% - 50px)
    
    div {
        height: auto;
        color: #fff;
        text-align: right;
        line-height: 15px;
        width: 0;
        background-color: #4caf50; 
        border-radius: 3px; 
        font-size: 13px;
    }
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: @danger;
}
  
.file-type-logo {
    width: 50px;
    height: 50px;
   // background: url(./images/generic.png) no-repeat center center; 
    background-size: 100%;
    position: absolute;
}
  
.modal-body {
    .uploadContent {
        min-height: auto;
    }
}