/* Copyright 2019 Greyskies. All Rights Reserved. */
// these styles are common here and in DateRangePicker

@import '../../../../config/variables-fns';

.datePickerWrapper, 
.dateRangePickerWrapper {
    padding: 6px;
    &:hover {
        background: var(--sysHover);
    }
    .react-bootstrap-daterangepicker-container {
        cursor: pointer;
    }
    .calendarIconWrapper {
        margin-right: 5px;
    }
    .caretIcon {
        margin-left: 5px;
    }
    .clearIconWrapper { // clear exists only in DatePicker NOT DateRangePicker
        margin-left: 6px;
        cursor: pointer;
    }
    .dateText {
        font-size: 13px;
        font-weight: 600;
    }
}