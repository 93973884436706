/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.dashboard-action {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    position: relative;

    &.dashboard-slider-control {
        width: 32px;
        margin-right: 5px;

        &.has-DDl {
            width: auto;
        }
    }

    &:last-of-type {
        margin-right: 0;
        margin-top: 0;
    }

    button[disabled] {
        .ev {
            color: @gray;
        }
    }

    &:hover {
        button[disabled] {
            .ev {
                color: @gray;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        left: inherit;
        border-radius: 0;
        box-shadow: none;
        right: 0;
        min-width: inherit;
        padding: 0;
        background-color: @modeBgColor;
        color: @mainText;
        .disabled i {
            color: @disableText
        }
        a {
            padding-left: 12px;
            margin: 2px 0;
            border-bottom: 1px solid @transparent; 
            color: @mainText;
            &:focus,
            &:hover {
                .menuItemHover;
                .transition(@ambience2);
            }

            i {
                padding-right: 10px;
            }
        }
    }
    .ev {
        min-width: 13px;
    }
    .ms-parent .ms-choice > span {
        font-size: 1.4rem;
    }
    & .playback-btn {
        font-weight: 600;
    }
}

.dashboard-title {
    h4 {
        margin-top: 5px;
    }

    .ev {
        padding-right: 10px;
    }
}

.margBZero{
    margin-bottom: 0;
}

.react-grid-layout {
    .react-grid-item:not(.panels-container){
        transition: width 0.5s ease, height 0.5s ease, color 0s;
    }
    &.showBorders .react-grid-item:not(.react-draggable-dragging){
        transition: all 1s ease, width 0s, height 0s, color 0s;
    }
    &.panel-drag .react-grid-item:not(.react-draggable-dragging){
        transition: all 1s ease, width 0s, height 0s, color 0s;
    }
    &.layout-panel {
        .react-grid-item {
            position: 'relative' ;
            .react-resizable-handle {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAIAAAAP9fodAAAACXBIW…kTkoxIOeDHgxMnHvzAqkxAw8JAggO7ttGMw8DAwMAAGACSLGRfht8uOgAAAABJRU5ErkJggg==);
                opacity: 0;
                .transition(@ambience2);
                position: absolute;
                width: 20px;
                height: 20px;
                bottom: 0;
                right: 0;
                background: url('resize-handler.svg');
                background-position: bottom right;
                padding: 0 3px 3px 0;
                background-repeat: no-repeat;
                background-origin: content-box;
                box-sizing: border-box;
                cursor: se-resize;
                z-index: 105;
            }

            .resize-detector {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                visibility: hidden;
            }

            &:hover {
                z-index: 2;
                .react-resizable-handle {
                    opacity: 1;
                }
            }
        }
    }
}
.panels-container > .react-resizable-handle-e {
    top: auto;
    bottom: 0;
    opacity: 0;
    transform: rotate(0);
}
.panels-container:hover > .react-resizable-handle-e {
    opacity: 1;
}

[data-theme="dark"]{
    .react-resizable-handle {
        filter: invert(1);
    }
}

  .react-grid-item:not(.react-grid-placeholder){
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: hidden;
      overflow: visible;
  }

  .react-grid-item.react-grid-placeholder{
      background: @widgetShadow;
      opacity: 0.4;
      z-index:5;
  }

.react-grid-item a i.ev-arrows{
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}

.form-input-row{
    width: 100%;
    padding-bottom: 10px;
    clear: both;
    & > span{
        font-weight: bold;
    }
    .input-container{
        display: block;
    }
}

.panel .form-input-row .input-container .form-group .form-control{
    .margBZero;
}

.dashboard.popover{
    min-height: 0px;
    .download-popover-content a{
        color:@mainText;
    }
}

.dashboard-panel-heading.panel-handler{
    cursor: move;
}

.panels-container .panel.panel-dashboard .panel-heading.dashboard-panel-heading .panel-action-container {
    .panel-action, 
    .panel-action .panel-header-btn {
        color: inherit;
    }
}

// for submenu trigger items 
.panel-heading .widget-submenu-menuItem {
    & > a {
        padding: 0  !important;
    }
    .dropdown.btn-group {
        width: 100%;
        margin-left: 0;
        // SUBMENU trigger item
        .panel-action-sub-menu-btn {
            width: 100%;
            background: transparent  !important;
            & + .dropdown-menu {
                top: 0 !important;
                left: auto !important;
                right: 100% !important;
            }
        }
    }
}

.widget-submenu-menuItem:hover {
    ul.dropdown-menu{
        display: block;
    }
}

.panel-action .dropdown-menu li:not(.divider){
    height: 26px;
}

.dashboard-panel-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .panel-title-input{
        min-width: 150px;
        max-width: 100%;
        width:auto;
        transition: .5s all linear;
    }
}

.dashbordAction{
    font-size: 13px;
    font-weight:600 ;
}

.dimmed-large-btn {
    width: 130px;
    height: 130px;
    border: 2px dashed @tableRowHoverBGColor;
    background: @modeBgColor;
    margin-top: 15px;
    cursor:pointer;
    .text-content{
        text-align: center;
        color: @tableRowHoverBGColor;
        margin-top: 20%;
    }
    &.disabled {
        cursor: not-allowed;
    }
}
.slideshow-enter {
    opacity: 0.01;
    &.slideshow-enter-active {
        opacity: 1;
        transition: opacity 1s ease-in;
    }
}

.slideshow-leave {
    opacity: 1;
    &.slideshow-leave-active {
        opacity: 0.01;
        transition: opacity 500ms ease-in;
    }
}

.panel-scrollable-area > .content, .widget-scrollable-area > .content{
    display: inline-block;
    min-width: 100%;
    padding-right: 10px;
    padding-bottom: 10px;
    min-height: 100%;
}

.widget-scrollable-area{
    height: 100%;
}

.react-tags__suggestions{
    cursor:auto;
}
.panels-container {
    margin-top: 0;
    border: none!important;
    &.react-grid-item.cssTransforms{
        box-shadow: 0 0 5px 0 @widgetShadow;
        &:hover{
            box-shadow: 0 4px 6px 0 @widgetShadowHover;
            z-index: 3;
        }
    }
    .panel.panel-dashboard{
        border: none !important;
        background-color: @sysTransitionColor;
        .panel-heading.dashboard-panel-heading{
            .panel-title.dashboard-panel-title{
                max-width: ~'calc(100% - 135px)';
                width: auto;
            }
        }
    }
}

.ev-text-warning{
    color: @favorite;
}

.react-grid-layout {
    position: relative;
}
.react-grid-item {
    box-shadow: 0 0 4px 0 @widgetShadow;
    &:hover{
        box-shadow: 0 4px 10px 0 @widgetShadowHover;
    }
    &.react-grid-placeholder{
        box-shadow: none;
        animation: none;
        transition: none;
    }
    &.react-draggable-dragging {
        transition: none;
        transition-duration: .5s;
        z-index: 3;
    }
}

  .empty-widget-container{
    height:100%;
    width:100%;
    cursor: pointer;
    .empty-widget-textFit {
        height: 100%;
        &.centered span {
          position: relative;
          top:50%;
          transform: translateY(-50%);
        }
        &.disabled {
            cursor: not-allowed;
        }
    }
    &.viewer{
      cursor: default;
      .widget-footer{
        display: none;
      }
    }
  }

  .markdown-textarea{
    resize: none;
    width: 100%;
    height: 100%!important;
  }

.modal-body .empty-widget-textFit.centered span{
    transform: translateY(0);
}

.gauge-widget-container {
    h6:first-of-type{
        font-weight: bold;
        display: block;
        font-size: 5em;
        .margBZero;
    }

    h6:last-of-type{
        display: block;
        font-size: 1.6rem;
        margin-top: 10px;
        .margBZero;
        font-weight: bold;
    }
}

.gauge-settings.display {
    text-align: center;
    .svg-wrapper {
        text-align: center;
        svg.gauge{
            float:none;
            display: block;
            margin: 0 auto;
        }
    }
}

.gauge-widget-container {
    height: 100%;
}

.iframe-container{
    padding: 10px;
    border: none;
}

.series-table{
    width: 100%;
    margin-bottom: 15px;
    th{
        font-size: 1.3rem;
    }
}


.expression-form .data-input {
    &,input{
        .margBZero !important;
    }
}

.column-form {
    margin-bottom: 10px;
    .data-input {
        &,input{
            .margBZero;
        }
        .col-lg-5{
            padding: 0;
        }
    }
    .data-input
    .checkbox-group{
        .margBZero;
    }
    .checkbox-label{
        margin-top: 7px;
        .margBZero;
    }
}
.notifMsg{
    animation: SlideDown .5s 1;
}
.showBorders .widget{
    border: 2px dotted @borders;
}
.metric-settings .checkbox-label{
    margin-top:5px;
    .margBZero;
}

.widget-action-container {

    ul.dropdown-menu{
        height: auto;
        max-height: inherit;
        // overflow-x: hidden;
    }
}
#data-association-preview .gauge-widget-container h6:first-of-type {
    font-size: 20px;
}
.metric-precision-data-input{
    .margBZero;
}
.pgBreak:before{
    transform: rotate(90deg) translateX(25%) translateY(-10%);
    font-weight: bold;
    display: inline-block;
    width: 14px;
}
@media screen and (max-width:"1200px"){
    .column-form .data-input .col-lg-5 {
        padding: 0 15px;
    }
}

.widget-iframe ul > li.disabled {
    cursor: not-allowed;
    a{
        pointer-events: none;
    }
}

.topologyTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ~"calc(100% - 65px)";
    margin: 0 auto;
    padding: 10px;
}
.placeholderDashboardForm{
    .inputAlignment {
        display: block;
        margin-right: 0px;
    }
    .input-text{
    height: 34px;
    }
    .input-group{
        width: 100%;
        .input-text {
            margin-bottom: 0px !important;
        }
    }
    .input-group-addon{
        width: 125px;
        text-align: left;
    }
    .ms-parent.input-select{
        display: block ;
        margin-bottom: 0px !important;
        width: 100% !important;
    }
 
    .NE-list{
        li{
        max-width: 250px;
        margin-right: 0px;
            }
        }
}
.btnPadding{
    button{
        padding: 2px 6px;
    }
}
@media only screen and (min-width:1440px){
    .placeholderDashboardForm{
    .NE-list{
        li{
            max-width: 280px;
            margin-right: 0px;
          }
        }
}
}
@media only screen and (min-width:1600px){
    .placeholderDashboardForm{
    .NE-list{
        li{
            max-width: 400px;
            margin-right: 0px;
          }
        }
}
}