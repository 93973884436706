/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.panels-container {
    margin-top: 5px;
    position: relative;
    & + .panels-container:not(.react-grid-item)  {
        margin-top: 15px;
    }
    .panel-group {
        position: relative;
    }
    .panel {
        position: relative;
        border-radius: 0;
        border: none;
        box-shadow: none;
        background-color: @panelBg;
        &.reportOrganizer-panel{
            box-shadow: none;
        }
        
        .panel-heading {
            background: @panelHeading;
            color: @panelHeadingText;
            border-radius: 0;
            height: 36px;
            .transition(@ambience2);
            padding: 0px;
            box-shadow: 0 6px 5px -8px @widgetShadow;

            &:hover {
                background: @panelHeading;
            }
            .panel-title {
                font-weight: bold;
                display: inline-block;
                padding: 10px ;
                width: ~'calc(100% - 75px)';
                .title-text{
                    line-height: normal;
                    margin: 0 15px 0 0;
                    float: left;
                    max-width: 100%;
                }
                .panel-header-content{
                    display: inline-block;
                    float: right;
                    margin-right: 20px;
                    &:empty {
                        width: 0px;
                    }
                    .radiobutton-group {
                        margin-bottom: 0;
                        .radiobutton-container{
                            margin-bottom: 0;
                        }
                        .radiobutton + label{
                            height: 17px;
                        }
                    }
                }
            }

            .panel-left-action-container{
                float: left;
                
                .row {
                    float: left;
                }
                .panel-action {
                    color: @mainText;
                    float: left;
                    padding: 10px;

                    &:hover, &:focus {
                        text-decoration: none;
                    }
                    i {
                        .transform(rotate , 0);
                    }

                    &[aria-expanded="true"] {
                        i {
                            .transform(rotate , 0);
                        }
                    }

                    &[aria-expanded="false"] {
                        i {
                            .transform(rotate , -180deg);
                        }
                    }
                }
            }

            .panel-action-container {
                .row {
                    float: left;
                }
                &:empty ~ .panel-title {
                    width: 100%;
                }
                .panel-action {
                    color: @mainText;
                    float: left;
                    padding: 10px;

                    &:hover, &:focus {
                        text-decoration: none;
                    }

                    i {
                        .transform(rotate , 0);
                    }

                    &[aria-expanded="true"] {
                        i {
                            .transform(rotate, 0deg);
                        }
                    }

                    &[aria-expanded="false"] {
                        i {
                            .transform(rotate ,-180deg);
                        }
                    }
                }

                .form-group {
                    display: inline-block;
                    .chosen-container {
                        margin-bottom: 0px;
                        top: 1px;
                    }
                }
            }

            .input-select {
                min-width: 220px !important;
                top: 4px;
            }
        }

        .panel-body {
            min-height: 200px;
        }
    }

    .panel.panel-dashboard {
        position: relative;
        border-radius: 0;
        border: none;
        box-shadow: none;
        border: 1px solid @borders; //newpanel
        border-top: 0; //newpanel
        height: auto !important;

        .panel-heading {
            position: relative;
            z-index: 1;
            background-color: @modeBgColor; //newpanel
            color: @mainText; //newpanel
            border-radius: 0;
            .transition(@ambience2);
            padding: 0 10px; //newpanel
            border: none; //newpanel
            height: 40px; //newpanel
            margin: -1px; //newpanel

            .panel-title {
                font-weight: bold;
                display: inline-block;
                padding: 12px 0; //newpanel
            }

            .panel-action-container {
                opacity: 1; //newpanel
                .transition(@ambience1);
                .panel-action {
                    color: @mainText;
                    float: left;
                    padding: 0;

                    i.fa-percentage::before {
                      content: '%';
                      font-weight: bold;
                    }

                    .btn-action {
                      padding: 10px 12px;
                      &:focus,&:hover {
                        background: @transparent;
                      }
                    }

                    .dropdown-menu {
                        //newpanel
                        margin: 0;
                        left: inherit;
                        border-radius: 0;
                        box-shadow: none;
                        right: 0px;
                        min-width: inherit;
                        padding: 0;
                        top: 39px;
                        cursor: default;
                        background-color: @modeBgColor;
                        .dropdown-header {
                          padding: 3px 12px;
                          color: @gray;
                          margin-top: 5px;
                          background-color: @modeBgColor;
                        }

                        .btn-action, a, button {
                            padding: 3px 15px;
                            text-align: left;
                            background-color: @modeBgColor;
                            color: @mainText;
                            border-bottom: 1px solid @transparent;
                            .transition(@ambience2);

                            &:focus,
                            &:hover {
                                .menuItemHover;
                            }
                            i {
                                padding-right: 10px;
                            }
                        }

                        .divider {
                            margin: 3px 0;
                            background-color: @borders;
                        }
                    }

                    &.color-picker-panel-action {
                        cursor: pointer;
                        font-size: 1.25rem;
                      .color-picker {
                        padding-left: 15px;
                        padding-right: 6px;

                        .color-button {
                            width: 14px;
                            height: 14px;
                            border-radius: 2px;
                            border: 1px solid @black;
                        }
                      }
                    }
                }
            }
        }

        .panel-body {
            min-height: 200px;
            border-top: 0 !important; //newpanel
            padding-right: 10px;
            padding-left: 10px;
            padding-top: 10px;
            height: ~'calc(100% - 60px)';
            width: 100%;

            .panel-resize { //newpanel
              opacity: 0;
              position: absolute;
              right: 10px;
              bottom: 5px;
              .transform(rotate,45deg);

              color: @mainText;
              .transition(@ambience2);
            }

            .panel-empty {
              text-align: center;
              margin: 25px;
              color: @darkGray;

              .ev {
                margin: 10px;
                color: @gray;
              }
            }
        }
    }
}

.panelTooltippedTitle {
    display: block;
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow:ellipsis;
    text-align: left;
}

.conditionGroup-panel{
    .panel-left-action-container {
        padding-top: 4px;
        padding-left: 4px;
    }
    .tglBtnGrp .btn{
        padding: 3px 15px;
    }
}
.automationPanelHeight{
    .panels-container .panel .panel-body{
        min-height: ~"calc(100vh - 230px)";
    }
}
.automationRightPanelHeight{
    .panels-container .panel .panel-body{
        height: auto;
        min-height: auto;
        margin-bottom: 15px;
    } 

}

.panelSlideRightAnimation-enter {
    transform: translateX(100%);
}
.panelSlideRightAnimation-enter-active {
    transform: translateX(0);
    .transition (@ambience3)
}
.panelSlideRightAnimation-leave {
    transform: translateX(0);
}
.panelSlideRightAnimation-leave-active {
    transform: translateX(100%);
    .transition (@ambience3)
}

