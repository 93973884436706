/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.checkbox-group  {
    margin-bottom: 10px;
    .checkbox {
        height: 0;
        width: 0;
        display: none;
        & + label {
            padding-left: 30px;
            display: inline-block;
            line-height: 22px;
            vertical-align: middle;
            cursor: pointer;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 400 !important;
            position: relative;
            min-height: 22px;
            span {
                position: absolute;
                top: 2px;
                left: 0;
                border: 2px solid @borders;
                background: @modeBgColor;
                cursor: pointer;
            }
        }
        &[disabled] ~ label{
            cursor: not-allowed;
            opacity: .5;
            span{
                cursor: not-allowed;
            }
        }
    }
    label {
        .userSelect(none);
    }
}

// checkbox
.checkbox-group {
    .checkbox {
        & + label > span {
            border-radius: 1px;
            width: 18px;
            height: 18px;
            border-color: @borders;
        }
        &:checked:not([checkState=dynamic]) + label > span {
            border: 7px solid @mainColor;
            border-radius: 1px;
            box-shadow: 0 0 0px 2px @borders;
            width: 15px;
            height: 15px;
            top: 4px;
            left: 2px;
            &::before {
                content: "";
                left: -6px;
                position: absolute;
                border-right: 2px solid transparent;
                border-bottom: 2px solid transparent;
                transform: translate(0, 0) rotate(45deg);
                transform-origin: 0% 100%;
                border-color: @white;
                width:6px;
                height: 10px;
                transform: translate(1px, -10px) rotate(45deg);
                background-color: @mainColor;
            }
        }
        &[checkState=dynamic] + label > span { //dynamic here is in Tree
            color: @borders;
            border: 2px dotted @borders;
            &::before {
                content: "*";
                top: 0;
                position: absolute;
                left: 0;
                transform: none;
                transform-origin: 7px 6.4px;
                transition: ease all 2s;
                font-size: 28px;
                transform: rotate(-180deg);
                font-family: initial;
            }
        }
        &[checkState=dynamic]:checked + label > span {
            border-color: @borders;
            background-color: @mainColor;
            &::before {
                color:@white;
                border-color: @white;
                transform: rotate(180deg);
            }
        }
        &[checkState=partial] +label span {
            border: 0 !important;
        }
        &[checkState=partial]:checked + label > span::before {
            border: 0;
            transform: none;
            width: 11px;
            height: 11px;
            top: 2px;
            left: 2px;
            background-color: @mainColor;
        }
    }
}
