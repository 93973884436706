/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';


.reactable {
    position: relative;
    z-index: 2;
    .highlight {
        background: @hoverColor;
        color: @mainText;
    }
    .pagination.container {
        float: right;
        padding-right: 0;
        margin: 15px 0;
    }
    .table-scrollable-area {
        width: 100%;
    }
    .emptyTableRow {
        border: 1px @borders solid;
        text-align: center;
        height: 37px;
        padding: 7px;
        background: @modeBgColor;
    }
    .header-checkbox {
        padding: 13px 18px;
        padding-right: 38px;
    }
    .scrollarea .scrollbar-container.vertical .scrollbar {
        margin-left: 2px;
    }
    .dropdown-menu {
        border-radius: 0px;
        box-shadow: none;
        padding: 0;
        margin: 0;
        >li {
            >a {
                .transition(@ambience2);
                &:hover,
                &:focus {
                    .menuItemHover;
                    outline: 0;
                }
            }
        }
    }
    .dropdown-toggle {
        box-shadow: none;
        &:focus {
            span {
                color: @mainText;
            }
        }
    }
    .checkbox-group {
        margin-bottom: 0px;
        .checkbox-label {
            margin-bottom: 2px;
        }
    }
    .progress {
        margin: 10px 0;
    }
    .showingPerPage {
        display: inline-block;
        > div {
            display: inline-block;
            margin: 10px 5px;
            .ms-parent {
                display: inline-block;
                min-width: 50px!important;
                width: 50px!important;
                margin-bottom: 0;
                .ms-choice {
                    span {
                        font-size: 13px;
                        padding: 3px 8px;
                    }
                }
                .ms-drop {
                    width: 80px;
                    &.bottom{
                        top:auto;
                        bottom: 100%;
                        box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
                        margin-bottom: 0;
                    }
                }
            }
        }
        span {
            display: inline-block;
            font-weight: 700;
        }
    }
    .tablePaginatorFooter {
        .pageLabels {
            display: inline-block;
            margin-top: 20px;
            margin-bottom: 5px;
        }
        .pagination.container {
            width: auto;
            .previous,
            .next {
                display: inline-block;
                &.disabled {
                    a {
                        cursor: not-allowed;
                    }
                }
            }
            .pages {
                margin: 0 0;
                display: inline-block;
            }
        }
        .pagination.pages,
        .pagination.container {
            li {
                display: inline-block;
                &.break span {
                    padding-left: 5px;
                    padding-right: 5px;
                }
                a {
                    background-color:@modeBgColor ;
                    cursor: pointer;
                    color: @mainText;
                    border-color: @borders;
                    border-radius: 0;
                    text-transform: capitalize;
                    .transition(@ambience2);
                    &:hover {
                        color: @mainColor;
                        background-color: @lightGray;
                        border-color: @midGray;
                        .menuItemHover;
                    }
                }
                &.active {
                    a {
                        background-color: @systemBackground;

                    }
                }
                span{
                    background-color:@modeBgColor ;
                    color: @mainText;
                    border-color: @borders;
                    &:hover {
                        .menuItemHover;
                        border-color: @borders;

                    }
                }
            }
        }
    }
    .RTSearchWrapper {    
        width: 100%;
        display: inline-block;
        margin: 10px 0;
        .reactable-searchbar {
            display: flex;
        }
    }
    .search-container {
        display: inline-block;
        position: relative;
        z-index: 101;
        .search-label {
            font-weight: 700;
        }
        & > div {
            display: inline-block;
            input {
                height: 32px;
                padding: 0 10px;
                margin-bottom: 0px !important;
                margin-left: 0.5em;
                width: 190px;
                border: 1px solid @borders;
                .transition(@ambience2);
            }
            input:focus,
            input:hover {
                outline: none;
                border: 1px solid @inputFocusBorder;
            }
            select {
                display: none;
                margin-left: 5px;
                margin-right: 2px;
            }
        }
    }
    .fixedHeader {
        position: relative;
        z-index: 100;
        table {
            margin-top: 0px;
            thead {
                visibility: visible;
                th {
                    .userSelect(none);
                    color: @tableHeadingTextingColor;
                    background-color: @tableHeading;
                    border-bottom: 0px;
                    background-repeat: no-repeat;
                    background-position: right 53%;
                    position: relative;
                    background-blend-mode: difference;
                    &.sort_asc,
                    &.sort_desc ,
                    &.sort_none {
                        &::before,
                        &::after {
                            content: '';
                            width: 0;
                            height: 0;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            font-size: 0;
                            line-height: 0;
                            position: absolute;
                            right: 10px;
                            pointer-events: none;
                        }
                        &::after {
                            border-top: 6px solid @tableHeadingTextingColor;
                            top: ~'calc(50% + 2px)';
                        }
                        &::before {
                            border-bottom: 6px solid @tableHeadingTextingColor;
                            top: ~'calc(50% - 8px)';
                        }
                    }
                    &.sort_desc {
                        &::before {
                            display: none;
                        }
     
                    }
                    &.sort_asc {
                        &::after {
                            display: none;
                        }
                    }
                    .sorting-field {
                        padding: 13px 18px;
                        &.filterable {
                            padding-right: 38px;
                        }
                    }
                    .ev{
                      color: @sysHeaderTextColor;
                      &.ev-arrow-up ,&.ev-arrow-down{
                        vertical-align: middle;
                      }

                    }
                    .column-filter {
                        width: 100%;
                        .ev-filter {
                            color: @tableHeadingTextingColor;
                        }
                        &.filtered {
                            .ev-filter {
                                color: @tableHeadingTextingColor;
                            }
                        }
                        .ms-parent {
                            position: absolute;
                            height: 0px;
                            top: 50%;
                            right: 0px;
                            .emptySearchValue {
                                height: 20px;
                                label {
                                    height: 20px;
                                }
                            }
                        }
                        button.ms-choice {
                            width: 20px;
                            height: 20px;
                            float: right;
                            background: transparent;
                            border: none;
                            margin-right: -5px;
                            position: absolute;
                            top: -11px;
                            right: 30px;
                            text-align: center;
                            span {
                                display: none;
                            }
                            div {
                                display: none;
                            }
                            &:hover {
                                border: none!important;
                            }
                        }
                        .ms-drop {
                            top: 10px;
                        }
                    }
                }
            }
        }
    }
    .maintable-container {
        position: relative;
        z-index: 10;
        box-shadow: 0 2px 6px 0 @widgetShadow;
        margin: 0 !important;
        thead {
            th * {
                padding-top: 0;
                padding-bottom: 0;
                height: 0px;
            }
        }
    }   
    table {
        width: 100%;
        thead {
            visibility: hidden;
            .sorting-field {
                padding: 13px 18px;
                &.filterable {
                    padding-right: 38px;
                }
            }
            th {
                word-break: break-word;
                font-size: 1.4rem;
                .checkbox-group {
                    padding: 0 13px;
                }
            }
        }
        tbody {
            tr {
                .transition(@ambience2);
                &.selected-row {
                    .menuItemHover;
                    border-bottom: 0;
                }
                &:hover {
                    td{
                        background: @tableRowHoverBGColor;
                        border-bottom-color: @activeElement;
                    }
                }
                &:nth-child(even) {
                    background-color: @modeBgColor;
                }
                &:nth-child(odd) {
                    background-color: @systemBackground;
                }
                .dashboard-action {
                    margin-bottom: 0;
                }
                td {
                    padding: 5px;
                    height: 31px;
                    word-break: break-word;
                    word-wrap: break-word;
                    padding-left: 18px;
                    border-bottom: 1px solid @borders;
                    .transition(@bgAmbience);
                    .search-result{
                        span.highlight {
                            display: inline-block;
                            border-bottom: 1px solid @mainColor;
                        }
                    }
                    i.ev {
                        vertical-align: middle;
                    }
                    .form-control {
                        margin-bottom: 0px;
                        .transition(@ambience2);
                        &:hover,
                        &:focus {
                            outline: none;
                            border: 1px solid @inputFocusBorder;
                            box-shadow: none;
                        }
                    }
                    .checkbox-group {
                        padding: 0 13px;
                    }
                    .btn,
                    .input {
                        margin-bottom: 0;
                    }
                    .btn.btn-action:hover{
                        background-color: @tableRowHoverBGColor;                        
                    }
                }
            }
        }
        &.mainTable.fixed {
            tbody {
                tr:last-child {
                    .ms-drop {
                        position: sticky;
                    }
                }
            }
        }
    }
}

.drilldownValue{
    display: inline-block;
    width: ~"calc(100% - 34px)";
  }
  
.drilldownCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .drilldownBtnVisibility{
      visibility: hidden;
    }
    &:hover{
      .drilldownBtnVisibility{
        visibility: visible;
      }
    }
  }

// mainly all the listing pages's tables  
#page-content-wrapper  .reactable.dynamicHeight .table-scrollable-area {
    max-height: ~'calc(100vh - 315px)' !important;
}

#page-content-wrapper .hasPlaceholder.withExpandedPlaceHolder .reactable.dynamicHeight .table-scrollable-area {
    max-height: ~'calc(100vh - 380px)' !important;
}

#page-content-wrapper .widgetPreview {
    .widget.widget-table{
        height: auto;
        margin-bottom: 0;
        padding: 0;
        h6 {
            margin-top: 0;
            margin-bottom: 10px;
        }
        .showingPerPage > div {
            margin-top: 0;
        }
        .tablePaginatorFooter {
            .pagination.container{
                margin: 5px 0 0 0;
            }
            .pageLabels{
                margin-top: 15px;
                margin-bottom: 0;
            }
        }
    }
    .reactable.dynamicHeight .table-scrollable-area {
        max-height: ~'calc(100vh - 320px)' !important;
    }
}
.widget .reactable {
    tbody {
        tr {
            &:nth-child(even),
            &:nth-child(odd) {
                background-color: @modeBgColor;
                border-bottom: 1px solid @borders;
            }
        }
    }
}

.separateTableRow{
    table{
        border-collapse: separate;
        border-spacing: 0px 7px;
    }
    td{
        border-top: 1px solid @borders;
    }
    .reactable{
        tr{
            cursor: pointer;
        }
    }
}

