/* Copyright 2019 Greyskies. All Rights Reserved. */

// Tree control Component
@import 'config/variables-fns';
.rc-tree {
    position: relative;
    margin: 0;
    padding: 5px;
    font-family: 'eventum-font';

    li {
        list-style: none;
        margin: 0;
        outline: 0;
        padding: 0;
        white-space: nowrap;

        a[draggable],
        a[draggable="true"] {
            .userSelect(none);
            -khtml-user-drag: element;
            -webkit-user-drag: element;
            color: @gray;
        }

        ul {
            margin: 0;
            padding: 0 0 0 22px;
        }
        .treeActIco,
        .treeActIco i {
            color: @mainText !important;
            margin-top: -4px;
        }
        a {
            cursor: pointer;
            display: inline-block;
            margin: 0;
            padding: 1px 3px 0 0;
            text-decoration: none;
            vertical-align: top;
            margin-bottom: 2px;
            font-size: 1em;
            color: @mainText;
            .transition(@ambience2);
            &.item-active-bg-color{
                background-color: @hoverColor;
            }
            &.item-inactive-bg-color{
                background-color: transparent;
            }
        }
        span.rc-tree-switcher,
        span.rc-tree-iconEle,
        span.rc-tree-checkbox {
            background-attachment: scroll;
            background-color: transparent;
            background-repeat: no-repeat;
            border: 0 none;
            cursor: pointer;
            display: inline-block;
            margin-right: 2px;
            vertical-align: middle;
        }
        span.rc-tree-switcher {
            height: @height0;
            line-height: @height0;
            margin-right: -5px;
            outline: none;
            width: @width1;
            margin:0 2px;
        }

        span.rc-tree-checkbox {
            background-image: url("../img/checkbox.png");
            background-position: 0 0;
            height: 13px;
            height: 19px;
            line-height: @height0;
            outline: none;
            width: 13px;
            width: 22px;
            margin: 0;
        }

        span.rc-tree-iconEle {
            height: @height0;
            line-height: 12px;
            outline: none;
            width: @width1;
            margin-left: 1px;
        }

        span.rc-tree-icon_loading {
            margin-right: 2px;
            vertical-align: top;
        }

        span.rc-tree-switcher {
            &.rc-tree-roots_open,
            &.rc-tree-center_open,
            &.rc-tree-bottom_open,
            &.rc-tree-noline_open,
            &.rc-tree-switcher_open {
                .transform(rotate, -45deg);

                &:before {
                    content: '\e000';
                    color: @mainColor;
                    font-size: 17px;
                }
            }

            &.rc-tree-roots_close,
            &.rc-tree-center_close,
            &.rc-tree-bottom_close,
            &.rc-tree-noline_close,
            &.rc-tree-noline_docu,
            &.rc-tree-switcher_close {
                &:before {
                    content: '\e002';
                    color: @mainText;
                    font-size: 17px;
                }
            }

            &.rc-tree-center_docu {
                background-position: -56px -18px;
            }

            &.rc-tree-bottom_docu {
                background-position: -56px -36px;
            }
        }

        span.rc-tree-checkbox-checked {
            background-attachment: scroll;
            background-color: transparent;
            background-image: url("../img/checkbox.png");
            background-position: 0 0;
            background-repeat: no-repeat;
            border: 0 none;
            cursor: pointer;
            display: inline-block;
            height: 13px;
            height: 22px;
            line-height: @height0;
            margin-right: 2px;
            outline: none;
            vertical-align: middle;
            width: 13px;
            width: 22px;
            margin: 0;
            background-position: 0 -18px;
        }

        span.rc-tree-checkbox-indeterminate {
            background-position: 0 -40px;
        }

        span.rc-tree-checkbox-disabled {
            background-position: 0 -56px;
        }

        span.rc-tree-checkbox.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
            background-position: 0 -18px;
        }

        span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
            background: @midGray;
            border-radius: 3px;
            position: relative;

            &::after {
                border-left: 0;
                border-top: 0;
                border: 2px solid @modeBgColor;
                content: ' ';
                height: 0;
                left: 3px;
                position: absolute;
                top: 5px;
                .transform(scale, 1);
                width: 5px;
            }
        }
    }

    li.drag-over > a[draggable] {
        background-color: @mainColor;
        border: 1px @mainColor solid;
        color: white;
        opacity: 0.8;
    }

    li.drag-over-gap-top > a[draggable] {
        border-top: 2px blue solid;
    }

    li.drag-over-gap-bottom > a[draggable] {
        border-bottom: 2px blue solid;
    }

    li.filter-node > a {
        color: @mainColor !important;
        font-weight: bold !important;
    }
}

.rc-tree-child-tree {
    display: none;
}

.rc-tree-child-tree-open {
    display: block;
}

.rc-tree-treenode-disabled > span,
.rc-tree-treenode-disabled > a {
    color: @midGray;
    cursor: not-allowed;

    span {
        color: @midGray;
        cursor: not-allowed;
    }
}

.rc-tree-node-selected {
    background-color: @hoverColor;
    opacity: 0.8;
    .transition(@ambience1);
    .btn-action-selected {
        background-color: @hoverColor;
    }
}

.rc-tree-node-content-wrapper {
    display: inline-block;
    padding: 5px;
}

.rc-tree-icon__open {
    margin-right: 2px;
    vertical-align: top;

    &:before {
        content: "\e024";
        color: @mainColor;
    }

    +.rc-tree-title {
        color: @mainColor;
        font-weight: bold;
    }
}

.rc-tree-icon__close {
    margin-right: 2px;
    vertical-align: top;

    &:before {
        content: "\e023";
        color: @mainText;
    }

    +.rc-tree-title {
        color: @mainText;
    }
}

.rc-tree-title {
    font-size: 14px;
    font-family: Arial, "Helvetica Condensed", Helvetica, sans-serif;
}

.rc-tree .rc-tree-child-tree:last-of-type .rc-tree-switcher-noop {
    visibility: hidden;
}
.ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
}

.nwMngmntTree, .designSpacesTree {
    .ReactVirtualized__List,
    .ReactVirtualized__Grid,
    .rst__virtualScrollOverride {
        overflow: auto !important;
    }
    .rst__node {
        .nodeActions {
            display: inline-block;
            transition: .4s all ease-in-out;
            opacity: 0;
            position: absolute;
            top: 5px;
            right: 0;
        }
        .rst__nodeContent {
            transition: .3s all ease-in-out;
        }
        &:hover {
            .rst__nodeContent {
                background-color:@systemBackground;
            }
            .nodeActions {
                opacity: 1;
            }
        }
    }
}

.eventumTree {
  .checkbox-group {
    display: inline-block;
    .checkbox-label {
      margin-left: 2px;
      padding-left: 19px;
      margin-right: 6px;
    }
  }
  .dataAssociationFiltering{
    width: ~'calc(100% - 45px)';
    display: inline-block;
  }
  .search-container {
    input {
      margin-bottom: 0;
    }
  }
  .panel-body {
    .rst__tree {
        min-width: 100%;
        display: inline-block;
        .ev{
          color: @mainColor;
        }
    }
  }
  .ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
    overflow: auto !important;
    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: visible !important;
    }
  }
}
.adminDefined-tree,
.nwMngmntTree {
  .eventumTree {
    .ms-parent {
      margin-bottom: 10px;
    }
  }
}

.tree-node-title {
  margin-left: 8px;
}
.matched-word {
  font-weight: bold;
}
