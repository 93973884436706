/* Copyright 2019 Greyskies. All Rights Reserved. */
// didn't find this to test
@import '../../../../config/variables-fns';
@import '../../../../config/animations';

.modal-open .modal.topologyPopup{
  .modal-dialog {
    &.modal-lg {
      margin: 30px auto;
      @media(max-width: 768px) {
        width: auto;
      }
    }
    .modal-body,
    .modal-body-wrapper {
      height: auto;
    }
  }
  .modal-title{
    font-size: 1.4rem;
  }
  .reactable,table,tbody,tr,td,th{
    font-size: 1.1rem;
  }
}

.topologyPopup {
  .reactable {
    tbody {
        tr {
            &:nth-child(even),
            &:nth-child(odd) {
                background-color: @modeBgColor;
                border-bottom: 1px solid @borders;
            }
        }
    }
  }
}

.node-background {
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: transparent;
    &.matched{
      border: 10px solid gold;
      .animated.infinite;
      .animationName(blink);
      animation-duration: 1.5s;
  }
}

.topologyWrapper {
  overflow: hidden;
  .topology-node-container{
    width: 0px;
    height: 0px;
    .topology-label{
      position: absolute;
      display: inline-block;
      white-space: nowrap;
      width:0px;
      height:0px;
      top:-15px;
      left:25px;
      &.matched {
        top: -25px;
        .matched-word {
          background: gold;
        }
      }
      span{
        font-size: 17px;;
      }    
    }
  }
}

