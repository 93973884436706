/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.widget {
    height: 100%;
    position: relative;
    background: @modeBgColor;
    padding: 5px;
    margin-bottom: 10px;
    &.widget-pagebreak {
        padding:5px 10px 5px 33px;
    }
    .widget-action-container {
        position: absolute;
        right: 4px;
        z-index: 102;
        opacity: 0;
        .transition(@ambience2);

        .dropdown-menu {
            //newpanel
            margin: 0;
            left: inherit;
            border-radius: 0;
            box-shadow: none;
            right: 0px;
            min-width: inherit;
            padding: 0;
            top: 32px;
            cursor: default;
            padding-bottom: 20px;
            background-color: @modeBgColor;
            .dropdown-header {
                padding: 3px 12px;
                color: @gray;
                margin-top: 5px;
                background-color: @modeBgColor;
            }

            li,
            .widget-action {
                a,
                button {
                    padding: 3px 15px;
                    text-align: left;
                    background:@modeBgColor;
                    width: 100%;
                    border-bottom: 1px solid @transparent;
                    color:@mainText;
                    
                    &:focus,
                    &:hover {
                        .menuItemHover;
                        .transition(@ambience2);
                    }
                    i {
                        padding-right: 10px;
                    }
                }
            }
            .divider {
                margin: 3px 0;
                background-color: @borders;
            }
        }

        .widget-action {

            .btn,
            .btn-action {
                margin-bottom: 0;

                &:hover,
                &:focus {
                    background: @transparent;
                }
            }

            &.widget-resize {
                position: absolute;
                bottom: 10px;
                .transform(rotate, 45deg);
            }
        }
    }

    &:hover {
        .widget-action-container {
            opacity: 1;
        }
    }

    .widget-dimmed-image {
        color: @iconsColor;
        text-align: center;
        height: ~'calc(100% - 40px)';
        opacity: 0.35;
        & ~ .widget-footer .widget-image-event {
            position: absolute;
            padding: 0 5px;
            bottom: 10px;
            left: 0;
        }
        i::before {
            font-size: 11em;
        }
        i.ev { 
            font-size: inherit; 
        }
    }

    .widget-body {
        height: 100%;
        overflow: hidden;
        .transition(@ambience3);
        &>div {
            .transition(@ambience3);
        }

        .widget-dimmed-image {
            width: 100%;
            background-size: contain;
        }

        .widget-image-event {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 0;
            text-align: center;
            color: @darkGray;

            .ev {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .page-break {
            padding-right: 30px;
            hr{
                background-color: transparent !important;
                border-top: 2px dashed @mainText !important;
            }
        }
        .no-data{
            min-height: unset;
            h5{
                font-weight: 400 ;
            }
        }
    }
}
.widget-image-event {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color: @darkGray;
  .ev {
      padding-left: 5px;
      padding-right: 5px;
  }
}
.credits {
    margin-top: 20px;
}
