/* Copyright 2019 Greyskies. All Rights Reserved. */

// Validations
.ValidationsMixin(@borderColor , @color , @background ) {    
    .form-control,
    input,
    select {
        box-shadow: none;
        border-color: @borderColor;
        color: @color;
        background: @background;

        &:focus,
        &:hover {
            box-shadow: none;
            border-color: @color;
        }
    }

 
}
.has-success {
    .ValidationsMixin(@lightOlive ,  @olive , @lightSuccess ) ;
}

.has-info {
    .ValidationsMixin(@color27 ,  @color28 , @LightInfo ) ;
}

.has-warning {
    .ValidationsMixin(@lightWarning2 ,  @warning , @lightWarning ) ;
}

.has-error {
    .ValidationsMixin(@lightDanger , @mainColor , @lightError ) ;
}
