/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/themes.less';
@import 'config/variables-fns';
@import 'local/vendors/typography';

@navigationSliderWidth: 365px;

html {
    height: 100%;
    body{
        padding: 0;
        height: 100%;
        color: @mainText;
        position: relative;
        overflow-x: hidden;
        background-color: @systemBackground;
        div.widget:not(.widget-metric):not(.widget-piechart) svg text{
            fill: @mainText !important;
        }
        
        div.widget.widget-metric svg {
            .mark-text.role-mark:not(:first-child){
                text{
                    fill: @mainText !important;
                }  
            }
         }

        svg {
            .mark-text.role-axis-label, .mark-text.role-axis-title{
                text{
                    fill: @mainText !important;
                }
            }        
            .role-legend-label, .role-title {
                text{
                    fill: @mainText !important;
                }
            }
            &.gauge text {
                fill: @mainText !important;
            }
            .mark-rule.role-axis-grid line {
                stroke: @panelHeading !important; // needs check for color var
            }
            .mark-line.anomaly-line  path {
                stroke: @darkGray !important;
            }
            .mark-rect.forecasting-background  path {
                stroke: @hoverColor !important;
            }
            .mark-text{
                &.metricTextMark-noRange text,
                &.metricTextMark text:nth-child(2),
                &.metricTextMark text:nth-child(3) {
                    fill: @mainText !important;
                }
            }
        }
        .pointerContainer path {
            fill: @pointerColor !important;
        }
    }
}

label {
    color: @mainText;
}

table {
    margin-bottom: 0;
}

.separator {
    border-bottom: 1px solid @borders;
    margin: 10px 0;
}

.paddSides0 {
    padding-left: 0;
    padding-right: 0;
}

.padding15 {
    padding:15px;
}
.padding10 {
    padding:10px;
}
.paddingLeft5 {
    padding-left:5px;
}
.paddingLeft10{
    padding-left:10px;
}
.paddingLeft25{
    padding-left:25px;
}
.paddingLeft40{
    padding-left:40px;
}
.paddingRight40{
    padding-right:40px;
}
.paddingRight25{
    padding-right:25px;
}
.paddingRight0 {
    padding-right:0px;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight10 {
    padding-right:10px;
}
.padBottom10 {
    padding-bottom:10px;
}
.padBottom32 {
    padding-bottom:32px;
}
.padTop32 {
    padding-top:32px;
}
.padTop7 {
    padding-top:7px !important;
}
.pad32 {
    padding: 32px;
}
.padding25 {
    padding:25px;
}

.paddBfive {
    padding-bottom: 5px;
}

.alignInput {
    line-height: 32px;
}

.block-center {
    float: none;
    margin: 0 auto;
}
.block-display {
    display: block;
}
.float-none {
    float: none;
}
.full-width{
  width: 100%;
}
.padRight0{
padding-right: 0;
}
.padRight5{
    padding-right: 5px;
    }
.padTop0{
    padding-top: 0px;
}
.padTop10{
    padding-top: 10px;
}
.padTop20{
    padding-top: 20px;
}
.nmsDivider {
    border-top-color: @borders;
    margin-top: 10px;
}

.margSides0 {
    margin-left: 0;
    margin-right: 0;
}
.margTop20 {
    margin-top: 20px;
}
.margTop5 {
    margin-top: 5px;
}

.margBtm10 {
    margin-bottom: 10px;
}
.margBtm5 {
    margin-bottom: 5px;
}
.margBtm20 {
    margin-bottom: 20px;
}
.margBtm15 {
    margin-bottom: 15px;
}
.margTop10 {
    margin-top: 10px;
}
.margTop5 {
    margin-top: 5px;
}
.margBtm0{
    margin-bottom: 0px !important;
}
.margRight6{
    margin-right: 6px;
}
.margRight4{
    margin-right: 4px;
}
.margLft5{
    margin-left: 5px;
}
.margLft10{
    margin-left: 10px;
}
.margLft30 {
    margin-left: 30px;
}
.margLft40 {
    margin-left: 40px;
}
.margLft5OverRid{
    margin-left: 5px !important;
}
.margBtm0OverRide{
    margin-bottom: 0px !important;
}
.margTop15OverRid{
    margin-top: 15px !important;
}
.marg0Auto{
    margin: 0 auto;
}
.padding15OverRid {
    padding:15px !important;
}
.hidden {
    display: none !important;
}

.break-word {
    word-wrap: break-word;
    word-break: break-word;
}
.break-all{
    word-break: break-all;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    grid-column-gap: 15px;
}

.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
}

.label {
    padding: .2em .6em .3em;
    border-radius: 0;
    line-height: 1;
    font-size: 100%;
    display: inline-block;
    text-align: left;
}
.textCenter{
    text-align: center;

}
.textRight{
    text-align: right;
}
.textLeft {
    text-align: left;
}
.label-default {
    background-color: @systemBackground;
    color: @mainText;
}
.headerTitle {
    display: inline-block;
    padding: 0px 10px 0px 0px;
    margin-bottom: 0px;
    font-weight: bold;
    line-height: 35px;
    color: @mainText;
}

.verticalTop {
    vertical-align: top;
}

.pointer-cursor {
    cursor: pointer;

    &>* {
        cursor: pointer;
    }
}

.waterMark {
    position: absolute;
    bottom: 140px;
    right: 140px;
    transform: scale(14);
    opacity: .1;
}

.paddingLeft20{
    padding-left: 20px;
}
.padding5 {
    padding:5px !important;
}
.paddingRight0{
    padding-right:0px;
}
.panelHeight{
    min-height: ~"calc(100vh - 300px)";
}
// Wrapper
#wrapper {
    .transition(@ambience2);
    padding-left: 65px;
    min-height: 100%;
    position: relative;
    width: ~"calc(100% - 16px)";
    background-color: @systemBackground;
    &.expanded{
        padding-left: 15px;
        .navigationSlider{
            left: -@navigationSliderWidth;
        }
        #page-content-wrapper{
            padding-top: 40px !important;
            .actions-header{
                top: 0;
                padding-left: 0px;
            }
            .dashboard-body {
                padding-top: 0;
            }
            .report-body {
                margin-top: 0;
            }
        }
        .navigationSlider {
            bottom: 0;
            height: ~'calc(100vh - 40px)';
            &.slideInRight,
            &.slideOutRight{
                transform: translateX(0);
            }
        }
    }

    #page-content-wrapper {
        position: relative;
        width: 100%;
        padding-bottom: 40px;
        padding-top: 50px;
        background-color: @systemBackground;
        .dashboard-wrapper {
            padding-bottom: 60px;
        }
        .actions-header{
            background-color: @toolbarBg;
            padding-top: 5px;
            position: fixed;
            width: 100%;
            box-shadow: 0 0 4px 0 @widgetShadow;
            z-index: 999;
            top: 50px;
            padding-left: 50px;
            @media(max-width: 768px) {
                padding-left: 0;
            }
            left: 15px;
        }
        .dashboard-body {
            padding-top: 40px;
        }
    }
}

mark,
.mark {
    padding: 0;
}

.pointer-none {
    pointer-events: none;
}

.input-select {
    margin-bottom: 0;
    min-width: 100px !important;
}

// uploader
.removeUploaded {
    padding: 6px 10px;
}

*:focus {
    outline: none;
}

.progress-bar-header {
    margin-bottom: 10px;
}

.addonReset {
    border: none;
    background-color: transparent;
    padding: 0;
}

.horizontal-divider {
    border-bottom: 1px solid @borders;
    margin-bottom: 5px;
}
.horizontal-divider-top{
    border-top: 1px solid @borders;
}
.vertical-divider {
    border-right: 1px solid @borders;
    margin: -5px 0px 6px 10px;
}
.no-data{
    height: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    word-wrap: break-word;
    h5{
        font-weight: 400 ;
    }
}

.row-padding {
    padding: 6px 10px;

    &.charts {
        position: relative;
        top: -3px;
    }

    &.ev-caret-down {
        padding: 6px 8px 6px 0;
        cursor: pointer;
    }
    &.ev-caret-right {
        padding: 6px 10px 6px 2px;
    }
}

#manual-wrapper {
    min-height: 100%;
    position: relative;
    padding-left: @menuWidth;

    &.no-sidebar {
        padding-left: 0;
    }
}

#manual-sidebar-wrapper {
    background-color: @black;
    height:~"calc(100vh - @{headerHeight})";
    left: 0;
    overflow-y: auto;
    position: fixed;
    width: 0;
    z-index: 1000;
    top: @headerHeight;
    width: @width0;

    .nav-pills {
        li {
            width: 100%;
            margin-bottom: 1px;

            a {
                color: @systemBackground;
                text-align: center;
                border-radius: 0;
                border-right: 3px solid transparent;
                .transition(@ambience1);

                &:hover {
                    color:@white;
                    background-color: @mainColor;
                    border-right: 3px solid @white;
                }
            }

            &.active {
                a {
                    color:@white;
                    background-color: @mainColor;
                    border-right: 3px solid @white;
                }
            }

            &+li {
                margin-left: 0;
            }
        }
    }
}

#manual-page-content-wrapper {
    padding: 15px;
    position: relative;
    width: 100%;
    padding-top: 65px;
}

.bold-text {
    font-weight: bold;
}

.semiBold-text {
    font-weight: 600;
}

.subTitle{
    font-size: 1.3rem;
}
.normal-text {
    font-weight: normal;
}
.help-icon {
    color: @mainText;
}

.inlineBlock {
    display: inline-block;
}

.marginRight10{
    margin-right: 10px;
}

.general-container{
    position: relative;
        .general-container-content{
            padding: 10px 20px 20px 20px;
            background:@transparent;
            position: relative;
            min-height: 200px;
        }
        &.scheduledReports-page,
        &.manageDashboards-page,
        &.manageReports-page {
            .general-container-content{
                padding: 0 20px 0 20px;
            }
        }
}
.ev-plus-circle{
    font-size: 1.4rem;
    }
.expandableArea {
    padding: 5px;
    background-color:@systemBackground;
    display: inline-block;
    width: 100%;
    .ms-parent {
        margin-bottom: 10px;
    }
    .data-input {
        margin-bottom: 0;
    }
}
.evc-container-sys-bg {
    .data-input {
        margin-bottom: 0;
    }
}
.evc-scrollArea{
    max-height: 90px;
    border: 1px solid @borders;
    background-color: @systemBackground;
}
.virticalAlign{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: ~"calc(100vh - 130px)";
}

.lineHeight{
    line-height: 26px;
}
.systemMood{
    background-color: @modeBgColor;
    color:@mainText;
}

.transparentBG{
    background-color: transparent; 
}

.navbar .container{
    width: auto;
  }
.emptySearchValue{
    height: 26px;
  }
  
  .emptySearchValue label{
    height: 20px;
  }
  
  .error-message{
    color: @lightDanger;
    vertical-align: top;
  }

  .dataTable .checkbox input[type="checkbox"]{
    margin: 0;
  }

  
.invisible-text{
    color: @mainText;
  }
  
  .pull-bottom {
    display: inline-block;
    vertical-align: bottom;
    float: none;
  }
  
  .modal-tree{
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .downloadBTN{
    display:inline-block;
    padding-right: 7px;
    color:@mainText;
  }
  .removeBTN{
    color: @mainColor;
  }
  .cancelBTN{
    color: @mainColor;
  }
  .downloads-wrapper{
    max-height: 250px;
    height:250px;
}
.max250{
    max-height: 250px;
    padding-right: 10px;
    overflow: auto;
}
  .combined-header-checkbox{
    padding: 13px 18px;
    padding-right: 38px;
  }
  .emptySearchValue{
    height: 26px;
  }
  
  .emptySearchValue label{
    height: 20px;
  }
  
  .error-message{
    color: @lightDanger;
    vertical-align: top;
  }
  
.navbar-action{
    position: relative;
    display: block;
    color:@white;
    cursor: pointer;
    padding: 0px;
}
.navbar-action-icon{
    position: relative;
    padding: 15px 15px;
}
.filterLabel{
    width: ~'calc(100% - 40px)';
}

.navigationSlider{
    left: -315px;
    @media(max-width: 768px) {
        left: -@navigationSliderWidth;
    }
    height: ~"calc(100vh - 111px)";
    width: @navigationSliderWidth;
    background-color: @modeBgColor;
    display: inline-block;
    padding: 5px 15px 0 15px;
    position: fixed;
    bottom: 20px;
    z-index: 15;
    box-shadow: 0 5px 4px 0 @widgetShadow;
}
.btn.btn-default.navigationBtn-btn{
    margin: 0;
    background: @transparent;
    color: @black;
    i {
        margin: 0 10px 0 0;
        line-height: 22px;
        float: left;
        color: @mainText;
    }
    .navBtnText {
        margin: 0;
        max-width: 300px;
        min-width: 200px;
        overflow: hidden;
        font-size: 1.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        cursor: pointer;
        text-align: left;
    }
    &:focus, &:hover, &:focus:active{
        outline: none;
        border: none;
        background: @hoverColor;
    } 
}
.textEllipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 125px;
    &.ellipses-fullWidth{
        max-width: 100%;
    }
}

.inputAlignment{
    display:inline-flex;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    margin-right: 20px;
    
    label{
        margin-right: 15px;
    }
    input{
        width: auto;
        margin-bottom: 0px;

    }
    .input-group {
        padding: 0;
        .treeSelections{
            border: 1px solid @borders;
        }
    }
}
.input-group-addon{
    color:@mainText;
    background-color:@systemBackground;
    border: 1px solid @borders;
}
.addonReset.input-group-addon{
    border:none;
    .btn.btn-primary{
        margin-bottom: 0 !important;
    }
}

.inputFilterChoose{
    .input-select{
        min-width: auto !important;
        &.ms-parent .ms-choice{
            border-bottom: 1px solid @borders !important;
            border-top: 1px solid @borders;
            border-right: 1px solid @borders;
            &:hover{
                border-bottom: 1px solid @borders !important;
            }
        }
    }
}

  .disableBG{
    .loader-overlay .loader{
        background-color: @systemBackground !important;
        opacity: .7;
      }
  }
.dataInputNoMargin{
    .data-input{
        .data-input{
            margin-bottom: 0;
        }
    }
}
.posRelative {
    position: relative;
}
.iconCounter{
    z-index: 1;
    left: 100%;
    width: auto;
    bottom: -5px;
    height: 15px;
    padding: 0 5px;
    font-size: 10px;
    position: absolute;
    border-radius: 5px;
    word-break: normal;
    background-color: @darkGray;
    color: @lightText;
}
.actionsBtns3 {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: end;    
}
    
input.form-control.repeted-input{
    margin-bottom: 0px !important;
}

.ms-parent.input-select.tableCell{
    display: table-cell;
    width: 171px !important;
    border: 1px solid @borders;
    border-bottom-style:none;
}
.cursorWait{
    cursor: wait;
    .footer-buttons{
        .btn-prev{
            cursor: wait;
        }
    } 
    .disableEvents{
        pointer-events: none;
    }
}

.InprogressColor{
  color:@favorite;
}


@import 'validations';
@import 'loader-progress';
@import 'notification';
@import 'tooltip-popover';
@import 'summary-panel';
@import 'forms';
@import 'local/vendors/switch';
@import 'react-tags';
@import 'webStyleGuide/chosen';
@import 'local/vendors/btn';
@import 'config/animations';
@import 'alert';
@import 'widget';
@import 'markdown-widget';
@import 'dashboard';
@import 'local/vendors/dropdown';
@import 'local/vendors/scrollbar';
@import 'local/vendors/tabs'; 

@import 'z-index';

// Media queries
@media(max-width: 1341px) {
    .summary-panel-container {
        width: 50%;
    }

    .grid-3 {
        grid-template-columns: 1fr 1fr;
    }
}

@media(min-width: 1341px) and (max-width: 1790px) {
    .grid-3 {
        .respCondGrp {
            width: 100%;
        }
    }
}

@media(max-width: 992px) {
    .summary-panel-container {
        width: 100%;
    }
}

@media(max-width: 1024px) {
    .grid-3 {
        grid-template-columns: 1fr;
    }
}


@media(max-width: 768px) {
    #wrapper {
        padding-left: 0;
    }

    #page-content-wrapper {
        padding-bottom: 80px;
    }

    .footer {
        padding-left: 15px;
    }

    .grid-2 {
        grid-template-columns: 1fr;
    }
}

.VerticalAlignMiddle {
    vertical-align: middle !important;
}
@media(max-width: 575px) {
    #page-content-wrapper {
        padding-bottom: 160px;
    }
}

.space-between-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; 
    width: 100%;
    .flex-child {
        width: auto;
    }
}
//=========== REST AND SQL BINDING


.queryLabel,
.requestLabel {
    background-color: transparent;
    border: 1px solid @borders;
    color: @mainText;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .orderingBtns {
        &.restOrdering{
            margin-top: 0px;
            .orderingBtn {
                display: block;
            }
        }
    }
    margin-bottom: 5px;
    padding: 0;
    label{
        padding: 10px;
        margin: 0;
        max-width: 90%;
        word-wrap: break-word;
        &:empty{
            height: 40px;
            vertical-align: middle;
        }
    }
}

.orderingBtns { // in FGA and Rest
    width: 25px;
    height: 34px;
    float: left;
    .btn-action.orderingBtn {
        width: 25px;
        height: 20px;
        line-height: 15px;
        padding: 0;
        
        &:hover {
            background-color: @systemBackground;
        }
    }
}

.labelHeight{
    min-height :40px;
    line-height: 20px;
    padding: 3px 5px;
}

.errorReqIcon{
    line-height: 20px;
    color:@mainColor;
    float: right;
    padding: 3px 5px 0px 5px;
}

.selectedLabel{
    background-color: @primaryBtn;
    border: 0;
    border-bottom: 1px solid @mainColor;
    label, span,
    .orderingBtn,
    .deleteReq-btn, 
    .errorReqIcon{
        color: @lightText !important;
    }
}

.sqlPanelTitle,
.restPanelTitle {
    display: block;
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow:ellipsis;
    text-align: left;
}

.sqlTooltipContent {
    max-width: 90%;
    display: flex;
    @media only screen and (max-width:1366px) {
        max-width: 84%;
    }
}

.restTooltipContent {
    max-width: 80%;
    display: flex;
    @media only screen and (max-width:1366px) {
        max-width: 70%;
    }
}

//===========

// new used generic helper Class
.evc-nesting-indentation{
    padding-left: 20px;
    &[class*='col-']{ //incase we used it on a Col div, then 15px the original padding of the Col and the 15px of the indenetaion 
        padding-left: 35px;
    }
}
// helper classes for text color
// ev is for eventum and text-danger following bootstrap naming
.evc-text-danger{
    color: @danger;
}
.evc-text-success{
    color: @success;
}
.evc-text-warning{
    color: @favorite;    
}
// helper classes for background colors 
.evc-system-bg {
    background-color: @systemBackground;
} 
//
.evc-default-link {
    text-decoration: underline;
    cursor: pointer;
}
.evc-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
// Mostly used in collabsiple component content
.evc-container-sys-bg {
    background-color: @systemBackground;
    padding: 10px;
} 

// any footer that has buttons (Modal, Panel, ...)
.evc-btns-footer {
    border-top: 1px solid @borders;
    padding-top: 15px;
    text-align: right;
    &.noFooterBorder {
        border: none;
        padding: 0;
    }
}

.evc-btns-footer{
    .btn {
        min-width: 75px;
        color:@white;
        margin-bottom: 0;
    }
    .btn-cancel {
        border: 2px solid @cancelBtnBorder;
    }
    .btn-primary {
        border: 2px solid @primaryBtn;
    }
    .btn + .btn { 
        margin-left: 5px;
    }
}
