
/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../config/variables-fns';

.selectionBtnWzIcon {
    .reset-btn{
        position: relative;
        z-index: 9;
        margin: 0;
        float: right;
        background-color: transparent;
        color: @mainText;
        &:hover,
        &:focus {
            background-color: @borders;
        }
    }
    .addFGA-btn .btnNotification {
        position: absolute;
        right: -6px;
        z-index: 1;
        top: 8px;
        .notificationIcon {
            background-color: @primaryBtn;
            color: @lightText;
            height: 22px;
            width: 22px;
            border-radius: 5px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: normal;
        }
    }
}