/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';

.alert {
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 0;
    color:@mainText;
    box-shadow: none;

    &.alert-success {
        background: @lightOlive;
        border-color: @olive;
    }

    &.alert-info {
        background: @systemBackground;
        border-color: @borders;
        color: @mainText !important;
    }

    &.alert-warning {
        background: @lightWarning2;
        border-color: @warning;
        color: @darkGray;
    }

    &.alert-danger {
        background: @lightDanger;
        border-color: @mainColor;
    }

    .ev {
        padding-right: 5px;
    }
}
