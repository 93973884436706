/* Copyright 2019 Greyskies. All Rights Reserved. */

@import '../../../../config/variables-fns';

.color-picker {
    display: inline-block;
    .twitter-picker {
        box-shadow: @widgetShadowHover 0px 0px 10px !important;
        background: @systemBackground !important;
        input {
            color: @mainText !important;
            caret-color: @mainText !important;
            border-color: @borders !important;
            box-shadow: @borders 0px 0px 0px 1px inset !important;
            background-color: @modeBgColor !important;
            text-transform: lowercase;
            .transition(@ambience2);

            &:focus {
                box-shadow: @inputFocusBorder 0px 0px 0px 1px inset !important;
            }
        }
    }
    &.removeInput {
        .color-popover {
            transform: translate(-80%, 2px) !important;
            .twitter-picker {
                input {
                    display: none;
                }
                & > div:last-child{
                    padding: 7px 10px 3px 10px !important;
                    span > div {
                        height: 25px !important;
                        width: 25px !important;
                        margin: 0px 6px 5px 0px !important;
                    }
                }
            }
        }

        div:nth-of-type(3)>div:first-of-type {
            display: none !important;
        }
    }
    .color-button {
        width: 18px;
        height: 15px;
        border-radius: 2px;
    }

    .color-popover {
        position: absolute;
        z-index: 2;
        .color-cover {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
        .twitter-picker {
            &>div:first-child,
            & > div:nth-child(2){
                right: 90px !important;
                border-color: transparent transparent transparent !important;
            }
            & > div:nth-child(3) > div {
                background-color: @borders !important;
            }
        }
    }
}

.color-picker-btn {
    width: 95px;
    height: 42px;
    border: 3px solid @borders;
}

// this needs to be moved to map file as this class used in MapLayerForm
.Addon-ms-choice{
    .color-picker{
        width: 100%;
        .AddonColorPicker{
            padding: 5px 12px;
            border: 1px solid @borders;
            background-color:@systemBackground;
            width: 100%;
            .color-button{
                display: inline-block;
                vertical-align: text-bottom;
                margin-bottom: 2px;
            }        
        }
        .twitter-picker {
            left:20%;
        }
    }
}