/* Copyright 2019 Greyskies. All Rights Reserved. */

@import 'config/variables-fns';
body.modal-open{
    background-color: @systemBackground;
}

.modal-open .modal.drilldownPopup{
  overflow:initial;
  .modal-dialog {
    margin-top: 70px;
    .modal-content {
        background-color: transparent;
        border: none;
        &:after,
        &:before {
            content: '';
            width: 45%;
            height: 0;
            box-shadow: 0 0 70px 5px @black;
            position: absolute;
            top: 95%;
            z-index: -1;
            transform: rotate(-4deg);
            left: 6%;
        }
        &:after {
            transform: rotate(4deg);
            left: auto;
            right: 6%;
        }
        .modal-header {
            color: @mainText;
            padding: 10px;
            border: 1px solid @borders;
            border-bottom: 0;
            background-color: @panelHeading; // needs check for color var
            .modal-title {
                font-weight: bold;
                display: inline-block;
                width: calc(~'100% - 35px*3');
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .close.drillBtns {
                color: @mainText;
                font-weight: 400;
                text-shadow: none;
                width: 35px;
                margin-top: 0;
                &[disabled] {
                    color: @disableText;
                    cursor: not-allowed;
                    pointer-events: none;
                }
            }
        }
        .modal-body {
            background-color: @modeBgColor;
            border: solid 1px @borders;
            max-height: ~"calc(100vh - 200px)";
            padding-bottom: 0;
            .no-data{
              height: 380px;
            }
            .widget{
              padding: 0;
              margin-bottom: 0;
            }
            .drill-container{
              display: grid;
              &.drill-col-3 {
                grid-template-columns: 1fr 1fr 1fr 1fr;
              }
              &.drill-col-4 {
                grid-template-columns: 1fr 1fr 1fr;
              }
              &.drill-col-6 {
                grid-template-columns: 1fr 1fr;
              }
              &.drill-col-12 {
                grid-template-columns: 1fr;
              }
            }
        }
    }
  } 
} 
